import {combineReducers} from "redux"
import Profile from "./profile"
import Signin from "./signin"
import NewProject from "./newProject"
import Projectinit from "./project"
import Activescene from "./scene"
import Projects from "./projects"
import Project from "./project/index"
import Invoice from "./invoice"
import Templates from "./templates"
import Template from "./template"
var allReducer = combineReducers({
    projectCreation : NewProject,
    projects : Projects,
    project : Project,
    invoice : Invoice,
    auth : Profile,
    signin : Signin,
    initProject: Projectinit,
    activeScene: Activescene,
    templates: Templates,
    template: Template,
})

export default allReducer;
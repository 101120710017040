import React, { useEffect, useRef } from "react";
import { Button, Divider, Dropdown, Grid, GridColumn, Icon, Loader, Progress } from "semantic-ui-react";
import Navbar from '../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getProjectRequest, getProjectReset } from "../../store/actions/project";
import Projectcard from "../../component/projects/projectCard";
import PhotoSphereViewer, { Viewer, } from "photo-sphere-viewer"
import { MarkersPlugin } from 'photo-sphere-viewer/dist/plugins/markers';
import ReactImageAnnotate from "react-image-annotate"
import { useState } from "react";
import Canvas from "./canvas.jsx";
import UploadView from "../../component/pano/uploadView";
import $ from 'jquery'
import AddAnnotation from "../../component/pano/addAnnotation";
import moment from 'moment'
import Ticket from "../../component/project/ticket";
import { generateRandomNumber } from "../../utils";
import Carousel from "../../component/project/carousel";
import CanvasImg from "./canvasImg";
import CanvasImage from "./canvasImage";
import { Container, Section, Bar } from '@column-resizer/react';
import ImageCanvas from "./ImageCanvas";



const imgUrl = "https://www.citypng.com/public/uploads/small/11641399344inlsxmiu4bj37nntjelor6xmeafh4yyzzyrquazl5sneyp7m9h5j25n0kwvujtle1a2kvnljj93ejntd9txu4kjhjkwkmgwym9ok.png"
const markerHeight = 50
const markerWidth = 50
export default function Pano(props) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector((s) => s.project);
    const drawings = project.data?.drawings
    const dispatch = useDispatch();
    const [PSV, setPSV] = useState(null)
    const [PSV2, setPSV2] = useState(null)
    const [markers, setMarkers] = useState([])
    const [drawing, setDrawing] = useState(null)
    var [fileType, setFileType] = useState('360 image')
    const [activeTicket, setActiveTicket] = useState(null);
    const [activeFolder, setActiveFolder] = useState(null);
    const [items, setItems] = useState([]);
    // const [PSV, setPSV] = useState({})

    const [grid1, setGrid1] = useState(window.innerHeight/2)
    const [grid2, setGrid2] = useState(window.innerHeight/2)
    const [open, setOpen] = useState('')
    const [openAnotModal, setOpenAnotModal] = useState(null)
    const [activePoint, setActivePoint] = useState({ x: 0, y: 0 })
    const [activeImagePano, setActiveImagePano] = useState(null)
    const [activeImagePano2, setActiveImagePano2] = useState(null)
    const [activeVideoPano, setActiveVideoPano] = useState(null)
    const [activePano, setActivePano] = useState(null)
    const [activePano2, setActivePano2] = useState(null)
    const [activePanoPoint, setActivePanoPoint] = useState(null)
    const [editPano, setEditPano] = useState(null)
    const [role, setRole] = useState('viewer')

    const sphereElementRef = useRef()
    const sphereElementRef2 = useRef()

    const { history, computedMatch, location } = props

    const fetchProject = () => {
        dispatch(getProjectRequest(computedMatch?.params?.slug))
    }
    const countFolderItems = (folder) => {
        var total = 0
        switch (fileType) {
            case '360 image':
                drawing?.pano?.map(img => {
                    if (img.folderId == folder._id && img.archive == false) {
                        total += 1
                    }
                })
                break;
            case 'image':
                drawing?.images?.map(img => {
                    if (img.folderId == folder._id && img.archive == false) {
                        total += 1
                    }
                })
                break;
            case 'video':
                drawing?.videos?.map(img => {
                    if (img.folderId == folder._id && img.archive == false) {
                        total += 1
                    }
                })
                break;
            default:
                break;
        }
        // drawing?.images?.map(img => {
        //     if (img.folderId == folder._id && img.archive == false) {
        //         total += 1
        //     }
        // })
        return ` (${total}) `
    }

    const countDrawingItems = (draw) => {
        var total
        switch (fileType) {
            case '360 image':
                total = draw?.pano?.filter(img => img.archive == false)
                break;
            case 'image':
                total = draw?.images?.filter(img => img.archive == false)
                break;
            case 'video':
                total = draw?.videos?.filter(img => img.archive == false)
                break;
            default:
                break;
        }
        return total ? total.length : 0
    }
    const checkForMarkerType = (marks) => {
        marks.map(m => {
            m.image = imgUrl
            m.width = markerWidth
            m.height = markerHeight
            m.tooltip = m.comment

        })
        return marks
    }
    useEffect(()=>{
        PSV && PSV?.resize()
    }, [grid1])
    useEffect(() => {
        $(window).scrollTop(0)
        fetchProject()
        $(sphereElementRef2.current).hide()
        load()

        return () => {
            try {

                dispatch(getProjectReset())
                PSV?.destroy()
                PSV2?.destroy()
            } catch (error) {
                console.log(error)
            }
        }
    }, [])
    useEffect(() => {
        if (project?.data?.users?.length > 0) {
            project?.data?.users?.map(u => {
                if (u.userId._id == profile.uid) {
                    setRole(u.role)
                }
            })
        }
        if (project?.data?.creatorID == profile?.uid) {
            setRole('owner')
        }
    }, [project.data])

    useEffect(() => {
        if (activePano) {
            PSV.setPanorama(activePano.url).then(p => console.log(p)).catch(e => console.log(e))
        }
    }, [activePano])
    useEffect(() => {
        if (drawing) {
            var lists = [];
            switch (fileType) {
                case 'image':
                    lists = drawing?.images?.filter(i => (i.archive == false && i.folderId == activeFolder?._id))
                    setItems(lists)
                    if (lists.length) {
                        setActiveImagePano(lists[lists.length - 1])
                        // setActiveImagePano2(lists[lists.length -1])
                    }
                    if (activeImagePano2) setActiveImagePano2(null)
                    break;
                case 'video':
                    lists = drawing?.videos?.filter(i => (i.archive == false && i.folderId == activeFolder?._id))
                    setItems(lists)
                    if (lists.length) {
                        setActiveVideoPano(lists[lists.length - 1])
                    }
                    break;
                case '360 image':
                    lists = drawing?.pano?.filter(i => (i.archive == false && i.folderId == activeFolder?._id))
                    setItems(lists)
                    if (lists.length) {
                        setActivePano(lists[lists.length - 1])
                    }
                    if (activePano2) {
                        setActivePano2(null)
                        $(sphereElementRef2.current).hide()
                        setActivePano2(null)
                        try {
                            PSV2?.destroy()
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    break;
                default:
                    break;
            }

        }
    }, [drawing, fileType])
    useEffect(() => {
        var list = []
        if (activeFolder) {
            switch (fileType) {
                case 'image':
                    drawing?.images?.map(img => {
                        if (img.folderId == activeFolder._id && img.archive == false) {
                            list.push(img)
                        }
                    })
                    if (list.length) {
                        setActiveImagePano(list[list.length - 1])
                    }
                    if (activeImagePano2) setActiveImagePano2(null)
                    break;
                case 'video':
                    drawing?.videos?.map(img => {
                        if (img.folderId == activeFolder._id && img.archive == false) {
                            list.push(img)
                        }
                    })
                    if (list.length) {
                        setActiveVideoPano(list[list.length - 1])
                    }
                    break;
                case '360 image':
                    drawing?.pano?.map(img => {
                        if (img.folderId == activeFolder._id && img.archive == false) {
                            list.push(img)
                        }
                    })
                    if (list.length) {
                        setActivePano(list[list.length - 1])
                    }
                    if (activePano2) {
                        setActivePano2(null)
                        $(sphereElementRef2.current).hide()
                        setActivePano2(null)
                        try {
                            PSV2?.destroy()
                        } catch (error) {
                            console.log(error)
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        setItems(list)
    }, [activeFolder, fileType])
    // useEffect(() => {
    //     if (fileType == 'video') {
    //         var panoLength = drawing.videos.length; 
    //         setActiveVideoPano(drawing.videos[panoLength - 1])
    //     } else if (fileType == 'image') {
    //         var panoLength = drawing.images?.length; 
    //         setActiveImagePano(drawing?.images[panoLength - 1])
    //     }
    // }, [fileType])
    useEffect(() => {
        if (activePano2) {
            PSV2?.setPanorama(activePano2.url).then(p => console.log(p)).catch(e => console.log(e))
        }
    }, [activePano2])
    useEffect(() => {
        if (drawings) {
            var draw
            const markersPlugin = PSV?.getPlugin(MarkersPlugin);
            drawings?.map((d, k) => {
                if (location?.state) {
                    if (d._id == location.state._id) {
                        setDrawing(d)
                        draw = d
                    }
                } else {
                    if (k == drawings.length - 1) {
                        setDrawing(d)
                        draw = d
                    }
                }
            })
            if (draw) {
                var panoLength = draw.pano.length;
                var active = draw.pano[panoLength - 1]
                var folder, lists = []
                project?.data?.folders.map(f => {
                    if (activeFolder?._id == f._id) {
                        folder = f
                    }
                })
                if (!folder && project?.data?.folders?.length > 0) { project?.data?.folders?.map(f => { folder = f }) }
                //    if(folder){
                //         draw?.images?.map(img => {
                //             if (img?.folderId == folder?._id && img.archive == false) {
                //                 lists.push(img)
                //             }
                //         })

                //     }
                // setActivePano(active)
                setActiveFolder(folder)
                PSV?.once('ready', () => {
                    if (active?.markers) {
                        console.log(active.markers)
                        setMarkers(checkForMarkerType(active?.markers))
                        markersPlugin?.setMarkers(active.markers)
                    }
                })
                PSV?.once('panorama-loaded', () => {
                    if (active?.markers) {
                        console.log(active.markers)
                        setMarkers(checkForMarkerType(active?.markers))
                        markersPlugin?.setMarkers(active.markers)
                    }
                })
            }
            PSV?.on('dblclick', onClick);
            markersPlugin?.on('select-marker', onSelect)

        }
    }, [drawings, markers])

    // const onSelect = (e, marker) => {
    //     var p;
    //     markers.map(m => {
    //         if (m.id == marker.id) p = m
    //     })
    //     console.log(p)
    //     setEditPano(p)
    //     setOpenAnotModal(activePano)

    // }
    const onClick = (e, data) => {
        if (role != 'editor' || role != 'owner' || role != 'comments') return false
        var id = '#' + generateRandomNumber(10)
        var m = {
            longitude: data.longitude, latitude: data.latitude, width: markerWidth, height: markerHeight, id, image: imgUrl
            // html: `<div style='background: #000; width: 100%'>
            //     sadfaasdf
            // </div>`
        }
        setMarkers(currentState => {
            console.log(activePano)
            // markersPlugin?.setMarkers([...currentState, m])
            console.log(`${data.rightclick ? 'right ' : ''}clicked at longitude: ${data.longitude} latitude: ${data.latitude}`);
            setActivePanoPoint({ longitude: data.longitude, latitude: data.latitude, id })
            setTimeout(() => {
                setOpenAnotModal(activePano)
            }, 1000)
            return [...currentState, m]
        })

    }

    const load = () => {
        var PSV = new Viewer({
            container: sphereElementRef.current,
            // panorama: 'https://photo-sphere-viewer-data.netlify.app/assets/sphere.jpg',
            // panorama: activeScene.url,
            // time_anim: false,
            plugins: [
                [MarkersPlugin, {
                    markers: [],
                }],
            ],
        });
        setPSV(PSV)

    }

    const load2 = (pano) => {
        $(sphereElementRef2.current).show()
        var PSV2 = new Viewer({
            container: sphereElementRef2.current,
            // panorama: pano?.url,
            // panorama: activeScene.url,
            // time_anim: false,
            plugins: [
                [MarkersPlugin, {
                    markers: [],
                }],
            ],
        });
        setActivePano2(pano)
        setPSV2(PSV2)
    }
    const pointCallback = (point) => {
        setActivePoint(point)
        setOpen(drawing._id)
    }
    const onSelect = (e, marker) => {

        var p;
        activePano?.markers?.map(m => {
            if (m.id == marker.id) p = m
        })
        p && setActiveTicket(p)

    }
    const enterFullScreen = () => {
        const markersPlugin = PSV?.getPlugin(MarkersPlugin);
        PSV.enterFullscreen()
        markersPlugin?.gotoMarker(activeTicket.id, 1500)
    }
    const checkRole = () => {
        var check = false
        if (role == 'editor' || role == 'owner') {
            check = true
        }
        return check
    }
    return (
        <>
            <UploadView
                point={activePoint} setOpen={setOpen}
                projectId={project?.data?._id}
                open={open} edit={null} folders={project?.data?.folders} fetchProject={fetchProject} />
            <AddAnnotation
                point={activePanoPoint} setOpen={setOpenAnotModal}
                setEdit={setEditPano}
                drawingId={drawing?._id}
                open={openAnotModal} edit={editPano} fetchProject={fetchProject} />
            {/* <Navbar {...props} auth={profile} /> */}
            <div className="x-pano">
                <Container vertical={true} style={{ height: window.innerHeight}}>
                    <Section minSize={50} onSizeChanged={(data)=> setGrid1(data)} >
                        <div className="x-view" style={{ display: fileType != '360 image' ? 'none' : 'flex' }}>
                            <div className="x-ground" ref={sphereElementRef2} style={{height: grid1 + 'px'}}>

                            </div>
                            <div className="x-ground" ref={sphereElementRef} style={{height: grid1 + 'px'}}>

                            </div>
                        </div>

                        <div className="x-back" onClick={() => props.history.goBack()}>
                            <Icon name="arrow left" />
                        </div>
                        <div className="x-upload" onClick={() => {
                            setOpen(project.data._id);
                            pointCallback({ x: 0, y: 0 })
                        }}>
                            <Icon name="plus" />
                        </div>
                        {fileType != '360 image' && <div className="x-media">
                            {fileType == 'video' ?
                                <video controls
                                    src={activeVideoPano?.url}
                                // ref={ref => (videoRef[i] = ref)}
                                >
                                    {/* <source
                            src={activeVideoPano?.url}
                            type="video/mp4"
                        
                          /> */}
                                </video>
                                :
                                <div className="x-image-media">
                                    {activeImagePano2 &&
                                        <>
                                            <div className="x-timetravel2" style={{ display: fileType != 'image' ? 'none' : 'flex' }}>
                                                <Dropdown
                                                    placeholder='Select an image'
                                                    fluid
                                                    selection
                                                    value={fileType}
                                                    text={activeImagePano2 ? moment(activeImagePano2.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a") : "Select a view"}
                                                >
                                                    <Dropdown.Menu>
                                                        {items.map((p, key) => (
                                                            <Dropdown.Item text={moment(p.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a")} key={key} onClick={() => setActiveImagePano2(p)} />
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </>
                                    }
                                    <div className="x-timetravel" style={{ display: fileType != 'image' ? 'none' : 'flex' }}>
                                        <div className="x-split" onClick={() => {

                                            if (activeImagePano2) {
                                                $('.canvasImg2').hide()
                                                setActiveImagePano2(null)
                                                // PSV2?.destroy()
                                            } else {
                                                setActiveImagePano2(activeImagePano)
                                                $('.canvasImg2').show()
                                            }
                                        }}>
                                            <Icon name="resize horizontal" />
                                        </div>
                                        <Dropdown
                                            placeholder='Select file type'
                                            fluid
                                            selection
                                            value={fileType}
                                            text={activeImagePano ? moment(activeImagePano.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a") : "Select an image"}
                                        >
                                            <Dropdown.Menu>
                                                {items?.map((p, key) => (
                                                    <Dropdown.Item text={moment(p.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a")} key={key} onClick={() => setActiveImagePano(p)} />
                                                ))} ;

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className="x-image-view">
                                        {/* {activeImagePano2 ? <CanvasImage className="canvasImg2" src={activeImagePano2?.url} key={2} /> : null} */}
                                        <ImageCanvas 
                                        canvasWidth={window.innerWidth} canvasHeight={grid1}
                                            checkRole={checkRole}
                                            // setOpen={setOpen} open={open} 
                                            // drawing={drawing} project={project.data}   checkRole={checkRole}
                                            activeImagePano={activeImagePano}
                                            setActiveImagePano={setActiveImagePano} key={1} 
                                            />
                                    </div>
                                </div>}
                        </div>}
                        {activePano2 && <div className="x-timetravel2" style={{ display: fileType != '360 image' ? 'none' : 'flex' }}>
                            <Dropdown
                                placeholder='Select file type'
                                fluid
                                selection
                                value={fileType}
                                text={activePano2 ? moment(activePano2.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a") : "Select a view"}
                            >
                                <Dropdown.Menu>
                                    {items?.map((p, key) => (
                                        <Dropdown.Item text={moment(p.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a")} key={key} onClick={() => setActivePano2(p)} />
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>}
                        <div className="x-timetravel" style={{ display: fileType != '360 image' ? 'none' : 'flex' }}>
                            <div className="x-split" onClick={() => {
                                if (activePano2) {
                                    $(sphereElementRef2.current).hide()
                                    setActivePano2(null)
                                    try {
                                        PSV2?.destroy()
                                    } catch (error) {
                                        console.log(error)
                                    }
                                } else load2(activePano)
                            }}>
                                <Icon name="resize horizontal" />
                            </div>
                            <Dropdown
                                placeholder='Select View'
                                fluid
                                selection
                                value={fileType}
                                text={activePano ? moment(activePano.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a") : "Select a view"}
                            >
                                <Dropdown.Menu>
                                    {items.map((p, key) => (
                                        <Dropdown.Item text={moment(p.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a")} key={key} onClick={() => setActivePano(p)} />
                                    ))} ;

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                       
                    </Section>
                    <Bar size={5} style={{ background: 'currentColor', cursor: "ns-resize" }} />
                    <Section minSize={100} onSizeChanged={(data)=> setGrid2(data)} >

                        <div className="x-drawingtype">
                            <Dropdown
                                placeholder='Select a Drawing'
                                fluid
                                selection
                                value={drawing?._id}
                                text={drawing?.title ? drawing?.title + ` (${countDrawingItems(drawing)})` : "Select a Drawing"}
                            >
                                <Dropdown.Menu>
                                    {drawings?.map((f, k) => f.archive ? null :
                                        <Dropdown.Item text={f.title + ` (${countDrawingItems(f)})`} key={k} onClick={() => setDrawing(f)} />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                            <Dropdown
                                placeholder='Select a Folder'
                                fluid
                                selection
                                value={activeFolder?._id}
                                text={activeFolder?.title ? activeFolder?.title + countFolderItems(activeFolder) : "Select a Folder"}
                            >
                                <Dropdown.Menu>
                                    {project?.data?.folders?.map((f, k) => f.archive ? null :
                                        <Dropdown.Item text={f.title + countFolderItems(f)} key={k} onClick={() => setActiveFolder(f)} />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="x-filetype">
                            <Dropdown
                                placeholder='Select file type'
                                fluid
                                selection
                                value={fileType}
                                text={fileType || "Select file type"}
                            >
                                <Dropdown.Menu>

                                    <Dropdown.Item text={"Image"} onClick={() => setFileType('image')} />
                                    <Dropdown.Item text={"Video"} onClick={() => setFileType('video')} />
                                    <Dropdown.Item text={"360° image"} onClick={() => setFileType('360 image')} />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>

                        {drawing && <Canvas pointCallback={pointCallback} canvasWidth={window.innerWidth} canvasHeight={700} setOpen={setOpen} open={open} drawing={drawing} project={project.data} fileType={fileType} setActivePano={setActivePano} fileType={fileType} checkRole={checkRole}
                            setActiveImagePano={setActiveImagePano} setActiveVideoPano={setActiveVideoPano}
                        />}

                    </Section>

                </Container>

            </div>
            {activeTicket && <Ticket
                project={project}
                setTicket={setActiveTicket}
                activeTicket={activeTicket}
                setActiveTicket={setActiveTicket}
                user={profile}
                pano={activePano}
                drawingId={drawing?._id}
                enterFullScreen={enterFullScreen}
                checkRole={checkRole}
            />}
        </>
    )
}

/* eslint-disable no-undef */
/* eslint-disable no-useless-catch */
import Axios from 'axios';
export const baseURL = "https://build4africa.com/api";
// export const baseURL = "http://localhost:4000/api";

const AxiosCall = async callObj => {

  const { path, method, data, contentType, baseUrlType = null } = callObj;

  const token = window.localStorage.getItem('token');
  const headers = {
    Authorization: `${token}`,
    // 'Content-Type': contentType || 'application/json'
  };

  let url = `${baseURL}/${path}`;


  try {
    const response = await Axios({ method, url, data, headers, json: true });
    const result = response && response.data;
    return result;
  } catch (error) {
    throw error;
  }
};

export  function setAuthorizationToken (token) {
  if(token) Axios.defaults.headers.common["Authorization"] = `${token}`; else
  delete Axios.defaults.headers.common["Authorization"]
 }

export default AxiosCall;

import React, { useState, useEffect } from 'react'
import { Button, Form, Header, Icon, Image, Modal, Select } from 'semantic-ui-react'
import Mediaupload from '../uploader'
import { Link } from "react-router-dom";
import AxiosCall from '../../config/axios';
import { Dropdown } from 'semantic-ui-react'

function EditProject({ open, setOpen , fetchProjects }) {
    var [loading, setLoading] = useState(false)
    var [title, setTitle] = useState("")
    var [description, setDescription] = useState("")
    useEffect(() => {
        setTitle(open?.title)
        setDescription(open?.description)
    }, [])
    const upload = async () => {
        setLoading(true)
        try {
           var res = await AxiosCall({
                path: "projects/edit",
                data: { projectId: open._id, title, description},
                method: "POST"
            })
            setLoading(false)
            fetchProjects()
            setOpen(null)
            setTitle("")
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
  
    return (
        <Modal
            onClose={() => setOpen(null)}
            open={open ? true: false}
            className="x-upload-receipt"
            size="tiny"
        >
            <Modal.Header>
            <div className="x-back" onClick={() => {
                                           
            }}>
                <Icon name="chevron left" />
                <span> Back</span>
            </div>
            </Modal.Header>
            <Modal.Content >
                <div className="x-content edit-doc">

                        <Form onSubmit={upload} warning>
                                                <Form.Field>
                                                    <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-name"
                                                        placeholder="Enter project title "
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        required
                                                        size="massive"
                                                        icon="folder"
                                                        iconPosition='left'
                                                        value={title}
                                                    />
                                               
                                                    {/* <Select 
                                                        placeholder="Select folder"
                                                        options={arrange(folders)}
                                                        onChange={(e,s)=>console.log(s,  e.value)}
                                                    /> */}
                                                </Form.Field>
                                             <Form.Field>
                                                    <Form.TextArea
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-name"
                                                        placeholder="Enter Description  "
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        required
                                                        size="massive" 
                                                        value={description}
                                                    />
                                               
                                                    {/* <Select 
                                                        placeholder="Select folder"
                                                        options={arrange(folders)}
                                                        onChange={(e,s)=>console.log(s,  e.value)}
                                                    /> */}
                                                </Form.Field>
                                             <Form.Field>
            <br/>
                                        <Button type="submit" color="green" loading={loading} size="huge"  disabled={!title} >
                                            {"EDIT PROJECT" }
                                        </Button>
                                             </Form.Field>
                                                </Form>
                  <div>
                  </div>
                </div>

            </Modal.Content> 
        </Modal>
    )
}

export default EditProject

import React, { Component } from "react";
import ReactDropzone from "react-dropzone";
import { Icon, Form, Button } from "semantic-ui-react";
import cloud from "../../assets/cloud.png";
import cloud2 from "../../assets/cloud2.png";
import AxiosCall from "../../config/axios";
export default class Mediaupload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [], 
      isLoading: false,
      error: "",
    };
    this.onDrop = this.onDrop.bind(this);
  }

  onDrop(files) {
    // POST to a test endpoint for demo purposes
      var links = []
      files.forEach(async (file, key) => {
      var formData = new FormData();
      formData.append("file", file);
      try {

        this.setState({ isLoading: true, success: "", error: "" });
        var res = await AxiosCall({
          method: "post",
          path: "projects/uploadFile",
          data: formData,
          headers: { "Content-Type": "multipart/form-data" },
        });
        links.push(res.url)
        if(links.length == files.length){
        this.setState({ isLoading: false, success: true });
        this.props.cb(links);
        }
      } catch (e) {
        let errorResponse;
        if (e.response) {
          const { error } = e.response.data;
          errorResponse = error;
        } else {
          errorResponse = "Something went wrong. please try again";
        }
        this.setState({ isLoading: false, error: errorResponse });
      }
    });
  }

  render() {
    var { mediaType , multiple, text, header} = this.props;
    return (
      <>
        <div className="imageUpload">
          {this.state.files.length ? null : (
            <Form loading={this.state.isLoading}>
              <ReactDropzone
                multiple={multiple == false ? false : true}
                accept={mediaType ? mediaType : "image/*,.pdf, .heic"}
                onDrop={this.onDrop}
                name="item"
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div style={{ textAlign: "center" }}>
                    
                      {header ? 
                      <>
                      <img src={cloud2} />
                      <div className="x-medium x-header" > 
                        {header}
                        </div>
                        </>
                        :
                        <img src={cloud} />}
                      <div>
                        {text || "Drag and Drop Your files here or click the browse button to upload your documents"}
                      </div>
                      <br />
                      <Button basic>Browse</Button>
                    </div>
                  </div>
                )}
              </ReactDropzone>
            </Form>
          )}
        </div>
        {this.state.error ? (
          <div className="ui warning message">
            <small>{this.state.error}</small>
          </div>
        ) : null}
      </>
    );
  }
}

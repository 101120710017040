
import * as types from '../../constant';


 const initialState = {
    isLoading: false,
    error: null,
    isSuccessful: false,
    data: [],
    tour: ''
  };

const getProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOUR_GUIDE:
      return { ...state, tour: action.payload};
    case types.GET_PROJECTS_START:
      return { ...state, isLoading: true, error: null };
    case types.GET_PROJECTS_SUCCESS:
      return { ...state,  isLoading: false, isSuccessful: true , data: action.payload};
    case types.GET_PROJECTS_FAIL:
      return { ...state, error: action.payload, isLoading: false };
    case types.GET_PROJECTS_CLEANUP:
      return { ...state, error: null, isLoading: false, isSuccessful: false };
    default:
      return state;
  }
};

export default getProjectReducer;

import React, { useEffect, useState } from "react";
import Navbar from '../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Divider, Grid, GridColumn, Icon, Table } from "semantic-ui-react";
import home from "../../assets/home.png"
import pin from "../../assets/pin.png"
import mortgage from "../../assets/mortgage.png"
import insurance from "../../assets/insurance.png"
import refer from "../../assets/refer.png"
import { getInvoiceRequest } from "../../store/actions/invoice";
import Projectcard from "../../component/projects/projectCard";
import moment from "moment"
import ProjectUpdates from "../../component/project/projectUpdates";
import PaymentHistory from "../../component/project/paymentHistory";
import Chat from "../../component/project/chat";
import ProjectImages from "../../component/project/projectImages";
import Panorama from "../../component/project/panorama";
import { getProjectRequest, getProjectReset } from "../../store/actions/project";
import Loader from "react-loader-spinner";
import UploadDrawings from "../../component/pano/uploadDrawings";
import UploadFolder from "../../component/pano/uploadFolder";
import ProjectVideos from "../../component/project/projectVideos";
import ProjectPano from "../../component/project/projectPano";

export default function Project(props) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector((s) => s.project.data);
    const invoices = useSelector((s) => s.invoice.data);
    const [mainTab, setMainTab] = useState("updates")
    const [tab, setTab] = useState("images")
    const [open, setOpenDrawingModal] = useState('')
    const [edit, setEditDrawing] = useState(null)
    const [openFolder, setFolderOpen] = useState(null)
    const [editFolder, setFolderEditOpen] = useState(null)
    const [role, setRole] = useState('viewer')

    const dispatch = useDispatch();
    const { history, location, computedMatch } = props
    const { state } = location
    useEffect(() => {
        fetchProject()
        let params = new URLSearchParams(computedMatch?.params?.slug);
        var filetype = params.get('f')?.toString()
        if (filetype == 'pano') {
            setTab("360 views")
        } else if (filetype == 'images' ) {
            setTab('images')
        } else if (params.get('message')){
            setTab("messages")
        }
        return () => dispatch(getProjectReset())
    }, [computedMatch?.params?.slug])
    // useEffect(() => {
    //     let params = new URLSearchParams(computedMatch?.params?.slug);
    //     var filetype = params.get('f')?.toString()
    //     if (filetype == 'pano' && !project) {
    //         // setTab("360 views")
    //         fetchProject()
    //     } else if (filetype == 'images'  && !project) {
    //         setTab('images')
    //     }
    // }, [computedMatch?.params?.slug])
    useEffect(() => {
        if (project?.users?.length > 0) {
            project?.users?.map(u => {
                if (u.userId._id == profile.uid) {
                    setRole(u.role)
                }
            })
        }
        if (project?.creatorID?._id == profile?.uid) {
            setRole('owner')
        }
    }, [project])

    const fetchProject = () => {
        dispatch(getProjectRequest(computedMatch?.params?.slug))
        // dispatch(getInvoiceRequest(state._id))
    }
    const countTotalImage = () => {
        var total = 0
        project?.drawings?.map(d => { 
           d?.images?.map(i => {if(i.archive == false) total += 1}) 

        })
        return total 
    }
    const countTotalPanos = () => {
        var total = 0
        project?.drawings?.map(d => { 
           d?.pano?.map(i => {if(i.archive == false) total += 1}) 

        })
        return total 
    }
    const countTotalVideos = () => {
        var total = 0
        project?.drawings?.map(d => { 
           d?.videos?.map(i => {if(i.archive == false) total += 1}) 

        })
        return total 
    }
    
    return (
        !project?._id ? <div className="x-page-loader">
            <Loader color="#37517E" />
        </div> :
            <>
                <UploadDrawings setOpen={setOpenDrawingModal} setEdit={setEditDrawing} open={open} edit={edit} fetchProject={fetchProject} />
                <UploadFolder setOpen={setFolderOpen} open={openFolder} fetchProject={fetchProject} folders={project.folders} edit={editFolder} />
                <Navbar {...props} auth={profile} />
                <div className="x-project">
                    <div className="x-container">
                        <section>
                            <div className="x-pagination-box">
                                <div className="x-pagination"><Link to="/">Home</Link> <Icon name="chevron right" /> <Link to="/projects" > My projects </Link> <Icon name="chevron right" />  {project?.title} </div>
                                <Link to={props.location.pathname + '/manage'} className="manage-link" > Manage projects </Link>
                            </div>
                            <div className="x-flex">
                                <div className="x-title">
                                    <span className="x-medium">{project?.title}</span>

                                </div>
                                <div>
                                    {(role == 'editor' || role == 'owner') ?
                                        <>
                                            <Button color="green" size="huge" onClick={() => setOpenDrawingModal(project._id)}>
                                                ADD DRAWINGS
                                            </Button>
                                            <Button color="green" size="huge" onClick={() => setFolderOpen(project._id)}>
                                                ADD FOLDER
                                            </Button>
                                        </> : null}

                                    <Button color="black" size="huge" onClick={() => history.push({ pathname: `${project.slug}/theater` })}>
                                        <Icon name="angle double up" /> THEATER MODE
                                    </Button>
                                </div>
                            </div>
                        </section>
                        {/* <div className="x-first-sec">
                            <div className="-back" onClick={() => {
                                props.history.goBack()
                            }}>
                                <Icon name="chevron left" />
                                <span> Back</span>
                            </div>
                        </div> */}
                        {/* <div className="x-second">
                        <div className="x-head">
                            <ul>
                                <li onClick={()=>setMainTab('updates')} className={mainTab == "updates" ? "active" : null }>Project Updates</li>
                                <li onClick={()=>setMainTab('history')} className={mainTab == "history" ? "active" : null }>Payment History</li>
                            </ul>
                        </div>
                        <div className="x-body">
                            <Grid columns="equal" stretched className="no-margin">
                                <Grid.Column
                                    mobile="16"
                                    tablet="8"
                                    computer="8"
                                    style={{ paddingLeft: 0, paddingTop: 0, paddingButton: 0 }}
                                >
                                    <div className="x-left">
                                        <p> <img src={home} /> <span> {project.buildingType} </span> </p>
                                        <p> <img src={pin} /> <span> {project.location} </span> </p>
                                        <Divider />

                                        <div>
                                        <Button primary onClick={()=>setTab('360 views')} >
                                            <Icon name="camera" />  Take a tour
                                        </Button>
                                        </div>
                                    </div>
                                </Grid.Column>
                                <Grid.Column
                                    mobile="16"
                                    tablet="8"
                                    computer="8"
                                    style={{ paddingLeft: 0, paddingTop: 0, paddingButton: 0 }}
                                >
                                    <div className="x-box">
                                        <p> <span className="x-medium">Ground floor construction</span></p>
                                        <div className="x-desc">
                                        Currently building the foundation of the house
                                    </div>
                                        <div className="x-desc">
                                           
                                        </div>
                                    </div>
                                </Grid.Column>
                            </Grid>
                        </div>

                    </div> */}
                        {mainTab == 'updates' ?

                            <div className="x-third">
                                <div className="x-head">
                                    <ul>
                                        {/* <li className={tab == 'project updates' ? 'active' : null} onClick={() => setTab("project updates")}>Project Updates</li> */}
                                        <li className={tab == 'images' ? 'active' : null} onClick={() => setTab("images")}>Images ({countTotalImage()})</li>
                                        <li className={tab == 'videos' ? 'active' : null} onClick={() => setTab("videos")}>Videos ({countTotalVideos()})</li>
                                        <li className={tab == '360 views' ? 'active' : null} onClick={() => setTab("360 views")}>360 views ({countTotalPanos()})</li>
                                        <li className={tab == 'messages' ? 'active' : null} onClick={() => setTab("messages")}>Messages</li>
                                        {/* <li className={tab == 'weekly report' ? 'active' : null} onClick={() => setTab("weekly report")}>Weekly Report</li> */}
                                    </ul>
                                </div>
                                <div className="x-body">
                                    {/* <div className="x-subtitle">
                           <p style={{textTransform: "capitalize"}}>{`${tab}`}</p>
                           </div> */}
                                    {/* {tab == 'project updates' && <ProjectUpdates state={project} history={history} />} */}
                                    {tab == 'messages' && <Chat project={project} state={project} history={history} user={profile} />}
                                    {tab == 'images' && <ProjectImages computedMatch={computedMatch} project={project}  history={history} fetchProject={fetchProject} />}
                                    {tab == 'videos' && <ProjectVideos computedMatch={computedMatch} project={project}  history={history} fetchProject={fetchProject} />}
                                    {tab == '360 views' && <ProjectPano computedMatch={computedMatch} project={project}  history={history} fetchProject={fetchProject} />}
                                    {/* {tab == 'weekly report' && <ProjectUpdates state={project} history={history} />} */}
                                </div>

                            </div>
                            :
                            <div className="x-third">
                                <div className="x-body">
                                    <div className="x-subtitle">
                                        <p>Transaction history</p>
                                    </div>
                                    {mainTab == 'history' && <PaymentHistory history={history} state={project} invoices={invoices} />}
                                </div>

                            </div>}
                    </div>
                </div>
            </>
    )
}

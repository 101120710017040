
import * as types from '../../constant';


const initialState = {
  isLoading: false,
  error: null,
  isSuccessful: false,
  profile: null,
  notifications: null,
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.REFRESH_USER_PROFILE:
      return { ...state, profile: action.payload.profile, notifications:action.payload.notifications };
    case types.UPDATE_PROFILE_START:
      return { ...state, error: null, isSuccessful: false, isLoading: true };
    case types.UPDATE_PROFILE_SUCCESS:
      return { ...state, error: null, isSuccessful: true, isLoading: false, profile: action.payload };
    case types.UPDATE_PROFILE_FAIL:
      return { ...state, error: action.payload, isSuccessful: false, isLoading: false, };
    case types.UPDATE_PROFILE_CLEANUP:
      return { ...state, error: null, isSuccessful: false, isLoading: false, };
    default:
      return state;
  }
};

export default profileReducer;

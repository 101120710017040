export const PopupText = {
    dashboardBtn: {
        title: "Welcome to Build4Africa!",
        description: "Hello, thank you for signing up on Build4Africa! I’ll be your personal tour guide on this platform. Click the show me button so I can show you around. You can skip the tour guide if you want.",
        key: 'dashboard'
    },
    profile: {
        title: "Your profile",
        description: "You can access and edit and logout from your account using this thumbnail",
        key: 'profile'
    },
    notification: {
        title: "Your notifications",
        description: "You can access and see latest comments, notes and updates from your collaborators, and your project status here.",
        key: 'notification'
    },
    createProject: {
        title: "Create Project",
        description: "Create your first building project on Build4Africa. Answer a few questions about your project and you’re good to go!",
        key: 'createProject'
    },
    addFolders: {
        title: "Create Folders",
        description: "Create a folder for your project to organize your images, videos and 360 photos",
        key: 'addFolder'
    },
    addDrawing: {
        title: "Add drawings",
        description: "Upload your project plan either 2D or 3D drawing file straight from the architect lab.",
        key: 'drawings'
    },
    theatre: {
        title: "Theatre Mode",
        description: "View and compare your current site progress with your uploaded construction 2D/3D architectural drawing.",
        key: 'theatre'
    }
}
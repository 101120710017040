import React, { useState , useEffect} from 'react' 
import moment from 'moment'
import $ from 'jquery'
import { Button } from 'semantic-ui-react';
import AxiosCall from '../../config/axios';
export default function Chat({user, project, setChat, getUnreadChats}) {
    const [loading, setLoading] = useState(false);
  const [convo, setConvo] = useState([]);
  const [text, setText] = useState('');
    useEffect(() => {
        getChats()
       var interval = setInterval(() => {
        getChats()
    }, 1000);
       return () =>{
           clearInterval(interval)
       }
    }, [])
  const getChats = async () => {
    setLoading(true);
    try {
      var callObj = {
        method: "GET",
        path: "projects/getChat",
      };
      // if(filters.length || dateString || issue) {
      callObj.path +=
        "?projectId=" +
       project._id  
      // }
      var res = await AxiosCall(callObj);
      setConvo(res.chats);
      setLoading(false);
      $(".card-content.chat-content").animate({ scrollTop: 20000000 })
    } catch (error) {
      setLoading(false);
    }
  };

  const postChat = async () => {
    setLoading(true);
    try {
      var callObj = {
        method: "POST",
        path: "projects/createChat",
        data:{
           projectId: project._id,
            text
        }
      };
      var res = await AxiosCall(callObj);
      getChats()
      setText('')
    } catch (error) {
      setLoading(false);
    }
  }; 
    return (
        <div className="chat">
            <div id="chatApp">
                
                <div className="chatBox" id="chatBox">
                    <div className="card">


                        <div id="chatbox-area">
                            <div className="card-content chat-content">
                                <div className="content">
                                    {convo?.map((c,key)=> c.createdBy._id == user.uid ? 
                                    <div className="chat-message-group writer-user" key={key}>
                                        <div className="chat-messages">
                                            <div className="message">{c.text}</div>
                                            <div className="from">{c.createdBy.firstName||'admin'} {moment(c.createdAt).fromNow()}</div>
                                        </div>
                                    </div>
                                    :
                                    <div className="chat-message-group" key={key}>
                                    <div className="chat-thumb">
                                        <div className="image is-32x32">
                                            <img src="https://res.cloudinary.com/dn5kttwc8/image/upload/v1602561569/hold/user-img.png" style={{width: '40px', height: '40px'}} />
                                        </div>
                                    </div>
                                    <div className="chat-messages">
                                    <div className="message">{c.text}</div>
                                            <div className="from">{c.createdBy.firstName||'admin'} {moment(c.createdAt).fromNow()}</div>
                                    </div>
                                </div>
                                    )}
                                   
                                    {/* <div className="chat-message-group">
                                        <div className="chat-thumb">
                                            <figure className="image is-32x32">
                                                <img src="https://k0.okccdn.com/php/load_okc_image.php/images/32x0/971x939/0/10846088441021659030.webp?v=2" />
                                            </figure>
                                        </div>
                                        <div className="chat-messages">
                                            <div className="message">Olá meu nome é Marinho</div>
                                            <div className="message">Caro marinho</div>
                                            <div className="from">Hoje 04:55</div>
                                        </div>
                                    </div> */}
                                    {/* <div className="chat-message-group">
                                        <div className="typing">Typing</div>
                                        <div className="spinner">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <footer className="card-footer" id="chatBox-textbox">
                                <div style={{"width": "80%"}}>
                                    <textarea value={text} id="chatTextarea" className="chat-textarea" placeholder="Enter a text" onChange={(e)=>setText(e.target.value)}></textarea>
                                    {/* v-on:focus="expandTextArea()" v-on:blur="dexpandTetArea()" */}
                                </div>
                                <div className="has-text-centered" style={{"width": "20%", textAlign: 'center'}}>
                                    <a className="button is-white">
                                        <i className="fa fa-smile-o fa-5" aria-hidden="true"></i>
                                    </a>
                                    <Button color="black" onClick={()=> text && postChat()}>SEND</Button></div>
                            </footer>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}
 
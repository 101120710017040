import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Table } from "semantic-ui-react";
import eyes from "../../assets/eyes.png"
import chat from "../../assets/chat.png"
import moment from 'moment'
import UploadDrawings from "../pano/uploadDrawings";
import AxiosCall from "../../config/axios";
export default function FoldersCard({ setEdit , fetchProject}) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector((s) => s.project.data);
    const [folders, setFolders] = useState("") 
    const [images, setImages] = useState("") 
    const [videos, setVideos] = useState("") 
    const [panos, setPanos] = useState("") 
    const drawings = project.drawings
    const dispatch = useDispatch();
    useEffect(() => {
        if(project){
            var {folders} = project
             folders.map(f=>{
                project.drawings.map(d=>{
                    var imgs = []
                    var vids = []
                    var panos = []
                    d.images.map(i=>{
                        if(i.folderId == f._id){
                            imgs.push(i)
                        }
                    })
                    d.videos.map(i=>{
                        if(i.folderId == f._id){
                            vids.push(i)
                        }
                    })
                    d.pano.map(i=>{
                        if(i.folderId == f._id){
                            panos.push(i)
                        }
                    })
                    f.images = imgs
                    f.videos = vids
                    f.pano = panos
                })
            })
            setFolders(folders)
        }

    }, [project])
    const deleteFolder = async (folderId) => {
        try {
           var res = await AxiosCall({
                path: "projects/deleteFolder",
                data: { projectId: project._id, folderId},
                method: "POST"
            })
            fetchProject()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className="x-projectcard">
            {/* <UploadDrawings setOpen={setOpen} setEdit={setEdit} open={open} edit={edit} fetchProject={fetchProject}/> */}
            <Table fixed striped>
                <Table.Header>
                    <Table.Row >
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Folder title</Table.HeaderCell> 
                        <Table.HeaderCell > Images </Table.HeaderCell>
                        <Table.HeaderCell > Videos</Table.HeaderCell>
                        <Table.HeaderCell >360° photos</Table.HeaderCell>
                        <Table.HeaderCell >Last updated</Table.HeaderCell>
                        {/* <Table.HeaderCell> </Table.HeaderCell> */}
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {folders && folders.map(folder => (
                        <Table.Row   >
                            <Table.Cell >{moment(folder.dateCreated).format("DD-MMM-YYYY")}</Table.Cell>
                       
                            <Table.Cell>
                            {/* <Icon name="folder  outline" size="big"/> */}
                                 {folder.title}
                            </Table.Cell>
                            <Table.Cell > {folder.images?.length}</Table.Cell>
                            <Table.Cell > {folder.videos?.length}</Table.Cell>
                            <Table.Cell > {folder.pano?.length}</Table.Cell>
                            <Table.Cell >{moment(folder.dateUpdated).format("DD-MMM-YYYY")}</Table.Cell>
                            {/*                            
                            <Table.Cell> 
                            <div>
                               <Link to=""> <Icon name="photo" /> 23 </Link>
                            </div>
                            <div>
                               <Link to=""> <Icon name="globe" /> 23 </Link>
                            </div>
                            </Table.Cell> 
                            */}
                            <Table.Cell>
                                <Button basic onClick={()=>deleteFolder(folder._id)} >Delete </Button>  
                                <Button basic onClick={()=>{
                                  setEdit(folder)
                                }}>Edit</Button>  
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import { Button, Grid, Table, Accordion, Icon, Dropdown } from "semantic-ui-react";
import moment from 'moment'
import DeleteBtn from './DeleteBtn';
import AxiosCall from '../../config/axios';

export default function Folders({ project, fetchProject , setOpen, setEdit}) {
    const [modalOpen, setModalOpen] =  useState(false)
    const [deleteLoading, setDeleteLoading] = useState(null)

    const { folders, drawings } = project
  
    const deleteFolder = async (id) => {
        try {
            setDeleteLoading(id)
           var res =  await AxiosCall({
                path: "projects/deleteFolder",
                data: {  folderId:  id,  },
                method: "POST"
            })
            setDeleteLoading(false)
            fetchProject()
        } catch (error) {
            setDeleteLoading(false)
           console.log(error)
        }
    }
    const countFolderItems = (item, folderId,) => {

        var total = 0
       drawings.map(drawing => {
        drawing[item]?.map(i => {
            if (i.folderId == folderId) {
                total += 1
            }
        })
       })
        return total 
    }
    return (
           <div className="x-projectUpdates">
            {/* {activeFolder && <UploadView
                point={{ x: 1, y: 1 }} setOpen={setModalOpen}
                projectId={project?._id}
                active="image"
                activeFolder={activeFolder}
                open={modalOpen} edit={null} folders={folders} fetchProject={fetchProject} />} */}
            <Table fixed striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date created </Table.HeaderCell>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Images </Table.HeaderCell>
                        <Table.HeaderCell>Videos </Table.HeaderCell>
                        <Table.HeaderCell>360° photos </Table.HeaderCell>
                        <Table.HeaderCell>Last updated </Table.HeaderCell>
                        <Table.HeaderCell>Action </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    { 
                       folders?.map(folder => (folder?.archive  == true ? null :
                        <Table.Row >
                            {/* <Table.Cell><img src={folder.url}  width="100%"/></Table.Cell> */}
                            <Table.Cell>{moment(folder.dateUpdated).format("DD-MMM-YYYY")}</Table.Cell>
                            <Table.Cell>{folder.title}</Table.Cell>
                            <Table.Cell> {countFolderItems("images", folder._id)}</Table.Cell>
                            <Table.Cell> {countFolderItems("videos", folder._id)}</Table.Cell>
                            <Table.Cell>   {countFolderItems("pano", folder._id)} </Table.Cell>
                            <Table.Cell>{moment(folder.dateUpdated).format("DD-MMM-YYYY")}</Table.Cell>
                           
                            <Table.Cell>
                                <Button basic onClick={()=>{ setEdit(folder); setOpen(true)}} > <Icon name="pencil" /> </Button>  
                                <DeleteBtn onConfirm={()=>deleteFolder( folder._id)}>
                                     <Button basic loading={deleteLoading == folder._id}   > <Icon name="trash alternate outline" /> </Button>  
                                 </DeleteBtn>  
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Table , Icon} from "semantic-ui-react";
import logo from "../../assets/logo.png"
import moment from 'moment'
import PaymentSuccessful from "./paymentSuccessModal";
import DeleteBtn from "../project/DeleteBtn";
import AxiosCall from "../../config/axios";
import EditProject from "./EditProject";
export default function Projectcard({ projects, history, fetchProjects , role}) {
    const profile = useSelector((s) => s.auth.profile);
    const dispatch = useDispatch();
    const [open, setOpen] = useState('')
    const [projectOpen, setProjectOpen] = useState('')
    const [deleteLoadingId, setDeleteLoadingId] = useState(null)

    
    const deleteProject = async (projectId) => {
        try {
            setDeleteLoadingId(projectId)
           var res =  await AxiosCall({
                path: "projects/deleteProject",
                data: { projectId, },
                method: "POST"
            })
            fetchProjects()
            setDeleteLoadingId(null)
        } catch (error) {
            setDeleteLoadingId(null)
           console.log(error)
        }
    }
    const checkRole = (project) => {
        var role = 'viewer'
        if (project?.users?.length > 0) {
            project?.users?.map(u => {
                if (u.userId._id == profile.uid) {
                   role = u.role
                }
            })
        }
        if (project?.creatorID == profile?.uid) {
                   role =  'owner'
        }
        return role
    }
    return (
        <div className="x-projectcard">
            <PaymentSuccessful setOpen={setOpen} open={open}/>
            {projectOpen && <EditProject setOpen={setProjectOpen} open={projectOpen} fetchProjects={fetchProjects}/>}
            <Table fixed striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        {/* <Table.HeaderCell>Time</Table.HeaderCell> */}
                        <Table.HeaderCell>Project name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        {/* <Table.HeaderCell>Project status</Table.HeaderCell> */}
                        {/* <Table.HeaderCell>Uploaded file</Table.HeaderCell> */}
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {projects && projects.map(project => (
                        <Table.Row >
                            <Table.Cell>{moment(project.dateCreated).format("DD-MMM-YYYY")}</Table.Cell>
                            {/* <Table.Cell>{moment(project.createdAt).format("hh:mm a")}</Table.Cell> */}
                            <Table.Cell>{project.title}</Table.Cell>
                            <Table.Cell> {project.description}</Table.Cell>
                            {/* <Table.Cell> {project.status == "awaitingPayment" ? <Button basic color="yellow">Awaiting payment</Button> : project.status == "onGoing" ? <Button basic color="green">Ongoing</Button> : project.status == "confirmingPayment" ? <Button color="blue" basic>Confirm payment</Button> : <Button basic color="yellow"> Awaiting Meeting </Button>}</Table.Cell> */}
                            {/* <Table.Cell> 
                            {project.status == "confirmingPayment" && project?.paymentReceipt?.length &&
                           <center>
                               <a target="_blank" href={project?.paymentReceipt[0]?.url} >
                                <img src={project?.paymentReceipt[0]?.url} width="30px" /> 
                           </a>
                           </center> }
                            </Table.Cell> */}
                          <Table.Cell>
                            <Button basic onClick={() => history.push("/projects/" + project.slug)}>Take a tour</Button>
                                {/* <Button basic >Chat</Button> */}
                                <Button basic onClick={() => history.push("/projects/" + project.slug + "/collaborators")}>Collaborators</Button>
                                {checkRole(project) == 'owner' || checkRole(project) == 'editor' ?
                                 <DeleteBtn onConfirm={()=>deleteProject(project?._id)}>
                                     <Button basic loading={deleteLoadingId == project?._id}><Icon name="trash alternate outline" /></Button>
                                 </DeleteBtn> : null}
                                 <Button basic onClick={()=> { setProjectOpen(project) }} > <Icon name="pencil" /> </Button>  
                               
                                {/* <Button basic onClick={()=>setProject(project)}>Chat</Button> */}
                                {/* <Button basic onClick={() => history.push("/apps/" + project.slug + '/roles')}>Roles</Button> */}
                                {/* <Button basic onClick={() => history.push("/apps/" + project.slug+'?id=drawings')}>Drawings</Button>
                                <Button basic onClick={() => history.push("/apps/" + project.slug+'?id=folders')}>Folders</Button> */}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

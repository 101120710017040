import React, { useState, useEffect } from 'react'
import { Button, Form, Header, Icon, Image, Modal, Select } from 'semantic-ui-react'
import Mediaupload from '../uploader'
import { Link } from "react-router-dom";
import AxiosCall from '../../config/axios';
import { Dropdown } from 'semantic-ui-react'

function AddUser({ open, setOpen , fetchProject, setEdit , edit}) {
    var [loading, setLoading] = useState(false)
    var [email, setEmail] = useState("")
    var [error, setError] = useState("")
    var [role, setRole] = useState("")
    
    useEffect(() => {
       if(edit){
        setEmail(edit.email)
       }
    }, [edit])
    const upload = async () => {
        setLoading(true)
        try {
           var res =  await AxiosCall({
                path: "projects/addUser",
                data: { projectId: open, email, edit, role},
                method: "POST"
            })
            console.log(error,'jj')
            setLoading(false)
            fetchProject()
            setOpen('')
            setEmail("")
            setEdit(null)
        } catch (error) {
            setLoading(false)
            setError(error?.response?.data?.error)
        }
    }
  
    return (
        <Modal
            onClose={() => setOpen('')}
            open={open ? true: false}
            className="x-upload-receipt"
            size="tiny"
        >
            <Modal.Header>
            <div className="x-back" onClick={() => {
                                           
            }}>
                <Icon name="user  outline" />
                <span> Add user role</span>
            </div>
            </Modal.Header>
            <Modal.Content >
                <div className="x-content edit-doc">

                        <Form onSubmit={upload} warning>
                                                <Form.Field>
                                                    <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-name"
                                                        placeholder="Enter user email "
                                                        onChange={(e) => setEmail(e.target.value)}
                                                        required
                                                        size="massive"
                                                        icon="envelope"
                                                        iconPosition='left'
                                                        type="email"
                                                        value={email}
                                                    />
                                               
                                                    {/* <Select 
                                                        placeholder="Select folder"
                                                        options={arrange(folders)}
                                                        onChange={(e,s)=>console.log(s,  e.value)}
                                                    /> */}
                                                </Form.Field>
                                                <Form.Field>
                                                <Dropdown
                                                placeholder='Select a role'
                                                fluid
                                                selection
                                                value={role}
                                                text={role || "Select a role "}
                                            >
                                                 <Dropdown.Menu> 
                                                  <Dropdown.Item text={"Editor"} onClick={()=>setRole('editor')} />
                                                  <Dropdown.Item text={"Viewer"} onClick={()=>setRole('viewer')} />

                                                  <Dropdown.Item text={"Comments"} onClick={()=>setRole('comments')} />
                                                 </Dropdown.Menu>
                                                </Dropdown>
                                                </Form.Field> 
                                             <Form.Field>
                                             {error ? (
                    <div className="ui warning message">
                      <small>{error}</small>
                    </div>
                  ) : null}
            <br/>
            
                                        <Button type="submit" color="green" loading={loading} size="huge"  >
                                           ADD USER
                                        </Button>
                                             </Form.Field>
                                                </Form>
                  <div>
                  </div>
                </div>

            </Modal.Content>
            {/* <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nope
        </Button>
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions> */}
        </Modal>
    )
}

export default AddUser

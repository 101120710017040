import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Form, Grid, Divider, Icon, Checkbox } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import AxiosCall from "../../config/axios";
import { refreshProfile, updateProfileCleanup, updateProfileRequest } from "../../store/actions/profile";

export default function ProfileSetup({history}) {
  const profile = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const {isLoading, isSuccessful, error} = profile

useEffect(() => {
  if(profile?.profile?.registrationStage == 'verification'){
    history.push('/')
  } else if(profile?.profile?.registrationStage == 'accountSetup'){
    history.push('/accountSetup')
  }
}, [])

useEffect(() => {
  if(profile.isSuccessful){
      dispatch(updateProfileCleanup())
      history.push('/accountSetup')
  }
}, [profile])

  return (
    !profile.profile ? null :
    <div className="signin">
      <Grid columns="equal">
      <Grid.Column
          tablet="7"
          computer="7"
          className="no-padding "
          only="computer"
        >
          <div className="left-grid flex-justify"></div>
        </Grid.Column>
        <Grid.Column
          tablet="9"
          mobile="16"
          computer="9"
          className="no-padding"
        >
          <div className="right-grid ">
              <div className="x-container">
                <Form onSubmit={()=>dispatch(updateProfileRequest({
                  email : profile?.profile?.email, password, firstName, lastName, phone, registrationStage: 'accountSetup'
                }))} warning>
                <Link to="/"> <img src={process.env.PUBLIC_URL + "images/logo.png"} /></Link>
                  <h2 className="x-bold">Welcome to B4A.com</h2>
                  <div className="subtitle ">
                    Let’s get started with a few simple steps
                  </div>
                  <br/>
                  <Form.Group widths="equal">
                  <Form.Field>
                    <Form.Input
                      fluid
                      id="form-subcomponent-shorthand-input-first-name"
                      placeholder="First Name"
                      onChange={(e) => setFirstName(e.target.value)}
                      required
                      type="text"
                      size="massive"
                    />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      fluid
                      id="form-subcomponent-shorthand-input-first-name"
                      placeholder="Surname "
                      onChange={(e) => setLastName(e.target.value)}
                      required
                      type="text"
                      size="massive"
                    />
                  </Form.Field>
                  
                  </Form.Group>
                  <Form.Field>
                  <Form.Input
                      id='form-subcomponent-shorthand-input-password'
                      placeholder='Password'
                      name="password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      size='massive'
                  />
                  </Form.Field>
                  <Form.Field>
                    <Form.Input
                      fluid
                      id="form-subcomponent-shorthand-input-first-name"
                      placeholder="Phone"
                      onChange={(e) => setPhone(e.target.value)}
                      required
                      type="number"
                      size="massive"
                    />
                  </Form.Field>
                  {error ? (
                    <div className="ui warning message">
                      <small>{error}</small>
                    </div>
                  ) : null}
                
                <div >
                <Checkbox  />
                    I agree to the  <Link to="/signin"> Terms of service </Link> and <Link to="/signin"> Privacy policy </Link>
                  </div>
                  <br />  
                  <Button   loading={isLoading} fluid type="submit" color="green" size="huge">
                    SUBMIT
                  </Button>
                </Form>
              
               
              
              </div>

          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Grid } from "semantic-ui-react";
import AOS from "aos"
import 'aos/dist/aos.css';
import Navbar2 from "../../component/navbar/navbar";
import Love from "../../assets/love.png";
import Handshake from "../../assets/handshake.png";
import Location from "../../assets/location.png";
import Document from "../../assets/document.png";
import Capture from "../../assets/capture.png";
import Save from "../../assets/save.png";
import Degree from "../../assets/degree.png";
import House from "../../assets/house.png";
import Ellipse from "../../assets/ellipse.png";
import Ellipse1 from "../../assets/ellipse1.png";
import Ellipse2 from "../../assets/ellipse2.png";
import Photo1 from "../../assets/rect.png";
import Appstore from "../../assets/appstore.png";
import Playstore from "../../assets/googleplay.png";
import Logo from "../../assets/logo.png";
import Footer from "../../component/footer";
export default function Home(props) {
  const profile = useSelector((s) => s.auth.profile);
  const {history} = props
  useEffect(()=>{
    AOS.init();

  }, [])
  return (
    <>
      <Navbar2 {...props} auth={profile} />
      <div className="x-home">
        <section className="x-first-section">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile="16" tablet="8" computer="8">
                <div className="x-left" data-aos="fade-up">
                  <p className="x-pretext">
                    BUILD FROM ANYWHERE, MONITOR REMOTELY
                  </p>
                  <h1>
                    Your Construction Site in Your Pocket Everywhere You Go
                  </h1>
                  <p className="x-description">
                    B4A provides the next-generation 360 photo documentation
                    software for your site. By allowing you to track, document
                    and collaborate progress in form of 360 photos and video.
                    You can keep an eye on your construction site in real time
                    with B4A.
                  </p>
                  <Button size="large" primary onClick={()=> history.push("/signin")}>
                    Get Started
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="8" computer="8">
                <div className="x-right-img" data-aos="fade-left"></div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </section>
        <section className="x-second">
          <Grid>
            <Grid.Row>
              <Grid.Column mobile="16" tablet="8" computer="8">
                <div className="x-left" data-aos="fade-up-right">
                  <h2>See Your Site, from anywhere, anytime</h2>
                </div>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="8" computer="8">
                <div className="x-right"  data-aos="fade-up-left">
                  <p className="x-description">
                    Because B4D 360 photo documentation software is so easy to
                    use, you’ll have your site completely documented, all the
                    time. Drive accountability, minimize travel, reduce risk,
                    resolve conflicts–because a picture is worth a thousand
                    words.
                  </p>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile="16" tablet="2" computer="4"></Grid.Column>
              <Grid.Column mobile="16" tablet="12" computer="8">
                <div className="x-works" data-aos="flip-up">
                  <p className="x-pretext">HOW IT WORKS</p>
                  <h2>
                    Introducing the new way to build, the new concept to
                    communicate with your clients
                  </h2>
                </div>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="2" computer="4"></Grid.Column>
            </Grid.Row>
            <Grid.Row className="x-boxes">
              <Grid.Column mobile="16" tablet="4" computer="4">
                <div className="x-box" data-aos="zoom-out-up"  data-aos-duration="100">
                  <a href="">Pick a Location</a>
                  <img src={Location} />
                  <p>
                    {" "}
                    Select a prefered location on your construction site or
                    plan.{" "}
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="4" computer="4">
                <div className="x-box" data-aos="zoom-out-up"  data-aos-duration="200">
                  <a href="">Select a document type</a>
                  <img src={Document} />
                  <p>
                    B4A provides the next-generation 360 photo documentation
                    software for your site.
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="4" computer="4">
                <div className="x-box" data-aos="zoom-out-up"  data-aos-duration="300">
                  <a href="">Choose Capture Method</a>
                  <img src={Capture} />
                  <p>
                    Select from three(3) capture methods to view and access your
                    jobsite.
                  </p>
                </div>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="4" computer="4">
                <div className="x-box" data-aos="zoom-out-up"  data-aos-duration="400">
                  <a href="">Toggle to share/save</a>
                  <img src={Save} />
                  <p>
                    Easily share between teams, colleagues or stakholders for
                    collaboration.
                  </p>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column mobile="16" tablet="2" computer="4"></Grid.Column>
              <Grid.Column mobile="16" tablet="12" computer="8">
                <div className="x-trust" data-aos="zoom-out-up">
                  <p className="x-pretext">PEOPLE TRUST US</p>
                  <div className="x-content">
                    <p>
                      <i>“We are able to direct workers more effectively by
                      instantly obtaining the most recent photographic data from
                      every location of the construction. This eliminates the
                      requirement for project managers to physically visit each
                      place to create location-specific plans with a large
                      number of foremen and superintendents.”</i>
                    </p>
                    <p>
                        <b>Oluwaseyi Daniel</b> <br />
                        <span> Chief Executive Officer Fluenttera </span>
                    </p>
                    <img src={Ellipse1} />
                    <img src={Ellipse} className="active" />
                    <img src={Ellipse2} />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile="16" tablet="2" computer="4"></Grid.Column>
            </Grid.Row>
          </Grid>
        </section>
        <section className="x-third">
          <Grid>
            <Grid.Column mobile="16" tablet="2" computer="2"></Grid.Column>
            <Grid.Column mobile="16" tablet="6" computer="6">
              <div className="x-box blue" data-aos="flip-left">
                <h3>Access Anytime</h3>
                <p>
                  Track your site progress on the go and be confident in your
                  numbers wherever you are, at your preferred time.
                </p>
                <img src={Love} />
              </div>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="6" computer="6">
              <div className="x-box darkblue"  data-aos="flip-right">
                <h3>Easy Collaboration</h3>
                <p>
                  Using the context of photographs, you may rapidly add
                  comments, tag a team member, and convey a field issue.
                </p>
                <img src={Handshake} />
              </div>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="2" computer="2"></Grid.Column>
            <Grid.Column mobile="16" tablet="2" computer="2"></Grid.Column>
            <Grid.Column mobile="16" tablet="6" computer="6">
              <div className="x-box lightblue"data-aos="flip-left" >
                <h3>Capture 360 Views</h3>
                <p>
                  Use a 360-degree camera to record footage as you go around the
                  worksite, and then have those photos mapped to the floor plan
                  to create a virtual tour that takes you through the whole
                  space in 360 degrees.
                </p>
                <img src={Degree} />
              </div>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="6" computer="6">
              <div className="x-box blue"  data-aos="flip-right">
                <h3>Review Progress</h3>
                <p>
                  When utilizing B4A, you may use the timeline of capture dates
                  to look back at earlier stages of development and compare
                  progress to your present timetable.
                </p>
                <img src={House} />
              </div>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="2" computer="2"></Grid.Column>
          </Grid>
        </section>
        <section className="x-download">
          <Grid>
            <Grid.Column mobile="16" tablet="8" computer="8">
              <div className="x-left " data-aos="zoom-in-right">
                <p className="x-pretext">
                  ON WEB AND MOBILE, YOU’RE IN CONTROL
                </p>
                <h2>Developed to be Mobile Friendly</h2>
                <p>
                  On Web and Mobile, your project are closer than you could ever
                  imagine. Access pictures and contents from anywhere, whenever
                  you want.
                </p>
                <p>*Coming soon on Android and Apple Stores</p>
                <div className="x-stores">
                  <img src={Appstore} />
                  <img src={Playstore} />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="8" computer="8">
              <div className="x-mobile-img"  data-aos="zoom-in-left"></div>
            </Grid.Column>
          </Grid>
        </section>
        <section className="x-software">
          <Grid>
            <Grid.Column mobile="16" tablet="8" computer="8">
              <div className="x-mobile-img"  data-aos="zoom-in-right"></div>
            </Grid.Column>
            <Grid.Column mobile="16" tablet="8" computer="8">
              <div className="x-right"  data-aos="zoom-in-left">
                <p className="x-pretext">B4A FOR CONSTRUCTION ENGINEERS</p>
                <h2>
                  Finally, a site documentation software that works for you.
                </h2>
                <p>
                  Consistently provide flawless client experiences. With Build 4
                  Africa, you have access to a quick, lightweight, and intuitive
                  site documentation studio that enables you to deliver even the
                  most ambitious ideas exactly as you picture them.
                </p>
                <p>*Coming soon on Android and Apple Stores</p>
              </div>
            </Grid.Column>
          </Grid>
        </section>
        <section className="x-why">
          <Grid>
            <Grid.Row>
              {/* <Grid.Column mobile="16" tablet="4" computer="4"></Grid.Column> */}
              <Grid.Column mobile="16" tablet="16" computer="16">
                <div className="x-trust"  data-aos="zoom-in-up">
                  <div className="x-eng">
                  <h2>Why Engineers Like <br/> Build 4 Africa</h2>
                  </div>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile="16" tablet="4" computer="4">
                        <h3>Save time</h3>
                        <p>Never worry about wasting time travelling </p>
                      </Grid.Column>
                      <Grid.Column mobile="16" tablet="4" computer="4">
                        <h3>Plan more efficiently</h3>
                        <p>Spend less on paperworks and abstract information</p>
                      </Grid.Column>
                      <Grid.Column mobile="16" tablet="4" computer="4">
                        <h3>Collaborate easily</h3>
                        <p>
                          Exchange messages and chats with team members and
                          stakeholders
                        </p>
                      </Grid.Column>
                      <Grid.Column mobile="16" tablet="4" computer="4">
                        <h3>Lower Workload</h3>
                        <p>Just share one link, we’ll handle the rest</p>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </Grid.Column>
              {/* <Grid.Column mobile="16" tablet="4" computer="4"></Grid.Column> */}
            </Grid.Row>
          </Grid>
        </section>
        <section className="x-outro" >
           <div className="bg" data-aos="flip-left">
           <img src={Logo} />
            <h2>Get Started Now!</h2>
            <p>Setup is easy and takes under 5 minutes</p>
            <Button primary size="large" onClick={()=> history.push("/signin")}>Get Started</Button>
            <p>100’s engineers and clients have started</p>
           </div>
        </section>
      </div>
      <Footer />
    </>
  );
}


import * as types from '../../constant';


 const initialState = {
    isLoading: false,
    error: null,
    isSuccessful: false,
    data: []
  };

const getInvoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_INVOICE_START:
      return { ...state, isLoading: true, error: null };
    case types.GET_INVOICE_SUCCESS:
      return { ...state,  isLoading: false, isSuccessful: true , data: action.payload};
    case types.GET_INVOICE_FAIL:
      return { ...state, error: action.payload, isLoading: false };
    case types.GET_INVOICE_CLEANUP:
      return { ...state, error: null, isLoading: false, isSuccessful: false };
    default:
      return state;
  }
};

export default getInvoiceReducer;

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon, Dropdown } from "semantic-ui-react";
import logo from "../../assets/logo.png"
import moment from "moment"
import $ from "jquery"
import AxiosCall from "../../config/axios";
import { refreshProfile } from "../../store/actions/profile";
import PopupBtn from "../ui/PopupBtn";
import { PopupText } from "../../utils/PopupText";
export default function Navbar(props) {
    const [unreads, setUnreads] = useState(0)
    const [openMenu, setOpenMenu] = useState(false)
    const { history } = props
    const profile = useSelector((s) => s.auth.profile);
    const notifications = useSelector((s) => s.auth.notifications);
    const dispatch = useDispatch();
    useEffect(() => {
        $(window).scrollTop(0)
    }, [])
  
    const logout = () => {
        window.localStorage.clear()
        window.location.assign("/signin")
    }

    useEffect(() => {
        if(notifications){
            var unreads = 0
            notifications?.map(n=>{
                n.userList.map(u=>{
                    if(u.userId == profile.uid && u.read == false) {
                        unreads+= 1
                    }
                })
            })
            setUnreads(unreads)
        }
    }, [notifications])
    const checkForUnread = (n) => {
        var unread = ''
        n.userList.map(u=>{
            if(u.userId == profile.uid && u.read == false) {
                unread = 'unread'
            }
        })
        return  unread
    }
    const gotoNot = async (n) => {
        try {
            var res = await AxiosCall({
                method: 'POST',
                path: 'auth/markNotAsRead',
                data: {notificationId: n._id}
            })
            dispatch(refreshProfile({profile, notifications: res.notifications}))
        } catch (error) {
            console.log(error)
        }
        if(n.for == 'chat') {
        props.history.push('/projects/' + n.projectId?.slug + '&message=true')

        } else   props.history.push('/projects/' + n.projectId?.slug + "&d=" + n.drawingId+ "&p=" + n.panoId + "&m=" + n.markerId +  "&f=" + n.fileType )
    }
    const alarm = () => (
        <Dropdown pointing="top right" icon={<Link to="#" className={window.location.pathname == '/notification' && "active"}>
            <Icon name="bell outline" pointing />
        </Link>}>

            <Dropdown.Menu>
                <Dropdown.Header>Notifications</Dropdown.Header>
                <Dropdown.Divider />
                {notifications?.map((n, k) => (
                    <Dropdown.Item className={checkForUnread(n)} key={k} onClick={() => gotoNot(n)} text={
                        <div className="x-notification">
                            <div className="x-col">
                                <img src={n.for == 'chat' ? process.env.PUBLIC_URL + '/images/chatimg.jpeg' : n?.thumbnail || n?.drawingId?.url} />
                            </div>
                           {n.for == 'chat' ? 
                            <div className="x-col">
                            <p> {n?.initiatedBy?.firstName} sent a chat in {n.projectId.title}  </p>
                            <p>  {n.title}  </p>
                            <small>{moment(n.dateCreated).fromNow()}</small>
                            
                            </div> : 
                             <div className="x-col">
                             <p>  {n.title}  </p>
                                <p>  {n.description}  </p>
                                <small>{moment(n.dateCreated).fromNow()}</small>
                         </div>}
                        </div>} />
                ))}
            </Dropdown.Menu>
        </Dropdown>
    )
    const profileDropdown = () => (
        <Dropdown pointing="top right" icon={<Link to="#" className={window.location.pathname == '/profile' && "active"}>
            <img src={profile.photoURL} />
        </Link>}>
            <Dropdown.Menu>
                <Dropdown.Header>{profile?.firstName} {profile?.lastName}</Dropdown.Header>
                <Dropdown.Divider />
                <Dropdown.Item icon='logout' text='Logout' onClick={logout} />
                {/* <Dropdown.Item icon='trash' text='Move to trash' /> */}
                {/* <Dropdown.Divider /> */}
            </Dropdown.Menu>
        </Dropdown>
    )
    return (
        <>
            <div className="x-navbar">
                <ul className="desktop">
                    <li>
                        <Link to="">
                            <img src={logo} />
                        </Link>
                    </li>
                    {/* <li>
                        <Link to="/projects" className={window.location.pathname == '/dashboard' && "active"}>  Home
                    </Link>
                    </li> */}
                    <li>
                    <Link to="/projects" className={window.location.pathname == '/projects' && "active"}>  Dashboard </Link>
                        <PopupBtn title={PopupText.dashboardBtn.title} content={PopupText.dashboardBtn.description} classNames="x-left" keyholder="dashboard"> </PopupBtn>
                    </li>
                    {/* <li>
                        <Link to="/explore" className={window.location.pathname == '/explore' && "active"}>  Explore
                    </Link>
                    </li> */}
                    {/* <li>
                    <Link to="/payment" className={window.location.pathname == '/explore' && "active"}>  Payment  
                    </Link>
                </li> */}
                    <li className="notification">

                        {alarm()}
                        {unreads > 0 && <span className="alert">{unreads} new</span>}
                        <PopupBtn title={PopupText.notification.title} content={PopupText.notification.description} classNames="x-right" keyholder="notification"> </PopupBtn>

                    </li>
                    <li className="profile">
                        {/* <Link to="/profile" className={window.location.pathname == '/profile' && "active"}>     
                        <img src={profile.photoURL} />  
                    </Link> */}
                        {profileDropdown()}
                        <PopupBtn title={PopupText.profile.title} content={PopupText.profile.description} classNames="x-right" keyholder="profile"> </PopupBtn>
                    </li>
                </ul>
                <ul className="x-mobile"> 
                {/* <li className="notification">

                {alarm()}
                {unreads > 0 && <span className="alert">{unreads} new</span>}

                </li> */}
                    <li>
                        <a href="#" onClick={() => setOpenMenu(!openMenu)}> {!openMenu ? <Icon name="bars" /> : <Icon name="close" />}</a>
                    </li>
                </ul>

            </div>
            <ul className={openMenu ? "x-menulist open" : "x-menulist closed"}>
           
            {profile?.firstName && <li>
                <Link to="/projects" ><Icon name="dashboard" />  Projects    </Link> 
            </li>}
            {profile?.firstName && <li>
                <Link to="/new-project" ><Icon name="pencil" />  New project    </Link> 
            </li>}
                <li>
                    {profile?.firstName ?
                        <Link to="/projects" ><Icon name="building outline" />  Dashboard    </Link> :
                        <Link to="/signin" ><Icon name="street view" />   Get Started    </Link>}
                </li>
                <li>  {profile?.firstName ?
                    <a href="#" onClick={logout}><Icon name="sign out" /> Log out    </a> :
                    <Link to="/signin" > <Icon name="user circle outline" />  Login    </Link>}</li>
            </ul>
        </>
    )
}

import React, { } from 'react';
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux";


export default function Privateroute(props) {
    const user = useSelector(s => s.auth.profile)
      var profile = window.localStorage.getItem("userData")
    profile = profile && JSON.parse(profile)
    const checkRole = ({ match, location, history }) => {
        console.log(user)
        if(user === undefined) {}
else if (profile.registrationStage != 'completed'  && match.path == "/projects")
            return <Redirect
                to={{
                    pathname: "/",
                    state: { from: props.location }
                }}
            />
        else if (profile.role === "admin" && match.path !== "/admin")
            return <Redirect
                to={{
                    pathname: "/admin",
                    state: { from: props.location }
                }}
            />
        else
            return <props.component {...props}  history={history}/>
    }
    return (
        <Route
            path={props.path}
            render={(p) => profile ?
                checkRole(p)
                : <Redirect
                    to={{
                        pathname: "/signin",
                        state: { from: props.location }
                    }}
                />
            }
        />
    )
}


function mapStateToProps(state) {
    return { auth: state.auth }
}


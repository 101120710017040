import React, { useState, useEffect } from 'react'
import { Button, Dropdown, Form, Header, Icon, Image, Modal } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import AxiosCall from '../../config/axios';

function AddAnnotation({ open, setOpen , fetchProject, point , setEdit, edit , drawingId}) {
    var [url, setUrl] = useState('')
    var [loading, setLoading] = useState(false)
    var [description, setDescription] = useState("")
    var [annotationType, setAnnotationType] = useState('comment')
 
    useEffect(() => {
       if(edit){
        // setUrl(edit.url)
        setDescription(edit.comment)
        setAnnotationType(edit.markerType)
       }
    }, [edit])
    const upload = async () => {
        // if(!url) return false
        setLoading(true)
        try {
           var res = await  AxiosCall({
                path: "projects/addAnnotation",
                data: {url, pano: open, annotationType, description, point ,edit, drawingId},
                method: "POST"
            })
        setLoading(false)
        fetchProject()
            setOpen("")
            setDescription("")
            setUrl("")
            setEdit(null)
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const arrange = (folders) => {
        var data = []
        folders?.map(f => data.push({text: f.description, key: f._id, value: f._id}))
        return data
    }

    return (
        <Modal
            onClose={() => {
                setOpen('')
                setEdit(null)
            }}
            open={open ? true: false}
            className="x-upload-receipt"
            size="tiny"
        >
            <Modal.Header>
            <div className="x-back" onClick={() => {
                                           
            }}>
                <Icon name="chevron left" />
                <span> Back</span>
            </div>
            </Modal.Header>
            <Modal.Content >
                <div className="x-content edit-doc">
                    {/* <Mediaupload cb={setUrl}  header="Upload a 360° image"/>
                    {url &&  <div className="x-url">
                        <a href="#" onClick={() => window.open(url, "__blank")}> {url.split("/")[4]}</a>
                        
                        </div>} */}
                        <Form onSubmit={upload} warning>
                                              
                                                {/* <Form.Field>
                                                <Dropdown
                                                placeholder='Select annotation type'
                                                fluid
                                                selection
                                                value={annotationType}
                                                text={annotationType || "Select annotation type"}
                                            >
                                                 <Dropdown.Menu>
                                                 
                                                     
                                                  <Dropdown.Item text={"Comment"} key="comment" onClick={()=>setAnnotationType('comment')} />
                                                 </Dropdown.Menu>
                                                </Dropdown>
                                                </Form.Field> */}
                                             <Form.Field>
                                             {annotationType == 'comment' && <Form.Field>
                                                    <Form.TextArea
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-name"
                                                        placeholder="Enter a comment "
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        required
                                                        size="massive"
                                                        icon="pencil"
                                                        iconPosition='left'
                                                        value={description}
                                                    />
                                                    
                                                </Form.Field>}
                                            
            <br/>
                                        <Button type="submit" color="green" loading={loading} size="huge"   >
                                            {edit ? "EDIT" : "ADD"} ANNOTATION  
                                        </Button>
                                             </Form.Field>
                                                </Form>
                  <div>
                  </div>
                </div>

            </Modal.Content>
            {/* <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nope
        </Button>
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions> */}
        </Modal>
    )
}

export default AddAnnotation

import * as types from '../../constant';
import AxiosCall, { setAuthorizationToken } from '../../../config/axios';
import { refreshProfile, refreshVendors, setActiveProfile } from '../profile';

const loginStart = () => {
  return { type: types.USER_LOGIN_START };
};
export const loginSuccess = payload => {
  return { type: types.USER_LOGIN_SUCCESS, payload };
};

export const loginFail = payload => {
  return { type: types.USER_LOGIN_FAIL, payload };
};

export const cleanupLoginError = () => {
  return { type: types.USER_LOGIN_CLEANUP };
};

const resetCodeVerificationStart = () => {
  return { type: types.RESET_CODE_VERIFICATION_START };
};
export const resetCodeVerificationSuccess = payload => {
  return { type: types.RESET_CODE_VERIFICATION_SUCCESS, payload };
};

export const resetCodeVerificationFail = payload => {
  return { type: types.RESET_CODE_VERIFICATION_FAIL, payload };
};

export const cleanupResetCode = () => {
  return { type: types.RESET_CODE_VERIFICATION_CLEANUP };
};
export const loginRequest = user => {
  return async dispatch => {
    dispatch(loginStart());
    try {
      const callObj = {
        method: 'POST',
        path: user.fullName || user.userToken ? 'auth/socialLogin' :  'auth/signin',
        data: user
      };
      const response = await AxiosCall(callObj);
      var userData = JSON.stringify(response.userData);
     
      localStorage.setItem('userData', userData);
      localStorage.setItem('token', response.token);
      setAuthorizationToken(response.token)
      dispatch(refreshProfile({profile: response.userData, notifications: response.notifications}));
      // dispatch(loginSuccess(response.userData));
    } catch (e) {
      let errorResponse;
      // console.log(e)
      if (e.response) {
        const { error } = e.response.data;
        errorResponse = error;
      } else {
        errorResponse = 'Something went wrong. please try again';
      }
      console.log(e)
      dispatch(loginFail(errorResponse)); 
    }
  };
};


export const sendPasswordResetCode = user => {
  return async dispatch => {
    try {
      const callObj = {
        method: 'POST',
        path: 'auth/recoverPassword',
        data: user
      };
      const response = await AxiosCall(callObj);
    } catch (e) {
      let errorResponse;
      if (e.response) {
        const { error } = e.response.data;
        errorResponse = error;
      } else {
        errorResponse = 'Something went wrong. please try again';
      }
    }
  };
};


export const resetConfirmation = data => {
  return async dispatch => {
    try {
      dispatch(resetCodeVerificationStart());
      const callObj = {
        method: 'POST',
        path: 'auth/resetConfirmation',
        data
      };
      const response = await AxiosCall(callObj);
      dispatch(resetCodeVerificationSuccess(response.token));
    } catch (e) {
      let errorResponse;
      if (e.response) {
        const { error } = e.response.data;
        errorResponse = error;
      } else {
        errorResponse = 'Something went wrong. please try again';
      }
      dispatch(resetCodeVerificationFail(errorResponse));
      console.log(errorResponse)
    }
  };
};




export const resetPassword = data => {
  return async dispatch => {
    try {
      dispatch(resetCodeVerificationStart());
      const callObj = {
        method: 'POST',
        path: 'auth/resetPassword',
        data
      };
      const response = await AxiosCall(callObj);
      dispatch(resetCodeVerificationSuccess());
    } catch (e) {
      let errorResponse;
      if (e.response) {
        const { error } = e.response.data;
        errorResponse = error;
      } else {
        errorResponse = 'Something went wrong. please try again';
      }
      dispatch(resetCodeVerificationFail(errorResponse));
    }
  };
};


export const refreshUserToken = () => {
  return async dispatch => {
    try {
      const callObj = {
        method: 'GET',
        path: 'auth/refresh'
      };
      const response = await AxiosCall(callObj);
      setAuthorizationToken(response.token)
      var userData = JSON.stringify(response.userData);
      localStorage.setItem('userData', userData);
     
    }
    catch (e) {
      console.log(e)
    }
  }
}


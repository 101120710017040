 
import React, { Component } from 'react';
import { createRoot } from 'react-dom/client';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';

// the first very simple and recommended way:
const ImgComponent = ({url, x=0, y=0, canvasHeight, canvasWidth, dblClick}) => {
  const [image] = useImage(url);
  return <Image onDblClick={dblClick} image={image} draggable x={x} y={y} width={canvasWidth} height={canvasHeight} />;
};


const ImageCanvas = ({activeImagePano,  canvasWidth=window.innerWidth, canvasHeight=window.innerHeight})=> {
    const dblClick = (prop)=>{
        console.log(prop)
        alert('yep')
    }
    return (
      <Stage width={canvasWidth} height={canvasHeight} >
        <Layer>
          {/* <URLImage  src="https://konvajs.org/assets/yoda.jpg" x={150} /> */}
          <ImgComponent 
           dblClick={dblClick}
           url={activeImagePano?.url}  canvasWidth={canvasWidth} canvasHeight={canvasHeight}/>
        </Layer>
      </Stage>
    );
}
export default ImageCanvas
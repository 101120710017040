import * as types from '../../constant';
import AxiosCall, { setAuthorizationToken } from '../../../config/axios';

const getProjectStart = () => {
  return { type: types.GET_PROJECT_START };
};
const getProjectSuccess = payload => {
  return { type: types.GET_PROJECT_SUCCESS, payload };
};
const getProjectFoldersSuccess = payload => {
  return { type: types.GET_PROJECT_FOLDERS_SUCCESS, payload };
};
export const getProjectFail = payload => {
  return { type: types.GET_PROJECT_FAIL, payload };
};

export const getProjectCleanUp = () => {
  return { type: types.GET_PROJECT_CLEANUP };
};

export const getProjectReset = () => {
  return { type: types.GET_PROJECT_RESET };
};
export const getProjectRequest = slug => {
  return async dispatch => {
    dispatch(getProjectStart());
    try {
      const callObj = {
        method: 'GET',
        path: 'projects/fetchProject?slug='+slug,
      };
      const data = await AxiosCall(callObj);
      dispatch(getProjectSuccess(data.project));
    } catch (e) {
        let errorResponse;
        if (e.response) {
          const { error } = e.response.data;
          errorResponse = error;
        } else {
          errorResponse = 'Something went wrong. please try again';
        }
      dispatch(getProjectFail(errorResponse));
    }
  };
};


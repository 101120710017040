import React, { useState, useEffect } from 'react'
import { Button, Dropdown, Form, Header, Icon, Image, Modal } from 'semantic-ui-react'
import Mediaupload from '../uploader'
import { Link } from "react-router-dom";
import AxiosCall from '../../config/axios';
import UploadFolder from './uploadFolder';

function UploadView({ open, setOpen, fetchProject, point, folders, edit, projectId , active, activeFolder}) {
    var [url, setUrl] = useState('')
    var [urls, setUrls] = useState([])
    var [loading, setLoading] = useState(false)
    var [title, setTitle] = useState("")
    var [folderId, setFolderId] = useState(null)
    var [fileType, setFileType] = useState(active || '')
    var [openFolder, setFolderOpen] = useState(null)
    useEffect(()=>{
        activeFolder && setFolderId(activeFolder) 
    },[activeFolder])
    useEffect(() => {
        if (edit) {
            // setUrl(edit.url)
            setUrls([edit.url])
            setTitle(edit.title)
            setFileType(edit.fileType)
        }
    }, [edit])
    const upload = async () => {
        if (!urls) return false
        setLoading(true)
        try {
            var check = []
           urls.forEach(async (url, key)=>{
            var res = await AxiosCall({
                path: "projects/uploadViews",
                data: { url, drawingId: open, folderId: folderId._id, title, point, edit, fileType },
                method: "POST"
            })
            check.push(key)
            if(check.length == urls.length){
                setLoading(false)
                fetchProject()
                setOpen("")
                setTitle("")
                setUrls([])
            }
           })
        } catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    const arrange = (folders) => {
        var data = []
        folders?.map(f => data.push({ text: f.title, key: f._id, value: f._id }))
        return data
    }

    const checkUploadText = () => {
        var text = 'Upload file'
        if (fileType == '360 image') {
            text = "Upload a 360° image"
        } else if (fileType == 'image') {
            text = "Upload an image"
        } else if (fileType == 'video') {
            text = "Upload a video"
        }
        return text
    }

    return (
        <>
            <UploadFolder setOpen={setFolderOpen} open={openFolder} fetchProject={fetchProject} folders={folders} edit={null} />
            <Modal
                onClose={() => setOpen('')}
                open={open ? true : false}
                className="x-upload-receipt"
                size="tiny"
            >
                <Modal.Header>
                    <div className="x-back" onClick={() => {

                    }}>
                        <Icon name="upload" />
                        <span> Upload file</span>
                    </div>
                </Modal.Header>
                <Modal.Content >
                    <div className="x-content edit-doc">
                        {fileType && <>
                            <Mediaupload mediaType={fileType == 'video' ? 'video/*' : null} cb={setUrls} header={checkUploadText()} />
                            {urls.map((url,key) =>(
                                <div className="x-url" key={key}>
                                    <a href={url} target="_blank"> {url.split("/")[4]}</a>
                                </div>
                            ))}
                        </>}
                        <Form onSubmit={upload} warning>
                            <Form.Field>
                                <Dropdown
                                    placeholder='Select file type'
                                    fluid
                                    selection
                                    value={fileType}
                                    text={fileType || "Select file type"}
                                    disabled={active ? true : false}
                                >
                                    <Dropdown.Menu>

                                        <Dropdown.Item text={"Image"} onClick={() => setFileType('image')} />
                                        <Dropdown.Item text={"Video"} onClick={() => setFileType('video')} />
                                        <Dropdown.Item text={"360° image"} onClick={() => setFileType('360 image')} />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Form.Field>
                            {/* <Form.Field>
                                                    <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-name"
                                                        placeholder="Enter a title "
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        required
                                                        size="massive"
                                                        icon="pencil"
                                                        iconPosition='left'
                                                        value={title}
                                                    />
                                                    
                                                </Form.Field> */}

                            <Dropdown
                                placeholder='Select a Folder'
                                fluid
                                selection
                                value={folderId?._id}
                                text={ folderId?.title || "Select a Folder"  }
                            >
                                <Dropdown.Menu>

                                    <Button color="grey" fluid onClick={() => setFolderOpen(projectId)}>Add Folder</Button>
                                    {folders?.map((f, k) =>
                                        <Dropdown.Item text={f.title} key={k} onClick={() => setFolderId(f)} />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            <Form.Field>
                                {/* {(url) && <div className="x-url">
                                    <a href="#" onClick={() => window.open('https://'+url, "__blank")}> {url.split("/")[3]}</a>

                                </div>} */}
                                <br />
                                <Button type="submit" color="green" loading={loading} size="huge" disabled={!urls}  >
                                    UPLOAD {fileType == "video" ? 'VIDEO' : 'IMAGE'}
                                </Button>
                            </Form.Field>
                        </Form>
                        <div>
                        </div>
                    </div>

                </Modal.Content>
                {/* <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nope
        </Button>
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions> */}
            </Modal>
        </>
    )
}

export default UploadView

import React, { createRef, useState, useEffect } from 'react'
import { Button, Dropdown, Grid, Table } from "semantic-ui-react";
import Carousel from './carousel';
import moment from "moment"
import { Viewer } from "photo-sphere-viewer"
import "photo-sphere-viewer/dist/photo-sphere-viewer.css"
import { Accordion, Icon } from 'semantic-ui-react'
import { MarkersPlugin } from 'photo-sphere-viewer/dist/plugins/markers';
import Ticket from './ticket';
import { useSelector } from 'react-redux';
import AddAnnotation from '../pano/addAnnotation';
import { generateRandomNumber } from '../../utils';

const imgUrl = "https://www.citypng.com/public/uploads/small/11641399344inlsxmiu4bj37nntjelor6xmeafh4yyzzyrquazl5sneyp7m9h5j25n0kwvujtle1a2kvnljj93ejntd9txu4kjhjkwkmgwym9ok.png"
const markerHeight = 50
const markerWidth = 50

export default function Panorama({ project, fetchProject, activeItem, activeDrawing, defaultTicket }) {
    const user = useSelector((s) => s.auth.profile);
    const [PSV, setPSV] = useState(null)
    const [activeImage, setActiveImage] = useState("")
    // const [activeDrawing, setActiveDrawing] = useState(null);
    const [activeFolder, setActiveFolder] = useState(null);
    const [activePano, setActivePano] = useState(null);
    const [activeTicket, setActiveTicket] = useState(null);
    const [activeIndex, setActiveIndex] = useState([]);
    const [panos, setPanos] = useState([]);
    const [markers, setMarkers] = useState([])
    const [editor, setEditor] = useState(false)
    const [activePanoPoint, setActivePanoPoint] = useState(null)
    const [openAnotModal, setOpenAnotModal] = useState(null)

    const { folders, drawings } = project
    const sphereElementRef = createRef()
    useEffect(()=>{
      let params = new URLSearchParams(window.location.pathname);
      var markerId = params.get('m')?.toString()
      if(markerId){
        var m = activeItem?.markers?.filter(m => m._id == markerId)
        console.log(m)
       if(m) setActiveTicket(m[0])
      }
    }, [])
    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        if (activeIndex.includes(index)) {
            setActiveIndex(activeIndex.filter(i => i != index))
        } else {
            setActiveIndex([...activeIndex, index])
        }
        // const newIndex = activeIndex === index ? -1 : index

        // setActiveIndex(newIndex)
    }

    useEffect(() => {
      
        setActivePano(activeItem)
        project.users.map(u => {
            if ((u.userId._id == user.uid && (u.role == "editor" || u.role == 'comments') || (user.uid == project.creatorID?._id))) setEditor(true)
        })
    }, [project])
    useEffect(() => {
        load()

    }, [])

    useEffect(() => {
        if (activeDrawing) {
            setPanos(activeDrawing.pano)
        }
    }, [activeDrawing])

    useEffect(() => {
        if (activePano && PSV) {
            const markersPlugin = PSV.getPlugin(MarkersPlugin);
            if (activePano.url != PSV.config.panorama) markersPlugin.clearMarkers()
            PSV.setPanorama(activePano.url).then(p => console.log(p)).catch(e => console.log(e))
        }
    }, [activePano])

    const load = () => {
        var PSV = new Viewer({
            container: sphereElementRef.current,
            // panorama: activeImage || project.panorama[0],
            plugins: [
                [MarkersPlugin, {
                    markers,
                }],
            ],
        })
        setPSV(PSV)
    }

    const checkForMarkerType = (marks) => {
        marks.map(m => {
            m.image = imgUrl
            m.width = markerWidth
            m.height = markerHeight,
                m.tooltip = m.comment
        })
        return marks
    }
    useEffect(() => {
        if (activePano && activeDrawing) {
            const markersPlugin = PSV.getPlugin(MarkersPlugin);
            PSV.once('ready', () => {


                setMarkers(checkForMarkerType(activePano.markers))
                console.log('reach  ready yayy', activePano.markers)
                markersPlugin.setMarkers(activePano.markers)
            })
            PSV.once('panorama-loaded', () => {
                setMarkers(checkForMarkerType(activePano.markers))
                console.log('reach  loaded yayy', activePano.markers)
                markersPlugin.setMarkers(activePano.markers)
            })


            markersPlugin.on('select-marker', onSelect)
            PSV.on('dblclick', onClick);

        }
    }, [activePano, activeDrawing])
    const onSelect = (e, marker) => {

        var p;
        activePano.markers.map(m => {
            if (m.id == marker.id) p = m
        })
        setActiveTicket(p)

    }

    // const onSelect = (e, marker) => {
    //     var p;
    //     markers.map(m => {
    //         if (m.id == marker.id) p = m
    //     })
    //     console.log(p)
    //     setEditPano(p)
    //     setOpenAnotModal(activePano)

    // }
    const onClick = (e, data) => {
        if (!editor) return false
        const markersPlugin = PSV.getPlugin(MarkersPlugin);
        var id = '#' + generateRandomNumber(10)
        var m = {
            longitude: data.longitude, latitude: data.latitude, width: markerWidth, height: markerHeight, id, image: imgUrl
            // html: `<div style='background: #000; width: 100%'>
            //     sadfaasdf
            // </div>`
        }

        setMarkers(currentState => {
            markersPlugin.setMarkers([...currentState, m])
            console.log(`${data.rightclick ? 'right ' : ''}clicked at longitude: ${data.longitude} latitude: ${data.latitude}`);
            setActivePanoPoint({ longitude: data.longitude, latitude: data.latitude, id })
            setTimeout(() => {
                setOpenAnotModal(activePano)
            }, 1000)
            return [...currentState, m]
        })

    }
    const enterFullScreen = () => {
        const markersPlugin = PSV.getPlugin(MarkersPlugin);
        PSV.enterFullscreen()
        markersPlugin.gotoMarker(activeTicket.id, 1500)
    }
    return (
        <>
            <AddAnnotation
                point={activePanoPoint} setOpen={setOpenAnotModal}
                setEdit={() => { }} edit={null}
                drawingId={activeDrawing?._id}
                open={openAnotModal} fetchProject={fetchProject} />
            {activeTicket && <Ticket
                project={project}
                drawingId={activeDrawing?._id}
                setTicket={setActiveTicket}
                activeTicket={activeTicket}
                setActiveTicket={setActiveTicket}
                user={user}
                pano={activePano}
                enterFullScreen={enterFullScreen}
            />}

            <div id="photosphere" ref={sphereElementRef} > </div>

        </>
    )
}

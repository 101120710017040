import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

import { BrowserRouter } from 'react-router-dom';
import App from './main';
import { Provider } from "react-redux";
import  allReducer  from "./store/reducer/index"
import { createStore , applyMiddleware} from "redux";
import jwt from "jsonwebtoken";
import { refreshProfile , refreshUserToken} from "./store/actions/profile"
import thunk from 'redux-thunk';
import 'semantic-ui-css/semantic.min.css';
import './styles/styles.scss';
import { setAuthorizationToken } from './config/axios';

var store = createStore(allReducer,  applyMiddleware(thunk));
    if (window.localStorage.token) {
    setAuthorizationToken(window.localStorage.token);
    var decodedToken = jwt.decode(window.localStorage.token);
    store.dispatch(refreshProfile(decodedToken))
    store.dispatch(refreshUserToken())
}
const root = createRoot(document.getElementById('root')); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter>
    <Provider store={store}>
        <App />
    </Provider >
</BrowserRouter>);
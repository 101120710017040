import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import { Button, Form, Grid, Divider, Icon } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import AxiosCall, { setAuthorizationToken } from "../../config/axios";
import { refreshProfile } from "../../store/actions/profile";
import google from "../../assets/google.png"
export default function Onboarding({history}) {
  const profile = useSelector((s) => s.auth.profile);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [resend, setResend] = useState(false);
  const [code, setCode] = useState("");
  const [step, setStep] = useState(1);


useEffect(() => {
  if(profile?.registrationStage == 'profileSetup'){
    history.push('/profileSetup')
  } else if(profile?.registrationStage == 'accountSetup'){
    history.push('/accountSetup')
  }
}, [])
  const sendVerificationCode = async () =>{
    try {
      setIsLoading(true)
      setError('')
     var res = await AxiosCall({
        method: 'post',
        path: 'auth/onboardEmail',
        data: {email}
      })
      setStep(2)
    } catch (e) {
        if (e.response) {
          const { error } = e.response.data;
          setError(error)
        } else {
          setError('Something went wrong. please try again')
        }
    }
    setIsLoading(false)
  }
  const verifyCode = async () =>{
    try {
      setIsLoading(true)
      setError('')
     var res = await AxiosCall({
        method: 'post',
        path: 'auth/verifyCode',
        data: {email,code}
      })
      var { token, userData } = res
      dispatch(refreshProfile({profile: userData, notifications: []}));
      localStorage.setItem('userData', JSON.stringify(userData));
      localStorage.setItem('token',  token);
      setAuthorizationToken(token)
      history.push('/profileSetup')
    } catch (e) {
        if (e.response) {
          const { error } = e.response.data;
          setError(error)
        } else {
          setError('Something went wrong. please try again')
        }
    }
    setIsLoading(false)
  }
  return (
    <div className="signin">
      <Grid columns="equal">
      <Grid.Column
          tablet="7"
          computer="7"
          className="no-padding "
          only="computer"
        >
          <div className="left-grid flex-justify"></div>
        </Grid.Column>
        <Grid.Column
          tablet="9"
          mobile="16"
          computer="9"
          className="no-padding"
        >
          <div className="right-grid ">
            {step == 1 && (
              <div className="x-container">
                <Form onSubmit={sendVerificationCode} warning>
                 <Link to="/"> <img src={process.env.PUBLIC_URL + "images/logo.png"} /></Link>
                  <h2 className="x-bold">Welcome to B4A.com</h2>
                  <div className="subtitle ">
                    Let’s get started with a few simple steps
                  </div>
                  <br/>
                  <Form.Group widths="equal"></Form.Group>
                  <Form.Field>
                    <Form.Input
                      fluid
                      id="form-subcomponent-shorthand-input-first-name"
                      placeholder="Email address"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      type="email"
                      size="massive"
                    />
                
                  </Form.Field>
                  {error ? (
                    <div className="ui warning message">
                      <small>{error}</small>
                    </div>
                  ) : null}
                  <Button disabled={!email}  loading={isLoading} fluid type="submit" color="green" size="huge">
                    SUBMIT
                  </Button>
                </Form>
                <Divider horizontal> Or signup in with </Divider>
                <Button
                  size="huge"
                  basic
                  color="black"
                   
                  fluid
                >
                  <img src={google} style={{width: '17px'}}/>
                  </Button>
                <center>
                  <br />I have an Account <Link to="/signin"> Login </Link>
                </center>
                <div className="bottom-text">
                  <div className="flex-justify">
                    <div>
                      <div>
                        <br /> <Link to="/signin"> Terms and Condition </Link>
                      </div>
                    </div>
                    <div>
                      <div>
                        <br /> <Link to="/signin"> Privacy policy </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step == 2 && (
              <div className="x-container">
                <Form onSubmit={verifyCode}   warning>
                  <img src={process.env.PUBLIC_URL + "images/logo.png"} />
                  <h2 className="x-bold">Verify your email</h2>
                  <div className="subtitle">
                  Enter the verification code that was sent to
                  </div>
                  <div className="x-medium">{email}</div>

                  <br/>
                  <Form.Group widths="equal"></Form.Group>
                  <Form.Field>
                    <Form.Input
                      fluid
                      id="form-subcomponent-shorthand-input-first-name"
                      placeholder="Enter verification code"
                      onChange={(e) => setCode(e.target.value)}
                      required
                      size="massive"
                    />
                  </Form.Field>
                  {error ? (
                    <div className="ui warning message">
                      <small>{error}</small>
                    </div>
                  ) : null}
                  <Button loading={isLoading} fluid type="submit" color="green" size="huge">
                    SUBMIT
                  </Button>
                </Form>
            
               {!resend && <center>
                  <br />Didn't get code? <Link to="#" onClick={()=>{
                    setResend(true);
                    sendVerificationCode()
                  }}> Resend </Link>
                </center>}
             
              </div>
            )}
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}

import React, { useState, useEffect } from 'react'
import { Button, Form, Header, Icon, Image, Modal, Select } from 'semantic-ui-react'
import Mediaupload from '../uploader'
import { Link } from "react-router-dom";
import AxiosCall from '../../config/axios';
import { Dropdown } from 'semantic-ui-react'

function UploadFolder({ open, setOpen , fetchProject, folders , edit, setEdit}) {
    var [loading, setLoading] = useState(false)
    var [title, setTitle] = useState("")
    
    useEffect(() => {
       if(edit){
        setTitle(edit.title)
       }
    }, [edit])
    const upload = async () => {
        setLoading(true)
        try {
           var res = await AxiosCall({
                path: "projects/uploadFolder",
                data: { projectId: open, title, edit},
                method: "POST"
            })
            setLoading(false)
            fetchProject()
            setOpen('')
            setTitle("")
            // setEdit(null)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }
  
    return (
        <Modal
            onClose={() => setOpen('')}
            open={open ? true: false}
            className="x-upload-receipt"
            size="tiny"
        >
            <Modal.Header>
            <div className="x-back" onClick={() => {
                                           
            }}>
                <Icon name="chevron left" />
                <span> Back</span>
            </div>
            </Modal.Header>
            <Modal.Content >
                <div className="x-content edit-doc">

                        <Form onSubmit={upload} warning>
                                                <Form.Field>
                                                    <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-name"
                                                        placeholder="Enter folder title "
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        required
                                                        size="massive"
                                                        icon="folder"
                                                        iconPosition='left'
                                                        value={title}
                                                    />
                                               
                                                    {/* <Select 
                                                        placeholder="Select folder"
                                                        options={arrange(folders)}
                                                        onChange={(e,s)=>console.log(s,  e.value)}
                                                    /> */}
                                                </Form.Field>
                                             <Form.Field>
            <br/>
                                        <Button type="submit" color="green" loading={loading} size="huge"  disabled={!title} >
                                            {edit ? "EDIT FOLDER" : "CREATE FOLDER "}
                                        </Button>
                                             </Form.Field>
                                                </Form>
                  <div>
                  </div>
                </div>

            </Modal.Content>
            {/* <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nope
        </Button>
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions> */}
        </Modal>
    )
}

export default UploadFolder

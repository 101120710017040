
import React, { useEffect, useState } from "react";
import Navbar from '../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Dropdown, Grid, GridColumn, Icon, Progress } from "semantic-ui-react";
import shield from "../../assets/shield.png"
import explore from "../../assets/explore.png"
import mortgage from "../../assets/mortgage.png"
import insurance from "../../assets/insurance.png"
import refer from "../../assets/refer.png"
import { getProjectRequest, getProjectReset } from "../../store/actions/project";
import Projectcard from "../../component/projects/drawingsCard";
import UploadDrawings from "../../component/pano/uploadDrawings";
import UploadFolder from "../../component/pano/uploadFolder";
import FoldersCard from "../../component/projects/foldersCard";
import Loader from "react-loader-spinner";
export default function Dashboard(props) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector((s) => s.project);
    const dispatch = useDispatch();
    const [open, setOpen] = useState('')
    const [category, setCategory] = useState('drawings')
    const [openFolder, setFolderOpen] = useState('')
    const [editFolder, setEditFolder] = useState(null)
    const [role, setRole] = useState('viewer')
    
    const { history, computedMatch } = props

    useEffect(() => {
        fetchProject()
        return ()=>dispatch(getProjectReset())
    }, [])

    useEffect(() => {
        if (project?.users?.length > 0) {
            project?.users?.map(u => {
                if (u.userId._id == profile.uid) {
                    setRole(u.role)
                }
            })
        }
        if (project?.creatorID?._id == profile?.uid) {
            setRole('owner')
        }
    }, [project.data])
    const fetchProject = () => {
        dispatch(getProjectRequest(computedMatch?.params?.slug))
    }
    return (
        !project.data?._id ?  <div className="x-page-loader">
        <Loader color="#37517E" />
    </div>  :
        <>
            <UploadDrawings setOpen={setOpen} open={open} fetchProject={fetchProject} />
            <UploadFolder setOpen={setFolderOpen} open={openFolder} fetchProject={fetchProject} folders={project.data.folders} edit={editFolder}/>
            <Navbar {...props} auth={profile} />
            <div className="x-dashboard">
                <div className="x-container">
                    <section>
                    <div className="x-pagination"><Link to="/">Home</Link> <Icon name="chevron right" /> <Link to="/projects" > My projects </Link>   <Icon name="chevron right" /> Edit</div>
                        <div className="x-flex">
                            
                        <div className="x-title">
                                {project.data.title}
                            </div>
                            {(role == 'editor' || role == 'owner') ?
                            <div>
                                <Button color="green" size="huge" onClick={() => setOpen(project.data._id)}>
                                   ADD DRAWINGS
                                </Button>
                                <Button color="green" size="huge" onClick={() => setFolderOpen(project.data._id)}>
                                   ADD FOLDER
                                </Button>
                            </div> :null}
                        </div>
                      
                    </section>
                     <div className="x-tabs">
                        <div className="x-head">
                            <ul>
                                <li onClick={()=>setCategory('drawings')} className={category == "drawings" ? "active" : null }>Drawings </li>
                                <li onClick={()=>setCategory('folders')} className={category == "folders" ? "active" : null }>Folders</li>
                            </ul>
                        </div>
                        <div className="x-body">
                        <div className="project-lists">
                         {category == 'drawings' ? 
                         <Projectcard history={history} drawings={project.data.drawings} project={project.data} fetchProject={fetchProject} /> : 
                         <FoldersCard
                         setOpen={setFolderOpen} open={openFolder} fetchProject={fetchProject} 
                         setEdit={(f)=>{
                             setEditFolder(f)
                             setFolderOpen(project.data._id)
                         }}
                         
                        />}
                        </div>
                        </div>

                    </div>
                       
                </div>
            </div>
        </>
    )
}


import React, { useState } from 'react'
import { Button, Grid, Input, Popup } from 'semantic-ui-react'
import AxiosCall from '../../config/axios'

const DeleteBtn = ({children, onConfirm , loading}) => 
{
  
  const [password,setPassword] = useState('')
  const [error,setError] = useState('')
  const [confirmLoading, setConfirmLoading] = useState(null)
  const [open, setOpen] = useState(false)
  const checkPass = async (e) => {
    e?.preventDefault()
    setConfirmLoading(true)
    setError("")
  try {
    var passCheck =  await AxiosCall({
      path: "auth/confirmPassword",
      data: { password},
      method: "POST"
  })
  
    setConfirmLoading(false)
    setOpen(false)
    onConfirm()
  } catch (error) {
    setConfirmLoading(false)
    setError(error?.response?.data?.error)
  }
  }

return (
  <>
  <Popup wide  trigger={children} position="top right"  on="click" open={open} onClose={()=>setOpen(false)} onOpen={()=>setOpen(true)}>
     <p>Enter your password to confirm delete </p>
     <form onSubmit={checkPass}>
     <Input placeholder="password" onChange={(e)=>setPassword(e.target.value)}  fluid size="mini" />
      {error && <small style={{padding : '.5em'}}>{error}</small>}
      <div><br />
      {/* <Button size="mini" color="grey" onClick={()=>setOpen(false)} content="Cancel" /> */}
      <Button size="mini" color='black' loading={confirmLoading} content='Continue' onClick={checkPass} /> 
      </div>
      </form>
  </Popup>
  </>
)
}

export default DeleteBtn

import * as types from '../../constant';
import AxiosCall, { setAuthorizationToken } from '../../../config/axios';
export const setTourGuide = (payload) => {
  return { type: types.SET_TOUR_GUIDE , payload};
};
const getProjectsStart = () => {
  return { type: types.GET_PROJECTS_START };
};
const getProjectsSuccess = payload => {
  return { type: types.GET_PROJECTS_SUCCESS, payload };
};

export const getProjectsFail = payload => {
  return { type: types.GET_PROJECTS_FAIL, payload };
};

export const getProjectsCleanUp = () => {
  return { type: types.GET_PROJECTS_CLEANUP };
};

export const getProjectsRequest = userType => {
  return async dispatch => {
    dispatch(getProjectsStart());
    try {
      const callObj = {
        method: 'GET',
        path: userType == "admin" ? 'projects/fetchAdminProjects' : 'projects/fetch'  ,
      };
      const data = await AxiosCall(callObj);
      dispatch(getProjectsSuccess(data.projects));
    } catch (e) {
        let errorResponse;
        if (e.response) {
          const { error } = e.response.data;
          errorResponse = error;
        } else {
          errorResponse = 'Something went wrong. please try again';
        }
      dispatch(getProjectsFail(errorResponse));
    }
  };
};


import React, { useEffect, useState } from "react";
import Navbar from '../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Divider, Grid, GridColumn, Icon, Table } from "semantic-ui-react"; 
import { getProjectRequest, getProjectReset } from "../../store/actions/project";
import Loader from "react-loader-spinner";
import UploadDrawings from "../../component/pano/uploadDrawings";
import UploadFolder from "../../component/pano/uploadFolder";
import Drawings from "../../component/project/drawings";
import Folders from "../../component/project/folders";

export default function Manage(props) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector((s) => s.project.data);
    const [tab, setTab] = useState("drawings")
    const [open, setOpenDrawingModal] = useState('')
    const [edit, setEditDrawing] = useState(null)
    const [openFolder, setFolderOpen] = useState(null)
    const [editFolder, setFolderEditOpen] = useState(null)
    const [role, setRole] = useState('viewer')

    const dispatch = useDispatch();
    const { history, location, computedMatch } = props
    const { state } = location
    useEffect(() => {
        fetchProject()
        return ()=>dispatch(getProjectReset())
    }, [])

    useEffect(() => {
        if (project?.users?.length > 0) {
            project?.users?.map(u => {
                if (u.userId._id == profile.uid) {
                    setRole(u.role)
                }
            })
        }
        if (project?.creatorID?._id == profile?.uid) {
            setRole('owner')
        }
    }, [project])

    const fetchProject = () => {
        dispatch(getProjectRequest(computedMatch?.params?.slug))
    }
 
    return (
        !project?._id ? <div className="x-page-loader">
            <Loader color="#37517E" />
        </div> :
            <>
                <UploadDrawings setOpen={setOpenDrawingModal} setEdit={setEditDrawing} open={open} edit={edit} fetchProject={fetchProject} />
                <UploadFolder setOpen={setFolderOpen} open={openFolder} fetchProject={fetchProject} folders={project.folders} edit={editFolder} />
                <Navbar {...props} auth={profile} />
                <div className="x-project">
                    <div className="x-container">
                        <section>
                            <div className="x-pagination-box">
                            <div className="x-pagination"><Link to="/">Home</Link> <Icon name="chevron right" /> <Link to="/projects" > My projects </Link> <Icon name="chevron right" />  <Link to={`/projects/${project?.slug}`} > {project?.title} </Link> <Icon name="chevron right" />  Manage</div>
                            </div>
                            <div className="x-flex">
                                <div className="x-title">
                                    <span className="x-medium">{project?.title}</span>
                                    
                                </div>
                                <div>
                                    {(role == 'editor' || role == 'owner') ?
                                        <>
                                            <Button color="green" size="huge" onClick={() => setOpenDrawingModal(project._id)}>
                                                ADD DRAWINGS
                                </Button>
                                            <Button color="green" size="huge" onClick={() => setFolderOpen(project._id)}>
                                                ADD FOLDER
                                </Button>
                                        </> : null}
                                    
                                    <Button color="black" size="huge" onClick={() => history.push({ pathname: `${project.slug}/theater` })}>
                                        <Icon name="angle double up" /> THEATER MODE
                                </Button>
                                </div>
                            </div>
                        </section>
                       
                            <div className="x-third">
                                <div className="x-head">
                                    <ul>
                                        <li className={tab == 'drawings' ? 'active' : null} onClick={() => setTab("drawings")}>Drawings </li>
                                        <li className={tab == 'folders' ? 'active' : null} onClick={() => setTab("folders")}>Folders </li>
                                    </ul>
                                </div>
                                <div className="x-body">
                                   
                                    {tab == 'drawings' &&  <Drawings  fetchProject={fetchProject} project={project}  setOpen={setOpenDrawingModal} setEdit={setEditDrawing}  /> }
                                    {tab == 'folders' &&  <Folders   setOpen={setFolderOpen} setEdit={setFolderEditOpen} fetchProject={fetchProject} project={project}   /> }
                                </div>

                            </div>
                           
                    </div>
                </div>
            </>
    )
}

import React, { useState, useEffect } from 'react'
import moment from 'moment'
import $ from 'jquery'
import { Button, Divider, Icon } from 'semantic-ui-react';
import AxiosCall from '../../config/axios';
import { getProjectRequest } from '../../store/actions/project';
import { useDispatch } from 'react-redux';
import {cuttext} from '../../utils'
export default function Ticket({ user, project, activeTicket, setActiveTicket, enterFullScreen, pano , drawingId}) {
    const [loading, setLoading] = useState(false);
    const [convo, setConvo] = useState([]);
    const [text, setText] = useState('');
    const [checkResolved, setCheckResolved] = useState('');
    const [dropdown, setDropdown] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {

        if (activeTicket) {
            getChats()
            setDropdown(false)
        }
    }, [activeTicket])
    const getChats = async () => {
        setLoading(true);
        try {
            var callObj = {
                method: "GET",
                path: "tickets/getChat",
            };
            // if(filters.length || dateString || issue) {
            callObj.path += "?ticketNumber=" + activeTicket.id.split('#')[1]
            // }
            var res = await AxiosCall(callObj);
            setConvo(res.chats);
            if(res.chats){
                setCheckResolved(res.chats[0].resolved)
            }
            setLoading(false);
            console.log(res.chats)
            $(".card-content.chat-content").animate({ scrollTop: 20000000 })
        } catch (error) {
            setLoading(false);
        }
    };
    const postChat = async () => {
        setLoading(true);
        try {
            var callObj = {
                method: "POST",
                path: "tickets/createChat",
                data: {
                    markerId: activeTicket._id,
                    text,
                    projectId: project._id,
                    itemId:  pano?._id,
                    drawingId,
                    ticketNumber: activeTicket.id.split('#')[1]
                }
            };
            var res = await AxiosCall(callObj);
            getChats()
            setText('')
        } catch (error) {
            setLoading(false);
        }
    };
    const resolved = async () => {
        try {
            var callObj = {
                method: "POST",
                path: "tickets/resolved",
                data: {
                    markerId: activeTicket._id,
                    projectId: project._id,
                    pano: pano._id,
                    resolved: true,
                    ticketNumber:  activeTicket.id.split('#')[1]
                }
            };
            var res = await AxiosCall(callObj);
            getChats()
            // setText('')
            setCheckResolved(true)
        } catch (error) {
            setLoading(false);
        }
    }
    return (
        <div className="ticketChat">
            <div id="chatApp">

                <div className="chatBox" id="chatBox">
                    <div className="card">

                        <header className={`card-header header-title ${checkResolved ? 'success' : null}`}>
                            {/*  @click="toggleChat()" */}
                            <div className="card-header-title">
                                {/* <i className="fa fa-circle is-online"></i> */}
                                {/* <img src="https://k0.okccdn.com/php/load_okc_image.php/images/32x0/971x939/0/10846088441021659030.webp?v=2" style={{"width": "30px;"}} />  */}
                                <div style={{marginTop: '.3em', marginBottom: '-.3em'}}>{cuttext(activeTicket.comment, 30)}   </div>
                                {checkResolved != true && <small className="resolved" onClick={resolved}>Mark as resolved</small>}
                                {/* Ticket {activeTicket?.id} */}
                            </div>
                            <div>
                               
                                <a className="card-header-icon" onClick={() => setDropdown(!dropdown)}>
                                    <span className="icon">
                                        <Icon name={dropdown ? "chevron up" : "chevron down"} />
                                    </span>
                                </a>
                                <a className="card-header-icon" onClick={() => setActiveTicket(null)}>
                                    <span className="icon">
                                        <Icon name="close" />
                                    </span>
                                </a>
                            </div>

                        </header>

                        {!dropdown && <div id="chatbox-area">
                            <div className="card-content chat-content">
                                <div className="content">
                               
                                    <Divider horizontal>
                                        <small>{moment(activeTicket?.dateCreated).format("ddd DD MMM, YYYY hh:mm a")}</small>
                                    </Divider>
                                    <div className="chat-message-group ">
                                        <div className="chat-thumb">
                                            <div className="image is-32x32">
                                                <img src="https://res.cloudinary.com/dn5kttwc8/image/upload/v1602561569/hold/user-img.png" style={{ width: '20px', height: '20px' }} />
                                            </div>
                                        </div>
                                        <div className="chat-messages">
                                            <div className="message">{activeTicket.comment}</div>
                                            <div className="from">  {moment(activeTicket.dateCreated).fromNow()}</div>
                                            <div className="subject" onClick={enterFullScreen}>
                                            <img src={pano.url} />
                                            <div className="preview">
                                                <span >build4africa.com{window.location.pathname}</span> 
                                                <div><span>{pano.title}</span></div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                    
                                    {convo?.map((c, key) => c.createdBy._id == user.uid ?
                                        <div className="chat-message-group writer-user" key={key}>
                                            <div className="chat-messages ">
                                                <div className="message">{c.text}</div>
                                                <div className="from">
                                                    {/* {c.createdBy.firstName || 'admin'}  */}
                                                    {moment(c.createdAt).format("DD/MM/YY hh:mm a")}</div>
                                            </div>
                                        </div>
                                        :
                                        <div className="chat-message-group" key={key}>
                                            <div className="chat-thumb">
                                                <div className="image is-32x32">
                                                    <img src="https://res.cloudinary.com/dn5kttwc8/image/upload/v1602561569/hold/user-img.png" style={{ width: '40px', height: '40px' }} />
                                                </div>
                                            </div>
                                            <div className="chat-messages">
                                                <div className="message">{c.text}</div>
                                                <div className="from">{c.createdBy.firstName || 'admin'} {moment(c.createdAt).fromNow()}</div>
                                            </div>
                                        </div>
                                    )}
                                    {activeTicket.resolved.status == true && <>
                                        <Divider horizontal>
                                            <small>ISSUE has been Resolved</small>
                                        </Divider>
                                        <br/>
                                        <br/>
                                    </>}
                                    {/* <div className="chat-message-group">
                                        <div className="chat-thumb">
                                            <figure className="image is-32x32">
                                                <img src="https://k0.okccdn.com/php/load_okc_image.php/images/32x0/971x939/0/10846088441021659030.webp?v=2" />
                                            </figure>
                                        </div>
                                        <div className="chat-messages">
                                            <div className="message">Olá meu nome é Marinho</div>
                                            <div className="message">Caro marinho</div>
                                            <div className="from">Hoje 04:55</div>
                                        </div>
                                    </div> */}
                                    {/* <div className="chat-message-group">
                                        <div className="typing">Typing</div>
                                        <div className="spinner">
                                            <div className="bounce1"></div>
                                            <div className="bounce2"></div>
                                            <div className="bounce3"></div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {checkResolved != true &&
                            <footer className="card-footer" id="chatBox-textbox">
                                <div style={{ "width": "60%" }}>
                                    <textarea value={text} id="chatTextarea" className="chat-textarea" placeholder="Enter a text" onChange={(e) => setText(e.target.value)}></textarea>
                                    {/* v-on:focus="expandTextArea()" v-on:blur="dexpandTetArea()" */}
                                </div>
                                <div className="has-text-centered" style={{ "width": "40%", textAlign: 'center' }}>

                                    <Button fluid color="black" onClick={() => text && postChat()}>SEND</Button></div>
                            </footer>}
                        </div>}
                    </div>
                </div>

            </div>


        </div>
    )
}

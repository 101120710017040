import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import "photo-sphere-viewer/dist/photo-sphere-viewer"
import Loader from 'react-loader-spinner'
import Privateroute from './container/privateroute';
import Signin from './pages/onboarding/index';
import Onboarding from './pages/onboarding/Onboarding';
import AccountSetup from './pages/onboarding/AccountSetup';
import ProfileSetup from './pages/onboarding/ProfileSetup';
import SignupCompleted from './pages/onboarding/SignupCompleted';
import Dashboard from './pages/dashboard';
import NewProject from './pages/newproject/newproject';
import Projects from './pages/projects';
import Project from './pages/project';
import Meeting from './pages/meeting';
import Invoice from './pages/invoice';
import Payment from './pages/payment';
import Explore from './pages/explore';
import Template from './pages/template';
import InvoiceSheet from './component/projects/invoice';
import Pano from './pages/pano';
import Apps from './pages/admin/admin';
import Admin from './pages/admin/index';
import Roles from './pages/admin/roles';
import Home from './pages/home';
import Manage from './pages/project/manage';
import PasswordReset from './pages/onboarding/PasswordReset';
import ChangePassword from './pages/onboarding/ChangePassword';
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }
    componentWillMount() {
     
        this.setState({ loading: false })

    }

    render() {
        return (
            <div>
                <Switch>
                    <Route exact path="/signin" component={Signin} />
                    <Route exact path="/signup" component={Onboarding} />
                    <Route exact path="/profileSetup" component={ProfileSetup} />
                    <Route exact path="/accountSetup" component={AccountSetup} />
                    <Route exact path="/password-reset" component={PasswordReset} />
                    <Route exact path="/signupCompleted" component={SignupCompleted} />
                    <Route exact path="/resetpassword/:token" component={ChangePassword} />
                    
                    {/* <Privateroute exact path="/explore" component={Explore} /> */}
                    {/* <Privateroute exact path="/explore/:slug" component={Template} /> */}
                    <Privateroute exact path="/projects" component={Dashboard} />
                    {/* <Privateroute exact path="/projects" component={Apps} /> */}
                    <Privateroute exact path="/projects/:slug" component={Project} />
                    <Privateroute exact path="/projects/:slug/manage" component={Manage} />
                    <Privateroute exact path="/projects/:slug/collaborators" component={Roles} />
                    <Privateroute exact path="/projects/:slug/theater" component={Pano} />
                    <Privateroute exact path="/new-project" component={NewProject} />
                    {/* <Privateroute exact path="/meeting" component={Meeting} /> */}
                    <Privateroute exact path="/edit" component={NewProject} />
                    {/* <Privateroute exact path="/invoice" component={Invoice} />
                    <Route exact path="/invoice/:slug" component={InvoiceSheet} /> */}
                    <Privateroute exact path="/apps" component={Apps} />
                    <Privateroute exact path="/apps/:slug" component={Admin} />
                    <Privateroute exact path="/apps/:slug/preview" component={Pano} />
                    {/* <Privateroute exact path="/apps/:slug/roles" component={Roles} /> */}
                    <Privateroute exact path="/pay" component={Payment} />
                    
                    <Route path="*" component={Home} />
                </Switch>
                {this.state.loading && <div className="animate-loader"> <Loader type="Ball-Triangle" color="white" height={80} width={80} /></div>}

            </div>
        );
    }
}

export default App;
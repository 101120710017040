import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button,  } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import sucImg from '../../assets/success.png'

export default function SignupCompleted({history}) {
  const profile = useSelector((s) => s.auth);
  const dispatch = useDispatch();

  return (
    !profile ? null :
    <div className="reg-success">
        <div className="x-card ">
            <center>
            <img src={sucImg} />
            </center>
            <h2 className="x-bold">You're all set</h2>
            <p className="x-medium">
            {profile.profile.email}
            </p>
            <p>
            You have successfully created your account! <br/>
            You can sign in to your account now
            </p>

            <center>
            <Button fluid type="submit" color="green" size="huge" onClick={()=>history.push('/login')}>
                Login
            </Button>
            </center>
        </div>
        <style>
            {`
                body{
                    background: url("${process.env.PUBLIC_URL}images/signupimg.png"); 
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-attachment: fixed
                }
            `}
        </style>
    </div>
  );
}

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Popup } from 'semantic-ui-react'
import { setTourGuide } from '../../store/actions/projects/index.js'
import { PopupText } from "../../utils/PopupText.js"
const PopupBtn = ({ title, content, children, keyholder, classNames }) => {
  const [open, setOpen] = useState("")
  const tour = useSelector((s) => s.projects.tour);
  const dispatch = useDispatch()
  console.log(tour)
  useEffect(() => {
    var active = window.localStorage.getItem("tour")
    switch (active) {
      case 'dashboard':
        dispatch(setTourGuide('dashboard'))
        window.localStorage.setItem('tour', 'dashboard')
        break;
      case 'profile':
        dispatch(setTourGuide('profile'))
        window.localStorage.setItem('tour', 'profile')
        break;
      case 'notification':
        dispatch(setTourGuide('notification'))
        window.localStorage.setItem('tour', 'notification')
        break;
      case 'createProject':
        dispatch(setTourGuide('createProject'))
        window.localStorage.setItem('tour', 'createProject')
        break;
      default:
        dispatch(setTourGuide('dashboard'))
        window.localStorage.setItem('tour', 'dashboard')
        break;
    }
  }, [])
  const next = () => {
    var active = window.localStorage.getItem("tour");
    var arr = []
    Object.entries(PopupText).forEach(a => arr.push(a[1].key))
    var index = arr.findIndex((i) => i == active);
    console.log(index, arr.length)
    if (index < arr.length) {
      window.localStorage.setItem("tour", arr[index + 1])
      dispatch(setTourGuide(arr[index + 1]))
    } else {
      window.localStorage.setItem("completed")
      dispatch(setTourGuide('completed'))
    }

  }
  return (tour == keyholder && <div className={`x-tourguide`}>
    <div className={`x-tour ${classNames}`}>
      <h2>{title} </h2>
      <p>{content} </p>
      <Button compact color="black" onClick={next}>Continue</Button>
    </div>
  </div>
  )
}

export default PopupBtn
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Table } from "semantic-ui-react";
import logo from "../../assets/logo.png"
import moment from 'moment'
export default function PaymentHistory({ projects, history, state , invoices}) {
    const profile = useSelector((s) => s.auth.profile);
    const dispatch = useDispatch();
    const [open, setOpen] = useState('')
    const calculateTotal = (invoice) => {
        var total = 0
        invoice?.products?.map(p => {
            total += parseInt(p.price)
        })
        return total
    }
    return (
        <div className="x-projectUpdates">
            <Table fixed striped >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Request Date</Table.HeaderCell>
                        {/* <Table.HeaderCell>Time</Table.HeaderCell> */}
                        <Table.HeaderCell>Due Date</Table.HeaderCell>
                        <Table.HeaderCell>Invoice Number</Table.HeaderCell>
                        <Table.HeaderCell>Amount</Table.HeaderCell>
                        <Table.HeaderCell>Payment Status</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {invoices && invoices.map(invoice => (
                        <Table.Row >
                            
                            <Table.Cell>{moment(invoice.dateCreated).format("DD-MM-YYYY")}</Table.Cell>
                            <Table.Cell>{moment(invoice.dueDate).format("DD-MM-YYYY")}</Table.Cell>
                            <Table.Cell>{invoice.invoiceNumber}</Table.Cell>
                            <Table.Cell>{calculateTotal(invoice)} {invoice.currency}</Table.Cell>
                            <Table.Cell>{invoice.paid ? <Button basic color="green"> Successful </Button>:<>
                            <Button basic color="yellow" disabled> Pending </Button>
                            <Button basic color="blue" onClick={()=>history.push({pathname:"/pay",state: { ...state, invoice}})}> Pay now </Button>
                            </>}</Table.Cell>
                            <Table.Cell>{invoice.note}</Table.Cell>
                            <Table.Cell><Link to={`/invoice/${invoice.invoiceNumber}`}>View</Link></Table.Cell>
                            {/* <Table.Cell> {project.description}</Table.Cell>
                            <Table.Cell> {project.status == "awaitingPayment" ? <Button basic color="yellow">Awaiting payment</Button> : project.status == "onGoing" ? <Button basic color="green">Ongoing</Button> : project.status == "confirmingPayment" ? <Button color="blue" basic>Confirm payment</Button> : <Button basic color="yellow"> Awaiting Meeting </Button>}</Table.Cell>
                            <Table.Cell> 
                            {project.status == "confirmingPayment" && project?.paymentReceipt?.length &&
                           <center>
                               <a target="_blank" href={project?.paymentReceipt[0]?.url} >
                                <img src={project?.paymentReceipt[0]?.url} width="30px" /> 
                           </a>
                           </center> }
                            </Table.Cell>
                            <Table.Cell>
                                {project.status == "awaitingPayment" ? <Button basic onClick={() => history.push({ pathname: '/pay', state: project })}>Make payment</Button> : project.status == "onGoing" ? <Button basic>Take a tour</Button> : project.status == "confirmingPayment" ? 
                                project.paymentReceipt ? 
                                null:
                                <Button basic onClick={()=>setOpen(project._id)}>Upload  receipt</Button> 
                                : <Button basic onClick={() => history.push({ pathname: "/meeting", state: project })}>See Details</Button>}

                            </Table.Cell> */}
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

import React, { useState, useEffect } from 'react'
import { Button, Form, Header, Icon, Image, Modal } from 'semantic-ui-react'
import Mediaupload from '../uploader'
import { Link } from "react-router-dom";
import AxiosCall from '../../config/axios';
import axios from 'axios'

function UploadDrawings({ open, setOpen, setEdit, fetchProject, edit }) {
    var [url, setUrl] = useState('')
    var [pdfUrl, setPdfUrl] = useState('')
    var [loading, setLoading] = useState(false)
    var [title, setTitle] = useState("")
    var [page, setPage] = useState("")

    useEffect(() => {
        if (edit) {
            setUrl(edit.url)
            setPdfUrl(edit.pdfUrl)
            setTitle(edit.title)
            setPage(edit.page) 
        }
    }, [edit])

    const upload = async () => {
        if (!url) return false
        try {
            setLoading(true)
            var res = await AxiosCall({
                path: "projects/uploadDrawing",
                data: { url, projectId: open, title, edit, page , pdfUrl},
                method: "POST"
            })
            fetchProject()
            setOpen('')
            setLoading(false)
            setEdit && setEdit(null)
        } catch (error) {
            setLoading(false)
        }
    } 
    return (
        <Modal
            onClose={() => setOpen('')}
            open={open ? true : false}
            className="x-upload-receipt"
            size="tiny"
        >
            {/* <Modal.Header>
            <div className="x-back" onClick={() => {
                                           
            }}>
                <Icon name="chevron left" />
                <span> Back</span>
            </div>
            </Modal.Header> */}
            <Modal.Content >
                <div className="x-back x-cursor" onClick={() => {
                    setOpen('')
                    setLoading(false)
                }}>
                    <Icon name="chevron left" />
                    <span> Back</span>
                </div>
                <div className="x-content edit-doc">

                     <Mediaupload cb={(arr)=>setUrl(arr[0])} mediaType=".pdf" header="Upload drawing file" multiple={false} />
                    {(url || pdfUrl) && <div className="x-url">
                        <a href="#" onClick={() => window.open(url || pdfUrl, "__blank")}> {url ? url?.split("/")[4] : pdfUrl?.split("/")[4] }</a>

                    </div>}
                    <Form onSubmit={upload} warning>
                        <Form.Field>
                            <Form.Input
                                fluid
                                id="form-subcomponent-shorthand-input-first-name"
                                placeholder="Enter a title "
                                onChange={(e) => setTitle(e.target.value)}
                                required
                                size="massive"
                                icon="pencil"
                                iconPosition='left'
                                value={title}
                            />

                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                fluid
                                id="form-subcomponent-shorthand-input-first-name"
                                placeholder="Page number to upload"
                                onChange={(e) => setPage(e.target.value)}
                                required
                                type="number"
                                size="massive"
                                icon="sticky note outline"
                                iconPosition='left'
                                value={page}
                            />

                        </Form.Field>
                        <Form.Field>
                            <br />
                            <Button type="submit" color="green" loading={loading} size="huge" disabled={!url || !page}  >
                                {edit ? " EDIT DOCUMENT " : " UPLOAD DOCUMENT "}
                            </Button>

                        </Form.Field>
                    </Form>
                    <div>
                    </div>
                </div>

            </Modal.Content>
            {/* <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nope
        </Button>
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions> */}
        </Modal>
    )
}

export default UploadDrawings

import React, { useState, useEffect } from 'react'
import { Button, Grid, Table, Accordion, Icon, Dropdown } from "semantic-ui-react";
import Carousel from './carousel';
import moment from 'moment'
import ImageModal from './ImageModal';
import UploadView from '../pano/uploadView';

export default function ProjectImages({ project, fetchProject, computedMatch, 
    history }) {
    const [activeDrawing, setActiveDrawing] = useState(null);
    const [activeFolder, setActiveFolder] = useState(null);
    const [activeItem, setActiveItem] = useState(null);
    const [activeIndex, setActiveIndex] = useState([0,1]);
    const [items, setItems] = useState([]);
    const [markers, setMarkers] = useState([])
    const [modalOpen, setModalOpen] = React.useState(false)

    const { folders, drawings } = project

    const countFolderItems = (folder) => {
        var total = 0
        activeDrawing?.images?.map(img => {
            if (img.folderId == folder._id && img.archive == false) {
                total += 1
            }
        })
        return ` (${total}) `
    }

    const countDrawingItems = (draw) => {
        var total =  draw?.images?.filter(img =>  img.archive == false) 
        return total ? total.length : 0
    }
    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        if (activeIndex.includes(index)) {
            setActiveIndex(activeIndex.filter(i => i != index))
        } else {
            setActiveIndex([...activeIndex, index])
        }
        // const newIndex = activeIndex === index ? -1 : index

        // setActiveIndex(newIndex)
    }
    const selectNext = () => {
        var imgList = []
        activeDrawing?.images?.map(img => {
            if (img.folderId == activeFolder._id && img.archive == false) {
                 imgList.push(img)
            }
        })
        var key = imgList.findIndex(i => i._id == activeItem._id)
        if(key >= 0 && key+1 < imgList.length){
            setActiveItem(imgList[key+1])
        }
    }
    const selectPrev = () => {
        var imgList = []
        activeDrawing?.images?.map(img => {
            if (img.folderId == activeFolder._id && img.archive == false) {
                 imgList.push(img)
            }
        })
        var key = imgList.findIndex(i => i._id == activeItem._id)
        if(key > 0 && key+1 <= imgList.length){
            setActiveItem(imgList[key-1])
        }
    }
    useEffect(() => {
        let params = new URLSearchParams(computedMatch?.params?.slug);
        var filetype = params.get('f')?.toString()
        if(filetype && project) urlChange()
        else if (project) {
            var draw, folder, lists = [];
            drawings?.map(d => {
                if(activeDrawing?._id == d._id){
                    draw = d
                } 
            })
            if(!draw) {  drawings.map(d=> {draw = d })   }
           folders.map(f => {
            if(activeFolder?._id == f._id){
                folder =  f
            } 
           })
           if(!folder && folders.length > 0){  folders.map(f=> {folder = f })   }
            if(folder){
                draw?.images?.map(img => {
                    if (img?.folderId == folder?._id && img?.archive == false) {
                        lists.push(img)
                    }
                })
                setItems(lists)
            }

            setActiveDrawing(draw)
            setActiveFolder(folder)
            
        }
    }, [project])


    useEffect(() => {
        if (activeDrawing) {
            var items = activeDrawing?.images?.filter(i=> (i.archive == false && i.folderId == activeFolder?._id))
            setItems(items)
        }
    }, [activeDrawing])
    useEffect(() => {
        var list = []
        if (activeFolder) {
            activeDrawing?.images?.map(img => {
                if (img.folderId == activeFolder._id && img.archive == false) {
                    list.push(img)
                }
            })
        }
        setItems(list)
    }, [activeFolder])

    const urlChange = () =>{
        let params = new URLSearchParams(computedMatch?.params?.slug);
        var filetype = params.get('f')?.toString()
        var dID = params.get('d')?.toString()
        var pID = params.get('p')?.toString()
        var pano
        if (filetype == 'pano' && project._id) {
            var draw, folder, lists = [];
             draw = drawings.filter(d=> d._id == dID )
             pano = draw[0]?.pano.filter(p=> p._id == pID )
           folders.map(f => {
            if(pano[0]?.folderId == f._id){
                folder =  f
            } 
           })
            draw[0]?.pano?.map(p => {
                if (p?.folderId == folder?._id && p.archive == false) {
                    lists.push(p)
                }
            })
            setActiveDrawing(draw[0])
            setActiveFolder(folder)
            setItems(lists)
            setActiveItem(pano[0])
        }
    }
 
    const closeItemModal = () =>{
        setActiveItem(null)
    }
    return (
        <div className="x-projectimages">
            {activeFolder && <UploadView
                point={{ x: 1, y: 1 }} setOpen={setModalOpen}
                projectId={project?._id}
                active="image"
                activeFolder={activeFolder}
                open={modalOpen} edit={null} folders={folders} fetchProject={fetchProject} />}
            <ImageModal setActiveItem={setActiveItem} 
            selectNext={selectNext}
            selectPrev={selectPrev}
            activeItem={activeItem} 
            project={project} 
            activeDrawing={activeDrawing}
            fetchProject={fetchProject}/>
            <Grid stackable >
                <Grid.Column
                    mobile="16"
                    tablet="4"
                    computer="4"
                    className="no-padding"
                >

                    <div className="x-left">
                        <Accordion>
                            <Accordion.Title
                                active={activeIndex.includes(0)}
                                index={0}
                                onClick={handleClick}
                            >
                                <h4><Icon name='dropdown' /> 2d Drawings</h4>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex.includes(0)}>

                                <div className="text">
                                    {/* {project.description} */}
                                    <Dropdown
                                        placeholder='Select a Drawing'
                                        fluid
                                        selection
                                        value={activeDrawing?._id}
                                        text={activeDrawing?.title ? activeDrawing?.title + ` (${countDrawingItems(activeDrawing)})` : "Select a Drawing"}
                                    >
                                        <Dropdown.Menu>
                                            {drawings?.map((f, k) => f.archive ? null :
                                                <Dropdown.Item text={f.title +  ` (${countDrawingItems(f)})`} key={k} onClick={() => setActiveDrawing(f)} />
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Accordion.Content>
                            <Accordion.Title
                                active={activeIndex.includes(1)}
                                index={1}
                                onClick={handleClick}
                            >
                                <h4> <Icon name='dropdown' /> Folders</h4>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex.includes(1)}>
                                <div>
                                    <Dropdown
                                        placeholder='Select a Folder'
                                        fluid
                                        selection
                                        value={activeFolder?._id}
                                        text={activeFolder?.title ? activeFolder?.title + countFolderItems(activeFolder) : "Select a Folder"}
                                    >
                                        <Dropdown.Menu>
                                            {folders?.map((f, k) => f.archive ? null :
                                                <Dropdown.Item text={f.title + countFolderItems(f)} key={k} onClick={() => setActiveFolder(f)} />
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Accordion.Content>

                            {/* <Accordion.Title
                                active={activeIndex.includes(2)}
                                index={2}
                                onClick={handleClick}
                            >
                                <h4><Icon name='dropdown' />   {videos ? "Videos" : "Images"}</h4>
                            </Accordion.Title>
                            <Accordion.Content active={activeIndex.includes(2)}>
                                <div>

                                    <Dropdown
                                        placeholder='Select a view'
                                        fluid
                                        selection
                                        value={activeItem?._id}
                                        text={activeItem?.title ? moment(activeItem.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a") : "Select a view"}
                                    >
                                        <Dropdown.Menu>
                                            {items?.map((f, k) =>  (f.folderId != activeFolder?._id ) && activeFolder ? null :
                                                <Dropdown.Item text={moment(f.dateCreated).format("ddd, MMM DD, YYYY hh:mm:a")} key={k} onClick={() => setActiveItem(f)} />
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Accordion.Content> */}
                        </Accordion>
                    </div>
                </Grid.Column>
                <Grid.Column
                    mobile="16"
                    tablet="12"
                    computer="12"
                    className="no-padding"
                >
                    <div className="x-rights">
                        {/* <Carousel images={items} videos={videos}/> */}
                        <Grid>
                            {activeDrawing?._id && <Grid.Column mobile="8"
                                tablet="4"
                                computer="4">
                                <div className='x-boxcard x-add' onClick={()=>setModalOpen(activeDrawing?._id)} >
                                    <div className='img-box '>
                                        {/* <div className='x-shadow'></div> */}
                                        <Icon name="plus" />
                                    </div>
                                    {/* <div className='x-text'>UPLOAD NEW ITEM </div> */}
                                </div>
                            </Grid.Column>}

                            {items?.map((item, key) => ( item.archive ? null :
                                <Grid.Column mobile="8"
                                    tablet="4"
                                    computer="4">
                                    <div className='x-boxcard' onClick={() => setActiveItem(item)}>
                                       
                                        <div className='img-box'>
                                            <div className='x-shadow'></div>
                                            <img src={item.url} />
                                        </div>
                                        <div className='x-text'>{moment(item.dateCreated).format("h:mm a MMM YY")}  <span>•</span> {item?.createdBy?.firstName} {item?.createdBy?.lastName}  </div>
                                    </div>
                                </Grid.Column>
                            ))}

                        </Grid>
                        <div>
                        </div>
                    </div>

                </Grid.Column>
            </Grid>
        </div>
    )
}

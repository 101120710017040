import * as types from '../../constant';
import AxiosCall, { setAuthorizationToken } from '../../../config/axios';

const getInvoiceStart = () => {
  return { type: types.GET_INVOICE_START };
};
const getInvoiceSuccess = payload => {
  return { type: types.GET_INVOICE_SUCCESS, payload };
};

export const getInvoiceFail = payload => {
  return { type: types.GET_INVOICE_FAIL, payload };
};

export const getInvoiceCleanUp = () => {
  return { type: types.GET_INVOICE_CLEANUP };
};

export const getInvoiceRequest = id => {
  return async dispatch => {
    dispatch(getInvoiceStart());
    try {
      const callObj = {
        method: 'GET',
        path: 'projects/fetchInvoice?projectID='+id,
      };
      const data = await AxiosCall(callObj);
      dispatch(getInvoiceSuccess(data.invoice));
    } catch (e) {
        let errorResponse;
        if (e.response) {
          const { error } = e.response.data;
          errorResponse = error;
        } else {
          errorResponse = 'Something went wrong. please try again';
        }
      dispatch(getInvoiceFail(errorResponse));
    }
  };
};



export const payInvoiceRequest = obj => {
  return async dispatch => {
    // dispatch(getInvoiceStart());
    try {
      const callObj = {
        method: 'POST',
        data: obj,
        path: 'projects/payInvoice',
      };
      const data = await AxiosCall(callObj);
      window.location.assign("/projects")
      // dispatch(getInvoiceSuccess(data.invoice));
    } catch (e) {
        let errorResponse;
        if (e.response) {
          const { error } = e.response.data;
          errorResponse = error;
        } else {
          errorResponse = 'Something went wrong. please try again';
        }
      dispatch(getInvoiceFail(errorResponse));
    }
  };
};

import moment from 'moment'
import React, {useEffect, useState} from 'react'
import { Button, Feed, Grid, Header, Icon, Image, Loader, Modal } from 'semantic-ui-react'
import AxiosCall from '../../config/axios'
import DeleteBtn from './DeleteBtn'
import Panorama from './panorama'

function PanoModal({project, activeDrawing, setActiveItem, activeItem, fetchProject}) {
 
  const [deleteLoading, setDeleteLoading] = useState(null)
 
  const deleteItem = async () => {
      try {
          setDeleteLoading(true)
         var res =  await AxiosCall({
              path: "projects/deletePano",
              data: {  drawingId: activeDrawing?._id,  panoId: activeItem?._id },
              method: "POST"
          })
          setActiveItem(null)
          setDeleteLoading(false)
          fetchProject()
      } catch (error) {
          setDeleteLoading(false)
         console.log(error)
      }
  }
  return (
    <>
    
    <Modal
      onClose={() => setActiveItem(false)}
      // onOpen={() => setActiveItem(true)}
      open={activeItem}
      className="x-projectViewModal"
      size="fullscreen"
    //   trigger={<Button>Show Modal</Button>}
    >
      {/* <Modal.Header>Select a Photo</Modal.Header> */}
      {/* <Modal.Content > */}
        <Grid>
        <Grid.Column mobile={"16"} computer="12" tablet="12">
        <div className='x-left-grid'>
        <Panorama activeDrawing={activeDrawing} activeItem={activeItem} project={project} history={history} fetchProject={fetchProject} />
        </div>
        {/* <Image size="massive" src={activeItem?.url} wrapped /> */}
        </Grid.Column>
        <Grid.Column mobile={"4"} computer="4" tablet="4">
              <div className='x-right-grid'>
            <p><h2>{project?.title}  <DeleteBtn onConfirm={deleteItem}>
               {deleteLoading ? <Loader active={true}/> : <span className=" xcursor"> <Icon name="trash alternate outline" size="small" />  </span>}
                </DeleteBtn> </h2> </p>
            <Feed>
        <Feed.Event>
          <Feed.Label image={project?.creatorID?.photoURL} />
          <Feed.Content>
            <Feed.Date content={moment(activeItem?.dateCreated).format("lll")}/>
            <Feed.Summary>
              Uploaded by <a>{activeItem?.createdBy?.firstName}  </a> 
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
        </Feed>
          {/* <p>
            We've found the following gravatar image associated with your e-mail
            address.
          </p>
          <p>Is it okay to use this photo?</p> */}
        </div>
            </Grid.Column>
        </Grid>  
    </Modal>
    </>
  )
}

export default PanoModal
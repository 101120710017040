import React, { useEffect, useState } from "react";
import Navbar from '../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Divider, Form, Grid, GridColumn, Icon, Progress, Radio, Select } from "semantic-ui-react";
import shield from "../../assets/shield.png"
import explore from "../../assets/explore.png"
import mortgage from "../../assets/mortgage.png"
import insurance from "../../assets/insurance.png"
import refer from "../../assets/refer.png"
import pin from "../../assets/pin.png"
import pdf from "../../assets/pdf.png"
import Mediaupload from "../../component/uploader";
import { newProjectCleanUp , newProjectRequest} from "../../store/actions/newproject";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';

export default function NewProject(props) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector(s => s.projectCreation);
    const [step, setStep] = useState(1)
    const [meetingId, setMeetingId] = useState("")
    const [edit, setEdit] = useState(false)
    const [template, setTemplate] = useState(false)
    const [projectId, setProjectId] = useState("")
    const [location, setLocation] = useState("")
    const [title, setTitle] = useState("")
    const [drawingTitle, setDrawingTitle] = useState("")
    const [drawingPage, setDrawingPage] = useState("")
    const [worth, setProjectWorth] = useState("")
    const [description, setDescription] = useState("")
    const [landOwner, setLandOwner] = useState(false)
    const [landSize, setLandSize] = useState("")
    const [buildingType, setBuildingType] = useState("")
    const [buildingCategory, setBuildingCategory] = useState("residential")
    const [archDrawings, setArchDrawings] = useState(true)
    const [govIdDoc, setGovIdDoc] = useState('')
    const [landOwnershipDoc, setLandOwnershipDoc] = useState('')
    const [arcDesignDoc, setArchDesignDoc] = useState('')
    const [otherDoc, setOtherDoc] = useState('')
    const [activeDoc, setActiveDoc] = useState('arcDesignDoc')
    const [date, setDate] = useState(new Date());
    const [scheduleOption, setScheduleOption] = useState('date');

    const dispatch = useDispatch();
 
    useEffect(() => {
        if(project.isSuccessful){
            dispatch(newProjectCleanUp())
            // setStep(7)
            props.history.push("/projects")
        }
    }, [project])

    const check = (begin, end) => {
        var val
        if (step >= begin && step < end) {
            val = 'active'
        } else if (step < begin) {
            val = 'inactive'
        } else if (step > begin) {
            val = 'completed'
        }
        return val
    }
    const checkDoc = (doc) => {
        var val = 'inactive'

        if (doc == 'govIdDoc' && govIdDoc != '') {
            val = 'completed'
        } else if (doc == 'landOwnershipDoc' && landOwnershipDoc != '') {
            val = 'completed'
        } else if (doc == 'arcDesignDoc' && arcDesignDoc != '') {
            val = 'completed'
        } else if (doc == 'otherDoc' && otherDoc != '') {
            val = 'completed'
        } else if (doc == activeDoc) {
            val = 'active'
        }
        return val
    }

    const upload = (url) => {
        switch (activeDoc) {
            case 'govIdDoc':
                setGovIdDoc(url[0])
                if (landOwner) {
                    setActiveDoc('landOwnershipDoc')
                } else if (archDrawings) {
                    setActiveDoc('arcDesignDoc')
                } else setActiveDoc('otherDoc')
                break;
            case 'landOwnershipDoc':
                setLandOwnershipDoc(url[0])
                setActiveDoc('arcDesignDoc')
                break;
            case 'arcDesignDoc':
                setArchDesignDoc(url[0])
                setActiveDoc('otherDoc')
                break;
            case 'otherDoc':
                setOtherDoc(url[0])
                setActiveDoc('')
                break;
            default:
                break;
        }
    }
    const checkForDisabled = () =>   !arcDesignDoc || !drawingPage || !drawingTitle ? true : false

    const save = () => {
        dispatch(newProjectRequest({
            location,
            title,
            worth,
            description,
            landOwner,
            landSize,
            buildingCategory,
            buildingType,
            arcDesignDoc,
            drawingPage,
            drawingTitle
        }))
    }
    return (
        <>
            <Navbar {...props} auth={profile} />
            <div className="x-newproject">
                <div className="x-container">
                    <section>
                        <div className="x-pagination">Home <Icon name="chevron right" /> My projects  <Icon name="chevron right" /> New projects</div>
                        <div className="x-flex">
                            <div className="x-title">
                                New Project
                            </div>
                            <div className="x-progress">
                                <div className={`x-step ${check(0, 4)}`}>
                                    {check(0, 4) == 'active' ? <Icon name="circle " /> :
                                        check(0, 4) == 'inactive' ? <Icon name=" circle outline" /> : <Icon name="check circle" />}
                                    <span>Project Information</span>
                                </div> 
                                <div className={`x-step ${check(4, 5)}`}>
                                    {check(4, 5) == 'active' ? <Icon name="circle " /> :
                                        check(4, 5) == 'inactive' ? <Icon name=" circle outline" /> : <Icon name="check circle" />}
                                    <span>Documents</span>
                                </div>
                                {/* <div className={`x-step ${check(5, 6)}`}>
                                    {check(5, 6) == 'active' ? <Icon name="circle " /> :
                                        check(5, 6) == 'inactive' ? <Icon name=" circle outline" /> : <Icon name="check circle" />}
                                    <span> Confirm details</span>
                                </div>
                                <div className={`x-step ${check(6, 7)}`}>
                                    {check(6, 7) == 'active' ? <Icon name="circle " /> :
                                        check(6, 7) == 'inactive' ? <Icon name=" circle outline" /> : <Icon name="check circle" />}
                                    <span> Payment</span>
                                </div> */}
                            </div>
                        </div>
                        <p className="x-project"> Complete the form</p>
                    </section>
                    <section className="x-card">
                        <Grid columns="equal" stretched>
                            <Grid.Column
                                mobile="16"
                                tablet="6"
                                computer="6"
                                style={{ paddingLeft: 0, paddingTop: 0, paddingButton: 0 }}
                            >
                                <div className="left-box">

                                </div>
                            </Grid.Column>
                            <Grid.Column
                                mobile="16"
                                tablet="8"
                                computer="8"
                            >
                                <div className="right-box">
                                    {step == 1 &&
                                        <div>
                                            {/* <h2>Where will you like to build?</h2>
                                            <p className="subtitle">Select building project location and title</p> */}
                                             <h2>Please complete the form</h2>
                                            <p className="subtitle">Fill  in the following details</p>
                                            <Form onSubmit={() => setStep(4)} warning>
                                                {/* <Form.Field>
                                                    <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-name"
                                                        placeholder="Enter address "
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        required
                                                        size="massive"
                                                        icon='map marker alternate'
                                                        iconPosition='left'
                                                        value={location}
                                                    />
                                                    
                                                </Form.Field>
                                                {location && <div className="option" >
                                                    <img src={pin} />
                                                    <p>{location}</p>
                                                </div>} */}
                                                <Form.Field>
                                                <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-title"
                                                        placeholder="Enter Title of the project "
                                                        onChange={(e) => setTitle(e.target.value)}
                                                        required
                                                        size="massive"
                                                        // icon='map marker alternate'
                                                        // iconPosition='left'
                                                        value={title}
                                                    />
                                                </Form.Field>
                                                {/* <Form.Field>
                                                <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-desc"
                                                        placeholder="Enter Decription of the project "
                                                        onChange={(e) => setDescription(e.target.value)}
                                                        required
                                                        size="massive"
                                                        // icon='map marker alternate'
                                                        // iconPosition='left'
                                                        value={description}
                                                    />
                                                </Form.Field> */}
                                                 <Form.Field>
                                                <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-desc"
                                                        placeholder="Approximate project worth (NGN)"
                                                        onChange={(e) => setProjectWorth(e.target.value)}
                                                        required
                                                        size="massive"
                                                        type="number"
                                                        // icon='map marker alternate'
                                                        // iconPosition='left'
                                                        value={worth}
                                                    />
                                                </Form.Field>
                                                <br />

                                                {/* {location && */}
                                                    <>
                                                        <p className="x-medium">Type of project</p>
                                                        <Form.Group >
                                                            <Form.Field required>
                                                                <Radio
                                                                    label='Residential'
                                                                    name='radioGroup'
                                                                    value={'residential'}
                                                                    checked={buildingCategory == 'residential'}
                                                                    onChange={(e, value) => setBuildingCategory(value.value)}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Radio
                                                                    label='Commercial'
                                                                    name='radioGroup'
                                                                    value={'commercial'}
                                                                    checked={buildingCategory == 'commercial'}
                                                                    onChange={(e, value) => setBuildingCategory(value.value)}
                                                                />
                                                            </Form.Field>
                                                        </Form.Group>
                                                    </>
                                                    {/* } */}
                                                    <br />
                                                <Button fluid type="submit" color="green" size="huge">
                                                    NEXT
                                    </Button>
                                            </Form>
                                        </div>
                                    }
                                    {step == 2 &&
                                        <div>
                                            <div className="x-back" onClick={() => {
                                                setStep(1)
                                            }}>
                                                <Icon name="chevron left" />
                                                <span> Back</span>
                                            </div>
                                            <h2>Whats the land size</h2>
                                            <p className="subtitle">The size of the plot of land for this building projects</p>
                                            <Form onSubmit={() => setStep(3)} warning>
                                                <Form.Field>
                                                    <Form.Input
                                                        fluid
                                                        id="form-subcomponent-shorthand-input-first-name"
                                                        placeholder="Enter land size"
                                                        onChange={(e) => setLandSize(e.target.value)}
                                                        required
                                                        value={landSize}
                                                        size="massive"
                                                    />
                                                </Form.Field>
                                                <small >I am not sure <Link to="" className="x-medium">Get measurement</Link></small>
                                                <br />
                                                <br />
                                                <Button fluid type="submit" color="green" size="huge">
                                                    NEXT
                                                </Button>
                                            </Form>
                                        </div>
                                    }
                                    {step == 3 &&
                                        <div className="x-section">
                                            <div className="x-back" onClick={() => {
                                                if (buildingType) {
                                                    setBuildingCategory("")
                                                    setBuildingType("")
                                                } else setStep(2)
                                            }}>
                                                <Icon name="chevron left" />
                                                <span> Back</span>
                                            </div>

                                            <h2>What type of property are you interested in building</h2>
                                            <p className="subtitle">Select property type</p>
                                            {buildingType ?
                                                <>
                                                    <Grid stackable columns="3" stretched>
                                                        <Grid.Row>
                                                            <Grid.Column width={16}>
                                                                <p className="x-head">{buildingCategory}</p>
                                                            </Grid.Column>
                                                            <Grid.Column
                                                                className="no-padding"
                                                            >
                                                                <div className="x-box">
                                                                    <div>{buildingType}</div>
                                                                </div>
                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                    <Form onSubmit={() => setStep(4)}>
                                                        {/* <p className="x-medium">Do you have architechtural drawings <br /> for this project?</p>
                                                        <Form.Group >
                                                            <Form.Field required>
                                                                <Radio
                                                                    label='Yes'
                                                                    name='radioGroup'
                                                                    value={true}
                                                                    checked={archDrawings == true}
                                                                    onChange={(e, value) => setArchDrawings(value.value)}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <Radio
                                                                    label='No'
                                                                    name='radioGroup'
                                                                    value={false}
                                                                    checked={archDrawings == false}
                                                                    onChange={(e, value) => setArchDrawings(value.value)}
                                                                />
                                                            </Form.Field>
                                                        </Form.Group> */}
                                                        <br />
                                                        <Button fluid type="submit" color="green" size="huge">
                                                            NEXT
                                                        </Button>
                                                    </Form>
                                                </>
                                                : <Grid stackable columns="3" stretched>

                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <p className="x-head">Homes</p>
                                                        </Grid.Column>
                                                        <Grid.Column
                                                            className="no-padding"
                                                        >
                                                            <div className="x-box" onClick={() => {
                                                                setBuildingType('bungalow')
                                                                setBuildingCategory('homes')
                                                            }
                                                            }>
                                                                <div>Bungalow</div>
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column
                                                            className="no-padding"
                                                        >
                                                            <div className="x-box" onClick={() => {
                                                                setBuildingType('duplex')
                                                                setBuildingCategory('homes')
                                                            }
                                                            }>
                                                                <div>Duplex</div>
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column
                                                            className="no-padding"
                                                        >
                                                            <div className="x-box" onClick={() => {
                                                                setBuildingType('apartment')
                                                                setBuildingCategory('homes')
                                                            }
                                                            }>
                                                                <div>Apartment</div>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <p className="x-head">Business</p>
                                                        </Grid.Column>
                                                        <Grid.Column
                                                            className="no-padding"
                                                        >
                                                            <div className="x-box" onClick={() => {
                                                                setBuildingType('offices')
                                                                setBuildingCategory('business')
                                                            }
                                                            }>
                                                                <div>Offices</div>
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column
                                                            className="no-padding"
                                                        >
                                                            <div className="x-box" onClick={() => {
                                                                setBuildingType('shopping complex')
                                                                setBuildingCategory('business')
                                                            }
                                                            }>
                                                                <div>Shopping Complex</div>
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column
                                                            className="no-padding"
                                                        >
                                                            <div className="x-box" onClick={() => {
                                                                setBuildingType('hotel / guest house')
                                                                setBuildingCategory('business')
                                                            }
                                                            }>
                                                                <div>Hotel / Guest House</div>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row>
                                                        <Grid.Column width={16}>
                                                            <p className="x-head">Worship centers</p>
                                                        </Grid.Column>
                                                        <Grid.Column
                                                            className="no-padding"
                                                        >
                                                            <div className="x-box" onClick={() => {
                                                                setBuildingType('church')
                                                                setBuildingCategory('worship centers')
                                                            }
                                                            }>
                                                                <div>Church</div>
                                                            </div>
                                                        </Grid.Column>
                                                        <Grid.Column
                                                            className="no-padding"
                                                        >
                                                            <div className="x-box" onClick={() => {
                                                                setBuildingType('mosque')
                                                                setBuildingCategory('worship centers')
                                                            }
                                                            }>
                                                                <div>Mosque</div>
                                                            </div>
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>}

                                        </div>
                                    }
                                    {step == 4 &&
                                        <div>
                                            <div className="x-back" onClick={() => {
                                                setStep(1)
                                            }}>
                                                <Icon name="chevron left" />
                                                <span> Back</span>
                                            </div>
                                            <h2>Upload your files</h2>
                                            <p className="subtitle">Please upload the listed documents</p>
                                            <div className="x-content edit-doc">

                     <Mediaupload cb={(arr)=>setArchDesignDoc(arr[0])} mediaType=".pdf" header="Upload drawing file" multiple={false} />
                    <br />
                            {arcDesignDoc && <div className="x-file">
                                {/* <img src={pdf} /> */}
                                <span onClick={() => window.open(arcDesignDoc, "__blank")}> {arcDesignDoc?.split('/')[4]}</span>
                                <Icon name="trash alternate outline" onClick={() => {
                                    setArchDesignDoc("");
                                }
                                } />
                            </div>} 
                    <Form   warning>
                        <Form.Field>
                            <Form.Input
                                fluid
                                id="form-subcomponent-shorthand-input-first-name"
                                placeholder="Enter drawing title "
                                onChange={(e) => setDrawingTitle(e.target.value)}
                                required
                                size="massive"
                                // icon="pencil"
                                // iconPosition='left'
                                value={drawingTitle}
                            />

                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                fluid
                                id="form-subcomponent-shorthand-input-first-name"
                                placeholder="Page number to upload"
                                onChange={(e) => setDrawingPage(e.target.value)}
                                required
                                type="number"
                                size="massive"
                                // icon="sticky note outline"
                                // iconPosition='left'
                                value={drawingPage}
                            />

                        </Form.Field>
                        <Form.Field>
                            <br />
                            {/* <Button type="submit" color="green" loading={loading} size="huge" disabled={!url || !page}  >
                                {edit ? " EDIT DOCUMENT " : " UPLOAD DOCUMENT "}
                            </Button> */}

                        </Form.Field>
                    </Form>
                    <div>
                    </div>
                </div>
                                              
                                            <Button disabled={checkForDisabled()} onClick={()=>setStep(5)} fluid type="submit" color="green" size="huge">
                                                NEXT
                                           </Button>
                                        </div>
                                    }
                                    {step == 5 &&
                                        <div className="x-document">
                                            <div className="x-back" onClick={() => {
                                                setStep(4)
                                            }}>
                                                <Icon name="chevron left" />
                                                <span> Back</span>
                                            </div>
                                            <h2>Confirm your details</h2>
                                            <p className="subtitle">Select building project location</p>
                                            <Divider />
                                            <Form onSubmit={save} warning>
                                               {/* <div><p className="x-medium">Location</p></div>
                                                 <div className="option" style={{display: 'flex'}}>
                                                 <img src={pin} />
                                                    <p>{location}</p>
                                                </div>
                                                <Divider />
                                                <div><p className="x-medium">Land Size</p></div>
                                                 <div className="option" >
                                                    <p>{landSize}</p>
                                                </div>
                                                <Divider />
                                                <div><p className="x-medium">Property Type</p></div>
                                                 <div className="option" >
                                                    <small>{buildingCategory}</small> 
                                                    <p className="x-medium">{buildingType}</p>
                                                </div>
                                                <Divider /> */}
                                                <div><p className="x-medium">Documents</p></div>
                                               
                                                <div className="option" >
                                                {/* <div className={`x-step ${checkDoc('govIdDoc')}`} >
                                                    {checkDoc('govIdDoc') == 'active' ? <Icon name="circle " /> :
                                                        checkDoc('govIdDoc') == 'inactive' ? <Icon name=" circle outline" /> : <Icon name="check circle" />}
                                                    <span>Government Approved Id</span>
                                                </div>
                                                {govIdDoc && <div className="x-file" >
                                                    <img src={pdf} />
                                                    <span onClick={() => window.open(govIdDoc, "__blank")}> {govIdDoc.split("/")[4]}</span>
                                                </div>} */}
                                                {landOwner &&
                                                    <>
                                                        <div className={`x-step ${checkDoc('landOwnershipDoc')}`}  >
                                                            {checkDoc('landOwnershipDoc') == 'active' ? <Icon name="circle " /> :
                                                                checkDoc('landOwnershipDoc') == 'inactive' ? <Icon name=" circle outline" /> : <Icon name="check circle" />}
                                                            <span> Land Ownership Document</span>
                                                        </div>
                                                        {landOwnershipDoc && <div className="x-file" >
                                                            <img src={pdf} />
                                                            <span onClick={() => window.open(landOwnershipDoc, "__blank")}> {landOwnershipDoc.split("/")[4]}</span>
                                                        </div>}
                                                    </>}

                                                {archDrawings &&
                                                    <>
                                                        <div className={`x-step ${checkDoc('arcDesignDoc')}`}   >
                                                            {checkDoc('arcDesignDoc') == 'active' ? <Icon name="circle " /> :
                                                                checkDoc('arcDesignDoc') == 'inactive' ? <Icon name=" circle outline" /> : <Icon name="check circle" />}
                                                            <span> Architectural Drawings</span>
                                                        </div>
                                                        {arcDesignDoc && <div className="x-file">
                                                            <img src={pdf} />
                                                            <span onClick={() => window.open(arcDesignDoc, "__blank")}> {arcDesignDoc.split("/")[4]}</span>
                                                        </div>}
                                                    </>}
                                                 {otherDoc &&
                                                 <>
                                                <div className={`x-step ${checkDoc('otherDoc')}`}  >
                                                    {checkDoc('otherDoc') == 'active' ? <Icon name="circle " /> :
                                                        checkDoc('otherDoc') == 'inactive' ? <Icon name=" circle outline" /> : <Icon name="check circle" />}
                                                    <span> Others </span>
                                                </div>
                                                <div className="x-file" >
                                                    <img src={pdf} />
                                                    <span onClick={() => window.open(otherDoc, "__blank")}> {otherDoc.split("/")[4]}</span>
                                                   
                                                </div>
                                                </>}
                                                </div>
                                                {project.error ? (
                                                <div className="ui warning message">
                                                <small>{project.error}</small>
                                                </div>
                                            ) : null}
                                                <Button fluid type="submit" color="green" size="huge" loading={project.isLoading}>
                                                     CREATE PROJECT
                                                </Button>
                                            </Form>
                                        </div>
                                    }
                                    {step == 6 &&
                                    <>
                                    {scheduleOption == 'date' && <div className="payment">
                                     {!template && <div className="x-back" onClick={() => {
                                                setStep(5)
                                            }}>
                                                <Icon name="chevron left" />
                                                <span> Back</span>
                                            </div>}
                                            <h2>{edit && !template ? "Reschedule":"Schedule"} a call to get an estimate</h2>
                                            <p className="subtitle">Schedule a call to have a detailed understanding of your project demands, requirements and expectation
                                            </p>
                                            <Divider />
                                            <p>Select Date</p>
                                            <Calendar onChange={setDate} value={date} defaultValue={date} />
                                            <div className="date-box">{moment(date).format("ddd, MMM DD, YYYY")}</div>
                                            {project.error ? (
                                                <div className="ui warning message">
                                                <small>{project.error}</small>
                                                </div>
                                            ) : null}
                                            <Button onClick={()=>setScheduleOption('time')}  fluid type="submit" color="black" size="huge">
                                                SET TIME
                                            </Button>
                                    </div>}
                                    {scheduleOption == 'time' && <div className="payment">
                                         <div className="x-back" onClick={() => {
                                                setScheduleOption('date')
                                            }}>
                                                <Icon name="chevron left" />
                                                <span> Back</span>
                                            </div>
                                            <h2>{edit && !template ? "Reschedule":"Schedule"} a call to get an estimate</h2>
                                            <p className="subtitle">Schedule a call to have a detailed understanding of your project demands, requirements and expectation
                                            </p>
                                            <Divider />
                                            <p>Select Time</p>
                                            <TimePicker
                                                showSecond={false}
                                                defaultValue={ moment(date)}
                                                className="xxx"
                                                onChange={(d)=>setDate(new Date(d))}
                                                format='h:mm a'
                                                use12Hours
                                                inputReadOnly
                                            />,
                                            <div className="date-box">{moment(date).format("ddd, MMM DD, YYYY")} {scheduleOption == 'time' && moment(date).format("h:mm a")}</div>
                                            {project.error ? (
                                                <div className="ui warning message">
                                                <small>{project.error}</small>
                                                </div>
                                            ) : null}
                                          
                                            <Button onClick={()=>setScheduleOption('confirm')} fluid type="submit" color="black" size="huge">
                                                SET TIME
                                            </Button>
                                    </div>}
                                    {scheduleOption == 'confirm' && <div className="payment confirm">
                                         <div className="x-back" onClick={() => {
                                                setScheduleOption('time')
                                            }}>
                                                <Icon name="chevron left" />
                                                <span> Back</span>
                                            </div>
                                            <h2>{edit && !template ? "Reschedule":"Schedule"} a call to get an estimate</h2>
                                            <p className="subtitle">Schedule a call to have a detailed understanding of your project demands, requirements and expectation
                                            </p>
                                            <Divider />
                                            <div className="x-confirm-head">Select Time slot</div>
                                            <div className="x-medium ">{moment(date).format("ddd, MMM, DD, YYYY, h:mm a")}</div>
                                            <Divider />
                                            <div className="x-confirm-head"> Contact info </div>
                                            <div className="desc">
                                                <span>Name: </span> <span className="x-medium">{profile.firstName}  {profile.lastName}</span>
                                            </div>
                                            <div className="desc">
                                                <span>Email: </span> <span className="x-medium">{profile.email}   </span>
                                            </div>
                                            <div className="desc">
                                                <span>Phone: </span> <span className="x-medium">{profile.phone}   </span>
                                            </div>
                                            <Divider />

                                            <div className="disclaimer">By scheduling the call, you acknowledge that B4D may record calls for quality assurance and training purposes </div>
                                            {project.error ? (
                                                <div className="ui warning message">
                                                <small>{project.error}</small>
                                                </div>
                                            ) : null}
                                          
                                            <Button onClick={save} loading={project.isLoading} disabled={project.isLoading} fluid type="submit" color="black" size="huge">
                                               {edit && !template ? "RESCHEDULE CALL" : "SCHEDULE CALL"} 
                                            </Button>
                                    </div>}
                                    </>
                                    }
                                      {step == 7 && <div className="payment scheduled">
                                         <div className="x-back" onClick={() => {
                                               propos.history.push("/")
                                            }}>
                                                <Icon name="chevron left" />
                                                <span> Home</span>
                                            </div>
                                            <h2>Your meeting has been {edit ? "rescheduled":"scheduled"}</h2>
                                            <p className="subtitle">Thank you for scheduling a time for your call, please view your meeting detais below
                                            </p>
                                            <Divider />
                                            <div className="x-confirm-head"> <Icon name="calendar check outline"/> Date</div>
                                            <div className="desc">{moment(date).format("ddd, MMM, DD, YYYY ")}</div>
                                            <br/>
                                            <div className="x-confirm-head"> <Icon name="time"/> Time</div>
                                            <div className="desc ">{moment(date).format("h:mm a")}</div>
                                            <br/>
                                            <div className="x-confirm-head"> <Icon name="video camera"/>  Join zoom meeting</div>
                                            <div className="desc "><span>ID:</span> {project?.data?.meetingId}</div>
                                            <div className="desc "><span>Password:</span> {project?.data?.meetingPassword}</div>
                                            <br/>
                                            <br/>
                                       
                                          
                                            <Button onClick={()=>props.history.push("/projects")} loading={project.isLoading} disabled={project.isLoading} fluid type="submit" color="black" size="huge">
                                                OKAY
                                            </Button>
                                    </div>}
                                </div>
                            </Grid.Column>
                        </Grid>
                    </section>
                </div>
            </div>
        </>
    )
}

import * as types from '../../constant';
import AxiosCall, { setAuthorizationToken } from '../../../config/axios';

const newProjectStart = () => {
  return { type: types.NEW_PROJECT_START };
};
const newProjectSuccess = payload => {
  return { type: types.NEW_PROJECT_SUCCESS, payload };
};

export const newProjectFail = payload => {
  return { type: types.NEW_PROJECT_FAIL, payload };
};

export const newProjectCleanUp = () => {
  return { type: types.NEW_PROJECT_CLEANUP };
};

export const newProjectRequest = project => {
  return async dispatch => {
    dispatch(newProjectStart());
    try {
      const callObj = {
        method: 'POST',
        path: project.edit ? 'projects/edit': 'projects/create',
        data: project
      };
      const data = await AxiosCall(callObj);
      dispatch(newProjectSuccess(data.project));
    } catch (e) {
        let errorResponse;
        if (e.response) {
          const { error } = e.response.data;
          errorResponse = error;
        } else {
          errorResponse = 'Something went wrong. please try again';
        }
      dispatch(newProjectFail(errorResponse));
    }
  };
};


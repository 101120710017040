import React, { useEffect, useState } from "react";
import Navbar from '../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, GridColumn, Icon, Progress } from "semantic-ui-react";
import shield from "../../assets/shield.png"
import explore from "../../assets/explore.png"
import mortgage from "../../assets/mortgage.png"
import insurance from "../../assets/insurance.png"
import refer from "../../assets/refer.png"
import { getProjectsRequest } from "../../store/actions/projects";
import Projectcard from "../../component/projects/adminProjectCard";
import Loader from "react-loader-spinner";
import { getProjectReset } from "../../store/actions/project";
export default function Dashboard(props) {
    const profile = useSelector((s) => s.auth.profile);
    const projects = useSelector((s) => s.projects);
    const dispatch = useDispatch();
    const { history } = props

    useEffect(() => {
        dispatch(getProjectsRequest())
        return ()=>dispatch(getProjectReset())
    }, [])
    
    return (
        !profile || (projects.isLoading &&  projects.data.length == 0) ? <div className="x-page-loader">
        <Loader color="#37517E" />
    </div>  : 
        <>
            <Navbar {...props} auth={profile} />
            <div className="x-dashboard">
                <div className="x-container">
                    <section>
                    <div className="x-pagination"> <Link to="/" > Home </Link> <Icon name="chevron right" /> My projects </div>
                        <div className="x-flex">
                            <div className="x-title">
                                Projects 
                            </div>
                            <div>
                             
                            </div>
                        </div>
                      
                    </section>
                        <div className="project-lists">
                       <Projectcard history={history} projects={projects.data} fetchProjects={dispatch(getProjectsRequest())} />
                        </div>
                </div>
            </div>
        </>
    )
}


import * as types from '../../constant';


const initialState = {
   isLoading: false,
   error: null,
   isSuccessful: false,
   data: {}
 };

const getProjectReducer = (state = initialState, action) => {
 switch (action.type) {
   case types.GET_PROJECT_START:
     return { ...state, isLoading: true, error: null };
   case types.GET_PROJECT_SUCCESS:
     return { ...state,  isLoading: false, isSuccessful: true , data: action.payload};
   case types.GET_PROJECT_FAIL:
     return { ...state, error: action.payload, isLoading: false };
   case types.GET_PROJECT_CLEANUP:
     return { ...state, error: null, isLoading: false, isSuccessful: false };
     case types.GET_PROJECT_RESET:
      return { ...state, error: null, isLoading: false, isSuccessful: false , data: {}};
   default:
     return state;
 }
};

export default getProjectReducer;

import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Table } from "semantic-ui-react";
import eyes from "../../assets/eyes.png"
import chat from "../../assets/chat.png"
import moment from 'moment'
import AdminChat from "./adminChat";
import UploadDrawings from "../pano/uploadDrawings";
import EditProject from "./EditProject";
export default function Adminprojectcard({ projects, history, fetchProjects }) {
    const profile = useSelector((s) => s.auth.profile);
    const dispatch = useDispatch();
    const [open, setOpen] = useState('')
    const [project, setProject] = useState(null)
    const [deleteLoadingId, setDeleteLoadingId] = useState(null)

    const deleteProject = async (projectId) => {
        try {
            setDeleteLoadingId(projectId)
           var res =  await AxiosCall({
                path: "projects/deleteProject",
                data: { projectId, },
                method: "POST"
            })
            fetchProjects()
            setDeleteLoadingId(null)
        } catch (error) {
            setDeleteLoadingId(null)
           console.log(error)
        }
    }
    return (
        <div className="x-projectcard">
            <EditProject setOpen={setOpen} open={open} fetchProjects={fetchProjects}/>
            {/* <UploadDrawings setOpen={setOpen} open={open}  setEdit={()=>{}}/> */}
            {project && (
                <AdminChat
                    // getUnreadChats={getUnreadChats}
                    user={profile}
                    project={project}
                    setProject={setProject}
                />
            )}
            <Table fixed striped  >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        {/* <Table.HeaderCell>Time</Table.HeaderCell> */}
                        <Table.HeaderCell>Project name</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        {/* <Table.HeaderCell> </Table.HeaderCell> */}
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {projects && projects.map(project => (
                        <Table.Row >
                            <Table.Cell>{moment(project.createdAt).format("DD-MMM-YYYY")}</Table.Cell>
                            {/* <Table.Cell>{moment(project.createdAt).format("hh:mm a")}</Table.Cell> */}
                            <Table.Cell>{project.title}</Table.Cell>
                            <Table.Cell> {project.description}</Table.Cell>
                            {/*                            
                            <Table.Cell> 
                            <div>
                               <Link to=""> <Icon name="photo" /> 23 </Link>
                            </div>
                            <div>
                               <Link to=""> <Icon name="globe" /> 23 </Link>
                            </div>
                            </Table.Cell> */}
                            <Table.Cell>
                            <Button basic onClick={() => history.push("/projects/" + project.slug)}>Take a tour</Button>
                                <Button basic onClick={() => history.push("/apps/" + project.slug)}>Edit</Button>
                                {/* <Button basic onClick={()=>setProject(project)}>Chat</Button> */}
                                <Button basic onClick={() => history.push("/apps/" + project.slug + '/roles')}>Roles</Button>
                                {role == 'owner' ?
                                 <DeleteBtn onConfirm={()=>deleteProject(project?._id)}>
                                     <Button basic loading={deleteLoadingId == project?._id}><Icon name="trash alternate outline" /></Button>
                                 </DeleteBtn> : null}
                                <Button basic onClick={()=> { setOpen(project) }} > <Icon name="pencil" /> </Button>  

                                {/* <Button basic onClick={() => history.push("/apps/" + project.slug+'?id=drawings')}>Drawings</Button>
                                <Button basic onClick={() => history.push("/apps/" + project.slug+'?id=folders')}>Folders</Button> */}
                                {/* <Button basic onClick={()=>setOpen(project._id)}>Edit</Button>   */}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

/* tslint:disable */
import {
  useEffect,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import * as React from "react";
import { useSelector } from "react-redux";
import $ from "jquery";
import Pin from "../../assets/pin.png";
const ORIGIN = Object.freeze({ x: 0, y: 0 });

// adjust to device to avoid blur
const { devicePixelRatio: ratio = 1 } = window;
function diffPoints(p1, p2) {
  return { x: p1.x - p2.x, y: p1.y - p2.y };
}

function addPoints(p1, p2) {
  return { x: p1.x + p2.x, y: p1.y + p2.y };
}

function scalePoint(p1, scale) {
  return { x: p1.x / scale, y: p1.y / scale };
}

const ZOOM_SENSITIVITY = 500; // bigger for lower zoom per scroll

export default function Canvas(props) {
  const project = useSelector((s) => s.project);
  const drawings = project.data?.drawings;
  const canvasRef = useRef(null);
  const [context, setContext] = useState(null);
  const [drawing, setDrawing] = useState(null);
  const [scale, setScale] = useState(1);
  const [offset, setOffset] = useState(ORIGIN);
  const [mousePos, setMousePos] = useState(ORIGIN);
  const [viewportTopLeft, setViewportTopLeft] = useState(ORIGIN);
  const [newViewPort, setNewViewPort] = useState(ORIGIN);
  const isResetRef = useRef(false);
  const lastMousePosRef = useRef(ORIGIN);
  const lastOffsetRef = useRef(ORIGIN);
  const canvasImgRef = useRef(null);
  var [markersRefs, setMarkersRefs] = useState([]);
  const { setOpen, open, fileType, checkRole } = props;
  // update last offset
  useEffect(() => {
    setDrawing(props?.drawing);
  }, []);
  useEffect(() => {
    if (drawings) {
      drawings.map((d) => {
        if (d._id == drawing?._id) setDrawing(d);
      });
    }
  }, [drawings]);

  useEffect(() => {
    lastOffsetRef.current = offset;
  }, [offset]);

  // reset
  const reset = useCallback(
    (context) => {
      if (context && !isResetRef.current) {
        // adjust for device pixel density
        context.canvas.width = props.canvasWidth * ratio;
        context.canvas.height = props.canvasHeight * ratio;
        context.scale(ratio, ratio);
        setScale(1);

        // reset state and refs
        setContext(context);
        setOffset(ORIGIN);
        setMousePos(ORIGIN);
        setViewportTopLeft(ORIGIN);
        lastOffsetRef.current = ORIGIN;
        lastMousePosRef.current = ORIGIN;

        // this thing is so multiple resets in a row don't clear canvas
        isResetRef.current = true;
      }
    },
    [props.canvasWidth, props.canvasHeight]
  );
  // functions for panning
  const mouseMove = useCallback(
    (event) => {
      if (context) {
        const lastMousePos = lastMousePosRef.current;
        const currentMousePos = { x: event.pageX, y: event.pageY }; // use document so can pan off element
        lastMousePosRef.current = currentMousePos;

        const mouseDiff = diffPoints(currentMousePos, lastMousePos);
        setOffset((prevOffset) => addPoints(prevOffset, mouseDiff));
      }
    },
    [context]
  );

  const mouseUp = useCallback(() => {
    document.removeEventListener("mousemove", mouseMove);
    document.removeEventListener("mouseup", mouseUp);
  }, [mouseMove]);

  const startPan = useCallback(
    (event) => {
      document.addEventListener("mousemove", mouseMove);
      document.addEventListener("mouseup", mouseUp);
      lastMousePosRef.current = { x: event.pageX, y: event.pageY };
    },
    [mouseMove, mouseUp]
  );

  // setup canvas and set context
  useLayoutEffect(() => {
    if (canvasRef.current) {
      // get new drawing context
      const renderCtx = canvasRef.current.getContext("2d");

      if (renderCtx) {
        reset(renderCtx);
      }
    }
  }, [reset, props.canvasHeight, props.canvasWidth]);

  // pan when offset or scale changes
  useLayoutEffect(() => {
    if (context && lastOffsetRef.current) {
      const offsetDiff = scalePoint(
        diffPoints(offset, lastOffsetRef.current),
        scale
      );
      context.translate(offsetDiff.x, offsetDiff.y);
      setViewportTopLeft((prevVal) => diffPoints(prevVal, offsetDiff));
      isResetRef.current = false;
    }
  }, [context, offset, scale]);
  // console.log(viewportTopLeft,'diffpoints')

  // draw
  useLayoutEffect(() => {
    if (context && canvasImgRef) {
      const squareSize = 20;

      // clear canvas but maintain transform
      const storedTransform = context.getTransform();
      context.canvas.width = context.canvas.width;
      context.setTransform(storedTransform);
      // context.fillStyle = "#000";
      // context.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      context.drawImage(
        canvasImgRef.current,
        window.innerWidth/4,
        squareSize,
        props.canvasWidth / 2 - squareSize / 2,
        props.canvasHeight / 2 - squareSize / 2
      );
      //   console.log(markers)
      var m = [];
      switch (fileType) {
        case "image":
          drawing?.images?.map((p) => m.push({ ...p.point, title: p.title }));
          break;
        case "video":
          drawing?.videos?.map((p) => m.push({ ...p.point, title: p.title }));
          break;
        default:
          drawing?.pano?.map((p) => m.push({ ...p.point, title: p.title }));
          break;
      }
      // drawing?.images?.map((p) => m.push({ ...p.point, title: p.title }));
      // drawing?.videos?.map((p) => m.push({ ...p.point, title: p.title }));
      var storeMarkerRef = [];
      for (var i = 0; i < m.length; i++) {
        var tempMarker = new Marker(m[i]);
        // Draw marker
        // context.drawImage(
        //   tempMarker.Sprite,
        //   tempMarker.XPos,
        //   tempMarker.YPos,
        //   tempMarker.Width,
        //   tempMarker.Height
        // );
        storeMarkerRef.push(tempMarker);

        // Calculate postion text
        // var markerText = tempMarker.Title;

        // Draw a simple box so you can see the position
        // var textMeasurements = context.measureText(markerText);
        // context.fillStyle = "#666";
        // context.globalAlpha = 0.7;
        // context.fillRect(tempMarker.XPos - (textMeasurements.width / 2), tempMarker.YPos - 15, textMeasurements.width, 20);
        // context.globalAlpha = 1;

        // Draw position above
        // context.fillStyle = "#000";
        // context.fillText(markerText, tempMarker.XPos, tempMarker.YPos);
      }
      setMarkersRefs(storeMarkerRef);
      // context.fillRect(
      //   props.canvasWidth / 2 - squareSize / 2,
      //   props.canvasHeight / 2 - squareSize / 2,
      //   squareSize,
      //   squareSize
      // );
      // context.arc(viewportTopLeft.x, viewportTopLeft.y, 5, 0, 2 * Math.PI);
      // context.fillStyle = "red";
      // context.fill();
    }
  }, [
    props.canvasWidth,
    props.canvasHeight,
    context,
    scale,
    offset,
    drawing,
    fileType,
    viewportTopLeft,
  ]);

  // add event listener on canvas for mouse position
  useEffect(() => {
    const canvasElem = canvasRef.current;
    if (canvasElem === null) {
      return;
    }
    // canvasImgRef.current.src='https://wpmedia.roomsketcher.com/content/uploads/2022/01/06145940/What-is-a-floor-plan-with-dimensions.png'

    function handleUpdateMouse(event) {
      event.preventDefault();
      if (canvasRef.current) {
        const viewportMousePos = { x: event.clientX, y: event.clientY };
        const topLeftCanvasPos = {
          x: canvasRef.current.offsetLeft,
          y: canvasRef.current.offsetTop,
        };
        setMousePos(diffPoints(viewportMousePos, topLeftCanvasPos));
      }
    }
    canvasElem.addEventListener("mousemove", handleUpdateMouse);
    canvasElem.addEventListener("wheel", handleUpdateMouse);
    return () => {
      canvasElem.removeEventListener("mousemove", handleUpdateMouse);
      canvasElem.removeEventListener("wheel", handleUpdateMouse);
    };
  }, []);

  // add event listener on canvas for zoom
  useEffect(() => {
    const canvasElem = canvasRef.current;
    if (canvasElem === null) {
      return;
    }

    // this is tricky. Update the viewport's "origin" such that
    // the mouse doesn't move during scale - the 'zoom point' of the mouse
    // before and after zoom is relatively the same position on the viewport
    function handleWheel(event) {
      event.preventDefault();
      if (context) {
        const zoom = 1 - event.deltaY / ZOOM_SENSITIVITY;
        const viewportTopLeftDelta = {
          x: (mousePos.x / scale) * (1 - 1 / zoom),
          y: (mousePos.y / scale) * (1 - 1 / zoom),
        };
        const newViewportTopLeft = addPoints(
          viewportTopLeft,
          viewportTopLeftDelta
        );
        context.translate(viewportTopLeft.x, viewportTopLeft.y);
        context.scale(zoom, zoom);
        context.translate(-newViewportTopLeft.x, -newViewportTopLeft.y);
        setNewViewPort(newViewportTopLeft)
        setViewportTopLeft(newViewportTopLeft);
        setScale(scale * zoom);
        isResetRef.current = false;
      }
    }

    canvasElem.addEventListener("wheel", handleWheel);
    return () => canvasElem.removeEventListener("wheel", handleWheel);
  }, [context, mousePos.x, mousePos.y, viewportTopLeft, scale]);

  var Marker = function(m) {
    this.Sprite = new Image();
    this.Sprite.src = Pin;
    this.Width = 12;
    this.Height = 20;
    this.XPos = m.x;
    this.YPos = m.y;
    this.Title = m.title;
  };
  function onDblClick(event) {
    if(!checkRole()) return false

    if (open) return setOpen("");
    event.preventDefault();
    if (canvasRef.current) {
      const viewportMousePos = { x: event.clientX, y: event.clientY };
      const topLeftCanvasPos = {
        x: canvasRef.current.offsetLeft,
        y: canvasRef.current.offsetTop,
      }; 
    
      setOpen(project.data._id);
      var diff = diffPoints(viewportMousePos, topLeftCanvasPos)
      props.pointCallback({x: diff.x + viewportTopLeft.x, y: diff.y + viewportTopLeft.y
      });
    }
  }
  function onClick(event) {
    event.preventDefault();
    if (canvasRef.current) {
      const viewportMousePos = { x: event.clientX, y: event.clientY };
      const topLeftCanvasPos = {
        x: canvasRef.current.offsetLeft,
        y: canvasRef.current.offsetTop,
      };
     
      var xVal = event.pageX - topLeftCanvasPos.x,
        yVal = event.pageY - topLeftCanvasPos.y;
     
        
      markersRefs.forEach(function(ele) {

        if (
          (yVal + viewportTopLeft.y  - newViewPort.y)> (ele.YPos) &&
          (yVal  + viewportTopLeft.y - newViewPort.y)< (ele.YPos)+ ele.Height &&
          (xVal  + viewportTopLeft.x - newViewPort.x)> (ele.XPos) &&
          (xVal + viewportTopLeft.x - newViewPort.x )< (ele.XPos) + ele.Width
        ) {
          drawing?.pano?.map((p) => {
            if (
              p.point.x == ele.XPos  &&
              p.point.y  == ele.YPos  &&
              props.fileType == "360 image"
            ) {
              props?.setActivePano(p);
            }
          })
          drawing?.images?.map((p) => {
            if (
              p.point.x == ele.XPos &&
              p.point.y == ele.YPos &&
              props.fileType == "image"
            ) {
              props?.setActiveImagePano(p);
            }
          })
          drawing?.videos?.map((p) => {
            if (
              p.point.x == ele.XPos &&
              p.point.y == ele.YPos &&
              props.fileType == "video"
            ) {
              props?.setActiveVideoPano(p);
            }
          })
        }
      });
    }
  }
 
  return (
    <div>
      {/* <button onClick={() => context && reset(context)}>Reset</button>
      <pre>scale: {scale}</pre>
      <pre>offset: {JSON.stringify(offset)}</pre>
      <pre>viewportTopLeft: {JSON.stringify(viewportTopLeft)}</pre> */}
      <canvas
        onMouseDown={startPan}
        onDoubleClick={onDblClick}
        onClick={onClick}
        ref={canvasRef}
        width={props.canvasWidth * ratio}
        height={props.canvasHeight * ratio}
        style={{
          // border: "2px solid #000",
          width: `${props.canvasWidth}px`,
          height: `${props.canvasHeight}px`,
        }}
      ></canvas>
      <img
        src={
          drawing?.url ||
          "https://wpmedia.roomsketcher.com/content/uploads/2022/01/06145940/What-is-a-floor-plan-with-dimensions.png"
        }
        ref={canvasImgRef}
        style={{ display: "none" }}
      />
    </div>
  );
}

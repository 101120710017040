import React, { useState, useEffect } from 'react'
import { Button, Grid, Table, Accordion, Icon, Dropdown } from "semantic-ui-react";
import moment from 'moment'
import AxiosCall from '../../config/axios';
import DeleteBtn from './DeleteBtn';

export default function Drawings({ project, fetchProject, setOpen, setEdit }) {
    const [modalOpen, setModalOpen] =  useState(false)
    const [deleteLoading, setDeleteLoading] = useState(null)

    const { folders, drawings } = project
  
 
    const deleteDrawing = async (id) => {
        try {
            setDeleteLoading(id)
           var res =  await AxiosCall({
                path: "projects/deleteDrawing",
                data: {  drawingId:  id,  },
                method: "POST"
            })
            setDeleteLoading(false)
            fetchProject()
        } catch (error) {
            setDeleteLoading(false)
           console.log(error)
        }
    }
    return (
           <div className="x-projectUpdates">
            {/* {activeFolder && <UploadView
                point={{ x: 1, y: 1 }} setOpen={setModalOpen}
                projectId={project?._id}
                active="image"
                activeFolder={activeFolder}
                open={modalOpen} edit={null} folders={folders} fetchProject={fetchProject} />} */}
            <Table fixed striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell></Table.HeaderCell>
                        {/* <Table.HeaderCell>Time</Table.HeaderCell> */}
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.HeaderCell>Photos </Table.HeaderCell>
                        <Table.HeaderCell>Videos </Table.HeaderCell>
                        <Table.HeaderCell>360° photos </Table.HeaderCell>
                        <Table.HeaderCell>Last updated </Table.HeaderCell>
                        <Table.HeaderCell>Action </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {drawings?.map(drawing => ( drawing?.archive  == true ? null :
                        <Table.Row >
                            <Table.Cell><img key={drawing.url} src={drawing.url}  width="100%"/></Table.Cell>
                            <Table.Cell>{drawing.title}</Table.Cell>
                            <Table.Cell> {drawing.images?.length}</Table.Cell>
                            <Table.Cell>  {drawing.videos?.length} </Table.Cell>
                            <Table.Cell>  {drawing.pano?.length} </Table.Cell>
                            <Table.Cell>{moment(drawing.dateUpdated).format("DD-MMM-YYYY")}</Table.Cell>
                           
                            <Table.Cell>
                                <Button basic onClick={()=> { setEdit(drawing); setOpen(true) }} > <Icon name="pencil" /> </Button>  
                                 <DeleteBtn onConfirm={()=>deleteDrawing( drawing._id)}>
                                     <Button basic loading={deleteLoading == drawing._id}   > <Icon name="trash alternate outline" /> </Button>  
                                 </DeleteBtn>  
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

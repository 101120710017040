
import * as types from '../../constant';


 const initialState = {
    isLoading: false,
    error: null,
    isSuccessful: false,
    data: null
  };

const newProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NEW_PROJECT_START:
      return { ...state, isLoading: true, error: null };
    case types.NEW_PROJECT_SUCCESS:
      return { ...state,  isLoading: false, isSuccessful: true , data: action.payload};
    case types.NEW_PROJECT_FAIL:
      return { ...state, error: action.payload, isLoading: false };
    case types.NEW_PROJECT_CLEANUP:
      return { ...state, error: null, isLoading: false, isSuccessful: false };
    default:
      return state;
  }
};

export default newProjectReducer;


import React, { useEffect, useState } from "react";
import Navbar from '../../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, GridColumn, Icon, Progress } from "semantic-ui-react";
import shield from "../../../assets/shield.png"
import explore from "../../../assets/explore.png"
import mortgage from "../../../assets/mortgage.png"
import insurance from "../../../assets/insurance.png"
import refer from "../../../assets/refer.png"
import { getProjectRequest, getProjectReset } from "../../../store/actions/project";
import Projectcard from "../../../component/projects/drawingsCard";
import UploadDrawings from "../../../component/pano/uploadDrawings";
import UploadFolder from "../../../component/pano/uploadFolder";
import RolesCard from "../../../component/projects/adminRolesCard";
import AddUser from "../../../component/admin/addUser";
import Loader from "react-loader-spinner";
export default function Roles(props) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector((s) => s.project.data);
    const dispatch = useDispatch();
    const [open, setOpen] = useState('')
    const [openFolder, setFolderOpen] = useState('')
    const { history, computedMatch } = props
    const [role, setRole] = useState('viewer')

    useEffect(() => {
        fetchProject()
        return () => dispatch(getProjectReset())
    }, [])

    useEffect(() => {
            if (project?.users?.length > 0) {
                project?.users?.map(u => {
                    if (u.userId._id == profile.uid) {
                        setRole(u.role)
                    }
                })
            }
            if (project?.creatorID?._id == profile?.uid) {
                setRole('owner')
            }
    }, [project])
    const fetchProject = () => {
        dispatch(getProjectRequest(computedMatch?.params?.slug))
    }
    return (
        !project?._id ? <div className="x-page-loader">
            <Loader color="#37517E" />
        </div> :
            <>
                <AddUser setOpen={setOpen} open={open} fetchProject={fetchProject} edit={() => { }} />
                <Navbar {...props} auth={profile} />
                <div className="x-dashboard">
                    <div className="x-container">
                        <section>
                            <div className="x-pagination"><Link to="/">Home</Link> <Icon name="chevron right" /> <Link to="/projects" > My projects </Link>  <Icon name="chevron right" /> Roles</div>
                            <div className="x-flex">
                                <div className="x-title">
                                    {project.title}
                                </div>
                                {role == 'owner' ?
                                    <div>
                                        <Button color="green" size="huge" onClick={() => setOpen(project._id)}>
                                            ADD COLLABORATOR
                                        </Button>

                                    </div> : null}
                            </div>

                        </section>
                        <div className="project-lists">
                            <RolesCard history={history} drawings={project.drawings} project={project} fetchProject={fetchProject} role={role} />
                        </div>
                    </div>
                </div>
            </>
    )
}


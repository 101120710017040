import moment from 'moment'
import React, { useState } from 'react'
import { Button, Feed, Grid, Header, Icon, Image, Loader, Modal } from 'semantic-ui-react'
import AxiosCall from '../../config/axios'
import DeleteBtn from './DeleteBtn'
import Panorama from './panorama'

function VideoModal({project, activeDrawing, setActiveItem, activeItem, fetchProject, selectPrev, selectNext}) {

  const [deleteLoading, setDeleteLoading] = useState(null)
  const deleteItem = async () => {
      try {
          setDeleteLoading(true)
         var res =  await AxiosCall({
              path: "projects/deleteVideo",
              data: {  drawingId: activeDrawing?._id,  videoId: activeItem?._id },
              method: "POST"
          })
          setActiveItem(null)
          setDeleteLoading(false)
          fetchProject()
      } catch (error) {
          setDeleteLoading(false)
         console.log(error)
      }
  }
  return (
    <>
    
    <Modal
      onClose={() => setActiveItem(false)}
      // onOpen={() => setActiveItem(true)}
      open={activeItem}
      className="x-projectViewModal"
      size="fullscreen"
    //   trigger={<Button>Show Modal</Button>}
    >
       
        <Grid>
        <Grid.Column mobile={"16"} computer="12" tablet="12">
        <div className='x-left-grid'>
        <div className='x-btn-div'>
        <Button circular className='x-btn-prev' icon="chevron left"  size="massive" onClick={selectPrev}/>
        <Button circular  className='x-btn-next'  icon="chevron right"  size="massive" onClick={selectNext}/>
        </div>
        {/* <div className='x-btn-round'><Icon name="chevron left" /> </div> */}
        {/* <div className='x-btn-round'><Icon name="chevron right" /> </div> */}
        {/* <Panorama activeItem={activeItem} project={project} history={history} fetchProject={fetchProject} /> */}
        {/* <Image size="massive" src={activeItem?.url}  /> */}
        <video width="400" controls key={activeItem?._id}>
          <source src={activeItem?.url} type="video/mp4" />
          {/* <source src="mov_bbb.ogg" type="video/ogg"> */}
          Your browser does not support HTML video.
          </video>
        </div>
        </Grid.Column>
        <Grid.Column mobile={"4"} computer="4" tablet="4">
              <div className='x-right-grid'> 
            <p><h2>{project?.title}  <DeleteBtn onConfirm={deleteItem}>
               {deleteLoading ? <Loader active={true}/> : <span className=" xcursor"> <Icon name="trash alternate outline" size="small" />  </span>}
                </DeleteBtn></h2></p>
            <Feed>
        <Feed.Event>
          <Feed.Label image={project?.creatorID?.photoURL} />
          <Feed.Content>
            <Feed.Date content={moment(activeItem?.dateCreated).format("lll")}/>
            <Feed.Summary>
              Uploaded by <a>{activeItem?.createdBy?.firstName}  </a> 
            </Feed.Summary>
          </Feed.Content>
        </Feed.Event>
        </Feed>
          {/* <p>
            We've found the following gravatar image associated with your e-mail
            address.
          </p>
          <p>Is it okay to use this photo?</p> */}
        </div>
            </Grid.Column>
        </Grid>  
    </Modal>
    </>
  )
}

export default VideoModal
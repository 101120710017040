import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import { Button, Form, Grid, Divider, Icon, Checkbox, TransitionablePortal, Segment, Header } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import AxiosCall from "../../config/axios";
import { refreshProfile, updateProfileCleanup, updateProfileRequest } from "../../store/actions/profile";
import { loginRequest } from "../../store/actions/signin";
import google from "../../assets/google.png"
import moment from "moment";

export default function ChangePassword({history, match}) {
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [error, setError]= useState("")
  const [isLoading, setIsLoading] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(() => {
    var user = jwt.decode(match?.params?.token)
    setUser(user)
   if(moment().diff(user?.timeStamp, "hours") > 1200){
    history.push("/")
   }
  
  //  dispatch(refreshProfile(null))
    //  localStorage.removeItem('userData')
    //  localStorage.removeItem('token')
  }, [])
 
  
 const sendCode = async () =>{
  if(password != cpassword) return setError("Password does not match")
  try {
    setIsLoading(true)
    setError('')
    var token = jwt.sign({email: user?.email, password}, 'fluenttera__')
   var res = await AxiosCall({
      method: 'post',
      path: 'auth/resetPassword',
      data: {token}
    })
    setIsLoading(false)
    setSuccess(true)
    setEmail('')
    setTimeout(() => {
      setSuccess('')
    }, 2000);
    setTimeout(() => {
      history.push('/signin')
    }, 3000);
    } catch (e) {
      if (e?.response) {
        console.log(e?.response)
        const { error } = e?.response?.data;
        setError(error?.message || 'Something went wrong. please try again')
    setIsLoading(false)
  } else {
    console.log(e)
    setError('Something went wrong. please try again')
      }
  }
 }


  
  return (
    <div className="signin">
       <TransitionablePortal
        closeOnTriggerClick
        // onOpen={this.handleOpen}
        // onClose={this.handleClose}
      open={success}
      >
        <Segment
          style={{ right: '0%', position: 'fixed', top: '10%', zIndex: 1000 }}
        >
          <Header>Password reset successful</Header>
          <p>Your password has been reset successful</p>
        </Segment>
      </TransitionablePortal>
      <Grid columns="equal">
        <Grid.Column
          tablet="7"
          computer="7"
          className="no-padding "
          only="computer"
        >
          <div className="left-grid flex-justify"></div>
        </Grid.Column>
        <Grid.Column
          tablet="9"
          mobile="16"
          computer="9"
          className="no-padding"
        >
          <div className="right-grid ">
              <div className="x-container">
                <Form onSubmit={sendCode} warning>
                <Link to="/"> <img src={process.env.PUBLIC_URL + "/images/logo.png"} /></Link>
                  <h2 className="x-bold">Welcome to B4A.com</h2>
                  <div className="subtitle ">
                  Enter a new password to reset your password
                  </div>
                  <br/>
                   
                  <Form.Field>
                    <Form.Input
                      fluid
                      id="form-subcomponent-shorthand-input-first-name"
                      placeholder="New password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      type="password"
                      size="massive"
                    />
                  </Form.Field>

                  <Form.Field>
                    <Form.Input
                      fluid
                      id="form-subcomponent-shorthand-input-first-name"
                      placeholder="Confirm password"
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                      type="password"
                      size="massive"
                    />
                  </Form.Field>
                  {error ? (
                    <div className="ui warning message">
                      <small>{error}</small>
                    </div>
                  ) : null}
                
                <div >
               
                  </div>
                  <br />  
                 
                  <Button disabled={!password || !user?.email} loading={isLoading} fluid type="submit" color="green" size="huge">
                    SUBMIT
                  </Button>
                </Form> 
               
              
              </div>

          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}

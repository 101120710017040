import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Dropdown, Icon, Table } from "semantic-ui-react";
import eyes from "../../assets/eyes.png"
import chat from "../../assets/chat.png"
import moment from 'moment'
import UploadDrawings from "../pano/uploadDrawings";
import AxiosCall from "../../config/axios";
import DeleteBtn from "../project/DeleteBtn";
export default function RolesCard({ history , role,fetchProject}) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector((s) => s.project.data);
    const users = project.users
    const dispatch = useDispatch();
    const [open, setOpen] = useState('')
    const [deleteLoadingId, setDeleteLoadingId] = useState(null)
    const deleteRole = async (user) => {
        try {
            setDeleteLoadingId(user)
           var res =  await AxiosCall({
                path: "projects/deleteUser",
                data: { projectId: project._id,  user },
                method: "POST"
            })
            fetchProject()
            setDeleteLoadingId(null)
        } catch (error) {
            setDeleteLoadingId(null)
           console.log(error)
        }
    }
    const editRole = async (edit, role) => {
        try {
           var res =  await AxiosCall({
                path: "projects/addUser",
                data: { projectId: project._id, email: edit.email, edit, role},
                method: "POST"
            })
            fetchProject()
        } catch (error) {
           console.log(error)
        }
    }
    return (
        <div className="x-projectcard">
           
            <Table fixed striped >
                <Table.Header>
                    <Table.Row>
                        {/* <Table.HeaderCell>Time</Table.HeaderCell> */}
                        <Table.HeaderCell> </Table.HeaderCell>
                        <Table.HeaderCell>Date created</Table.HeaderCell>
                        <Table.HeaderCell >Fullname</Table.HeaderCell>
                        <Table.HeaderCell >Role</Table.HeaderCell>
                        {/* <Table.HeaderCell >Last updated</Table.HeaderCell> */}
                        {/* <Table.HeaderCell> </Table.HeaderCell> */}
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body  >
                    {users && users.map((user, key) => (
                        <Table.Row  key={key}>
                            <Table.Cell>
                                <img src={user?.userId?.photoURL} style={{width: '50px', height: '50px'}}/>
                            </Table.Cell>
                            <Table.Cell>{moment(user?.dateCreated).format("DD/MMM/YYYY hh:mm a")}</Table.Cell>
                            <Table.Cell>
                                 {user?.userId?.firstName || (user?.userId?.email +" - Invite Sent")}  {user?.userId?.lastName}
                            </Table.Cell>
                            <Table.Cell >  
                            {role == 'owner' ?
                                 <Dropdown pointing='top' text={<div className="x-title">
                                 {user?.role}
                            </div>}>
                                <Dropdown.Menu>
                                {/* <Dropdown.Header icon='user' content='Edit user role' />
                                <Dropdown.Divider /> */}
                                <Dropdown.Item text='Editor' onClick={()=>editRole(user?.userId, 'editor')}/>
                                <Dropdown.Item text='Viewer'  onClick={()=>editRole(user?.userId, 'viewer')}/>
                                <Dropdown.Item text='Comments'  onClick={()=>editRole(user?.userId, 'comments')}/>
                            </Dropdown.Menu>
                            </Dropdown>
                            : <div className="x-title">
                            {user?.role}
                       </div>}
                            </Table.Cell>
                            {/* <Table.Cell > {drawing.pano.length}</Table.Cell> */}
                            {/* <Table.Cell >{moment(drawing.dateUpdated).format("DD-MMM-YYYY")}</Table.Cell> */}
{/*                            
                            <Table.Cell> 
                            <div>
                               <Link to=""> <Icon name="photo" /> 23 </Link>
                            </div>
                            <div>
                               <Link to=""> <Icon name="globe" /> 23 </Link>
                            </div>
                            </Table.Cell> */}
                            <Table.Cell>
                            {role == 'owner' ?
                                 <DeleteBtn onConfirm={()=>deleteRole(user?.userId)}>
                                    <Button loading={deleteLoadingId == user?.userId}  compact color="red"
                                    >Remove</Button> 
                                 </DeleteBtn> : null}
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

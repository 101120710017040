import React, { useEffect, useState } from "react";
import Navbar from '../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, GridColumn, Icon, Progress } from "semantic-ui-react";
import shield from "../../assets/shield.png"
import explore from "../../assets/explore.png"
import mortgage from "../../assets/mortgage.png"
import insurance from "../../assets/insurance.png"
import refer from "../../assets/refer.png"
import { getProjectsRequest } from "../../store/actions/projects";
import Projectcard from "../../component/projects/projectCard";
import Loader from "react-loader-spinner";
export default function Dashboard(props) {
    const profile = useSelector((s) => s.auth.profile);
    const projects = useSelector((s) => s.projects);
    const dispatch = useDispatch();
    const { history } = props

    useEffect(() => {
        dispatch(getProjectsRequest())
    }, [])
    
    return (
        !profile || (projects.isLoading &&  projects.data?.length == 0) ?  <div className="x-page-loader">
        <Loader color="#37517E" />
    </div>  : 
        <>
            <Navbar {...props} auth={profile} />
            <div className="x-dashboard">
                <div className="x-container">
                    <section>
                        <div className="x-pagination"><Link to="/">Home</Link> </div>
                        <div className="x-flex">
                            <div className="x-title">
                                Welcome <span className="x-medium">{profile.firstName}</span>
                            </div>
                            <div>
                                <Button color="green" size="huge" onClick={() => history.push("new-project")}>
                                    NEW PROJECT
                                </Button>
                            </div>
                        </div>
                        <p className="x-project"> Your Projects <span className="x-bold">({projects.data?.length})</span> </p>
                    </section>
                    {projects?.data?.length ?
                        <div className="project-lists">
                       <Projectcard fetchProjects={()=> dispatch(getProjectsRequest())}   history={history} projects={projects.data} />
                        </div>
                    :
                    <Grid columns="equal">
                        <Grid.Column
                            mobile="16"
                            tablet="12"
                            computer="12"
                        >
                            <div className="left-box">
                                <h2>Start Your First <br /> Construction Project </h2>
                                <p>Recieve daily project updates and view live <br />  images and videos of your properties</p>
                                <Button color="white" size="huge" onClick={() => history.push("new-project")}>
                                    Cick Here <Icon name="chevron right" />
                                </Button>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            mobile="16"
                            tablet="4"
                            computer="4"
                        >
                            <div className="right-box">
                                <img src={shield} />
                                <h2>Update your account</h2>
                                <Progress percent={80} indicating />
                                <h3>80% Complete</h3>
                                <p>Update your account information to ulock all app features</p>
                            </div>
                        </Grid.Column>
                    </Grid>}
                    <br />
                    {/* <Grid columns="equal" stretched>
                        <Grid.Column
                            mobile="16"
                            tablet="4"
                            computer="4"
                        >
                            <div className="x-box explore">
                                <div>
                                    <img src={explore} />
                                    <h2>Explore Projects</h2>
                                    <div>
                                        Explore building  projects that fit your income bracket
                                    </div>
                                </div>
                                <Link to="">Click Here</Link>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            mobile="16"
                            tablet="4"
                            computer="4"
                        >
                            <div className="x-box mortgage">
                                <img src={mortgage} />
                                <h2>Mortgage Facility</h2>
                                <div>
                                    Attractive mortgage plans for everyone
                                </div>
                                <Link to="">Click Here</Link>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            mobile="16"
                            tablet="4"
                            computer="4"
                        >
                            <div className="x-box insurance">
                                <img src={insurance} />
                                <h2>Insurance Plan</h2>
                                <div>
                                    Your projects are safe and integrated with reliable insurance plans
                                </div>
                                <Link to="">Click Here</Link>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            mobile="16"
                            tablet="4"
                            computer="4"
                        >
                            <div className="x-box refer">
                                <img src={refer} />
                                <h2>Refer a friend</h2>
                                <div>
                                    Refer B4D to your family and friends and earn some loyalty points
                                </div>
                                <Link to="">Click Here</Link>
                            </div>
                        </Grid.Column>
                    </Grid> */}
                </div>
            </div>
        </>
    )
}

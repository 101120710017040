import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import { Button, Form, Grid, Divider, Icon, Checkbox } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import AxiosCall from "../../config/axios";
import { refreshProfile, updateProfileCleanup, updateProfileRequest } from "../../store/actions/profile";
import { loginRequest } from "../../store/actions/signin";
import google from "../../assets/google.png"

export default function Signin({history}) {
  const profile = useSelector((s) => s.auth.profile);
  const signin = useSelector((s) => s.signin);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState('');
  const {error, isLoading} = signin

  useEffect(() => {
    //  dispatch(refreshProfile(null))
    //  localStorage.removeItem('userData')
    //  localStorage.removeItem('token')
  }, [])
  
useEffect(() => {
  console.log(profile)
  if(signin.isSuccessful || profile?.registrationStage =='completed'){
    history.push('/projects')
  }
}, [signin, profile])


  
  return (
    <div className="signin">
      <Grid columns="equal">
        <Grid.Column
          tablet="7"
          computer="7"
          className="no-padding "
          only="computer"
        >
          <div className="left-grid flex-justify"></div>
        </Grid.Column>
        <Grid.Column
          tablet="9"
          mobile="16"
          computer="9"
          className="no-padding"
        >
          <div className="right-grid ">
              <div className="x-container">
                <Form onSubmit={()=>dispatch(loginRequest({email,password}))} warning>
                <Link to="/"> <img src={process.env.PUBLIC_URL + "images/logo.png"} /></Link>
                  <h2 className="x-bold">Welcome to B4A.com</h2>
                  <div className="subtitle ">
                    Let’s get started with a few simple steps
                  </div>
                  <br/>
                  
                  <Form.Field>
                    <Form.Input
                      fluid
                      id="form-subcomponent-shorthand-input-first-name"
                      placeholder="Email address"
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      type="email"
                      size="massive"
                    />
                  </Form.Field>
                  <Form.Field>
                  <Form.Input
                      id='form-subcomponent-shorthand-input-password'
                      placeholder='Password'
                      name="password"
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      size='massive'
                  />
                  </Form.Field>
                  {error ? (
                    <div className="ui warning message">
                      <small>{error}</small>
                    </div>
                  ) : null}
                
                <div >
                <div className=" ">
                     forgot password? <Link to="/password-reset"> Click here </Link>
                    </div>
                  </div>
                  <br />  
                 
                  <Button disabled={!email || !password} loading={isLoading} fluid type="submit" color="green" size="huge">
                    NEXT
                  </Button>
                </Form>
                <Divider horizontal> Or signup in with </Divider>
                <Button
                  size="huge"
                  basic
                  color="black"
                 
                  fluid
                >
                   <img src={google} style={{width: '17px'}}/>
                  </Button>
                <center>
                  <br />Dont have an Account yet? <Link to="/signup"> Signup </Link>
                </center>
                <div className="bottom-text">
                  <div className="flex-justify">
                    <div>
                      <div>
                        <br /> <Link to="/signin"> Terms and Condition </Link>
                      </div>
                    </div>
                    <div>
                      <div>
                        <br /> <Link to="/signin"> Privacy policy </Link>
                      </div>
                    </div>
                  </div>
                </div>
               
              
              </div>

          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}

import React, { useState } from 'react'
import { Button, Header, Icon, Image, Modal } from 'semantic-ui-react'
import Mediaupload from '../uploader'
import { Link } from "react-router-dom";
import AxiosCall from '../../config/axios';

function PaymentSuccessful({ open, setOpen, projectID }) {
    var [url, setUrl] = useState('')
    var [loading, setLoading] = useState(false)
    
    const upload = async () => {
        try {
           var res = AxiosCall({
                path: 'projects/uploadReceipt',
                data: {url, projectID: open},
                method: "POST"
            })
            window.location.reload()
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <Modal
            onClose={() => setOpen('')}
            open={open ? true: false}
            className="x-upload-receipt"
        >
            <Modal.Header>
            <div className="x-back" onClick={() => {
                                           
            }}>
                <Icon name="chevron left" />
                <span> Back</span>
            </div>
            </Modal.Header>
            <Modal.Content >
                <div className="x-content">
                    <Mediaupload cb={setUrl} text="Uplaod the evidence of payment for your consultation fee" header="Upload evidence of payment"/>
                    {url &&  <div className="x-url">
                        <a href="#" onClick={() => window.open(url, "__blank")}> {url.split("/")[4]}</a>
                        
                        </div>}
                   
                    <Button color="green" loading={loading} size="huge" disabled={!url} onClick={upload} >
                        UPLOAD PAYMENT RECEIPT
                    </Button>
                    <br />
                    <br />
                  <div>
                  <Link to="/projects">Return to project page</Link>
                  </div>
                </div>

            </Modal.Content>
            {/* <Modal.Actions>
        <Button color='black' onClick={() => setOpen(false)}>
          Nope
        </Button>
        <Button
          content="Yep, that's me"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpen(false)}
          positive
        />
      </Modal.Actions> */}
        </Modal>
    )
}

export default PaymentSuccessful

import React, { useEffect, useState } from "react";
import Navbar from '../../component/navbar'
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Divider, Grid, GridColumn, Icon, Loader, Progress } from "semantic-ui-react";
import pin from "../../assets/pin.png"
import landsize from "../../assets/landsize.png"
import bank from "../../assets/bank.png"
import visa from "../../assets/visa.png"
import mastercard from "../../assets/mastercard.png"
import ng from "../../assets/ng.png"
import flutterwave from "../../assets/flutterwave.png"
import paypal from "../../assets/paypal.png"
import { getInvoiceRequest, payInvoiceRequest } from "../../store/actions/invoice";
import Projectcard from "../../component/projects/projectCard";
import moment from "moment"
import InvoiceSheet from "../../component/projects/invoice";
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';

export default function Payment(props) {
    const profile = useSelector((s) => s.auth.profile);
    const invoices = useSelector((s) => s.invoice.data);
    const [loadingPayment,setLoadingPayment] = useState(false)
    const [invoice,setInvoice] = useState(null)
    const dispatch = useDispatch();
    const { history, location } = props
    const { state } = location

    useEffect(() => {
        dispatch(getInvoiceRequest(state?._id))
    }, [])
    useEffect(() => {
        invoices?.map(i=>{
            if(i.paid == false){
                setInvoice(i)
            }
        })
    }, [invoices])
    const calculateTotal = () => {
        var total = 0
        invoice?.products?.map(p => {
            total += parseInt(p.price)
        })
        return total
    }
  console.log(state, invoices, profile)
    const config = {
        public_key: 'FLWPUBK_TEST-eb4e70f681ae86f5150a5de25a3df538-X',
        tx_ref: Date.now(),
        amount: calculateTotal(),
        currency: invoice?.currency,
        // currency: 'ngn',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: profile.email,
          phonenumber: profile.phone,
          name: profile.firstName + ' ' + profile.lastName,
        },
        customizations: {
          title: 'Build4Africa',
          description: 'Project Payment',
          logo: 'https://res.cloudinary.com/afrikal/image/upload/v1653041968/logo_xs3amz.png',
        },
      };
    
    const handleFlutterPayment = useFlutterwave(config);
    
    return (
        !invoice?._id ?
            <div className="animate-loader"> <Loader type="Ball-Triangle" color="white" height={80} width={80} /></div>
            :
            <>
                <Navbar {...props} auth={profile} />
                <div className="x-payment">
                    <div className="x-container">
                        <section>
                            <div className="x-pagination">Home <Icon name="chevron right" /> My projects  </div>
                            <div className="x-flex">
                                <div className="x-title">
                                    <span className="x-medium">{state.title}</span>
                                </div>
                                <div>
                                </div>
                            </div>
                        </section>
                        {/* <div className="x-first-sec">
                       
                    </div> */}
                        <div className="x-second">
                            <div className="x-back" onClick={() => {
                                props.history.goBack()
                            }}>
                                <Icon name="chevron left" />
                                <span> Back</span>
                            </div>
                            <div className="x-head">
                                <Grid columns="equal" stretch >
                                    <Grid.Column
                                        mobile="16"
                                        tablet="14"
                                        computer="14"
                                    >
                                        <div className="x-left">
                                            <h2>Make Payment</h2>
                                            <p>We accept the following payment method</p>
                                        </div>
                                    </Grid.Column>
                                    <Grid.Column
                                        mobile="16"
                                        tablet="2"
                                        computer="2"
                                    >
                                        <div className="x-right">
                                            <div className="x-currency">
                                                <img src={ng} /> <span>USD</span>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid>

                            </div>
                            <div className="x-body">
                                <div className="x-methods">
                                    <div className="x-method">
                                        <div className="x-img"><img src={bank} /></div>
                                        <div className="x-medium">Bank Transfer</div>
                                    </div>
                                    <div className="x-method">
                                        <div className="x-img"> <img src={visa} /></div>
                                        <div className="x-medium"> Visa card</div>
                                    </div>
                                    <div className="x-method">
                                        <div className="x-img"><img src={mastercard} /></div>
                                        <div className="x-medium">Mastercard </div>
                                    </div>
                                    <div className="x-method">
                                        <div className="x-img">  <img src={paypal} /></div>
                                        <div className="x-medium">Paypal</div>
                                    </div>
                                </div>
                                <Grid columns="equal" stretch verticalAlign='middle' centered>
                                    <Grid.Column
                                        mobile="16"
                                        tablet="10"
                                        computer="10"
                                        centered
                                    >
                                        <div className="x-left">
                                         <center>
                                             <h3>Choose a payment method</h3>
                                             <br />
                                         <Button
                                         className="flutterwave"
                                         loading={loadingPayment}
                                         size="huge"
                                            onClick={() => {
                                            handleFlutterPayment({
                                                callback: (response) => {
                                                    setLoadingPayment(true)
                                                    dispatch(payInvoiceRequest({...response,...invoice}))
                                                    closePaymentModal()  
                                                },
                                                onClose: () => {},
                                            });
                                            }}
                                        >
                                          <img src={flutterwave}  Pay with flutterwave />
                                          Pay with Flutterwave
                                        </Button>
                                         </center>
                                         </div>
                                    </Grid.Column>
                                    <Grid.Column
                                        mobile="16"
                                        tablet="6"
                                        computer="6"
                                    >
                                        <div className="x-right">
                                            <div className="x-top">
                                                <h2>Consultation Fee</h2>
                                                <div className="x-flex x-location">
                                                    <div>
                                                        <img src={pin} />
                                                    </div>
                                                    <p>
                                                        {state.location}
                                                    </p>
                                                </div>
                                                <div className="x-flex x-landSize">
                                                    <div>
                                                        <img src={landsize} />
                                                    </div>
                                                    <div>
                                                        Land size

                                                <p className="x-medium">
                                                            {state.landSize}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="x-bottom">
                                                <div className="x-flex x-space-between">
                                                    <div>
                                                        Subtotal
                                                </div>
                                                    <div>
                                                        {calculateTotal()}{invoice.currency}
                                                    </div>
                                                </div>
                                                <div className="x-flex x-space-between">
                                                    <div>
                                                        Tax {invoice.tax}
                                                    </div>
                                                    <div>
                                                        {calculateTotal()}{invoice.currency}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="x-flex x-space-between x-total">
                                                <div>
                                                    Total Amount
                                                </div>
                                                <div className="x-bold">
                                                    {calculateTotal()}{invoice.currency}
                                                </div>
                                            </div>
                                        </div>
                                    </Grid.Column>
                                </Grid>
                                <Divider />
                            </div>

                        </div>
                    </div>
                </div>
            </>
    )
}

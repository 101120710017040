import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Grid, Icon, Table } from "semantic-ui-react";
import eyes from "../../assets/eyes.png"
import chat from "../../assets/chat.png"
import moment from 'moment'
import UploadDrawings from "../pano/uploadDrawings";
export default function DrawingsCard({ history ,fetchProject}) {
    const profile = useSelector((s) => s.auth.profile);
    const project = useSelector((s) => s.project.data);
    const drawings = project.drawings
    const dispatch = useDispatch();
    const [open, setOpen] = useState('')
    const [edit, setEdit] = useState(null)
    return (
        <div className="x-projectcard">
            <UploadDrawings setOpen={setOpen} setEdit={setEdit} open={open} edit={edit} fetchProject={fetchProject}/>
            <Table fixed striped >
                <Table.Header>
                    <Table.Row>
                        {/* <Table.HeaderCell>Time</Table.HeaderCell> */}
                        <Table.HeaderCell> </Table.HeaderCell>
                        <Table.HeaderCell>Drawing title</Table.HeaderCell>
                        <Table.HeaderCell >Photos</Table.HeaderCell>
                        <Table.HeaderCell >Videos</Table.HeaderCell>
                        <Table.HeaderCell >360° photos</Table.HeaderCell>
                        <Table.HeaderCell >Last updated</Table.HeaderCell>
                        {/* <Table.HeaderCell> </Table.HeaderCell> */}
                        <Table.HeaderCell>Action</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {drawings && drawings.map(drawing => (
                        <Table.Row >
                            {/* <Table.Cell>{moment(drawing.createdAt).format("hh:mm a")}</Table.Cell> */}
                            <Table.Cell>
                                <img src={drawing.url} style={{width: '50px', height: '50px'}}/>
                            </Table.Cell>
                            <Table.Cell>
                                 {drawing.title}
                            </Table.Cell>
                            <Table.Cell > {drawing.images.length}</Table.Cell>
                            <Table.Cell > {drawing.videos.length}</Table.Cell>
                            <Table.Cell > {drawing.pano.length}</Table.Cell>
                            <Table.Cell >{moment(drawing.dateUpdated).format("DD-MMM-YYYY")}</Table.Cell>
{/*                            
                            <Table.Cell> 
                            <div>
                               <Link to=""> <Icon name="photo" /> 23 </Link>
                            </div>
                            <div>
                               <Link to=""> <Icon name="globe" /> 23 </Link>
                            </div>
                            </Table.Cell> */}
                            <Table.Cell>
                                <Button basic onClick={()=>history.push({
                                    pathname: "/apps/"+project.slug+"/preview",
                                    state:  drawing
                                })}>Explore</Button>  
                                <Button basic onClick={()=>{
                                    setEdit(drawing)
                                    setOpen(drawing._id)
                                }}><Icon name="pencil" /> </Button>  
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </div>
    )
}

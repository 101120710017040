import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon , Dropdown} from "semantic-ui-react";
import logo from "../../assets/logo.png"
import moment from "moment"
import $ from "jquery"
export default function Footer(props) {
    const profile = useSelector((s) => s.auth.profile);
    const notifications = useSelector((s) => s.auth.notifications);
    const dispatch = useDispatch();
    useEffect(() => {
        $(window).scrollTop(0)
    }, [])
    const logout  = () => {
        window.localStorage.clear()
        window.location.assign("/signin")
    }
   
   
    return (
        <div className="x-footer">
            <ul className="desktop">
                <li>
                    <Link to="">
                        <img src={logo} />
                    </Link>
                </li>
                <li>
                    <Link to="" >  About us   
                    </Link>
                </li> 
                <li>
                    <Link to="" >  Contact  us   
                    </Link>
                </li> 
            </ul>
            <ul className="x-mobile">
                <li>
                <Link to="">    Contact  us      </Link>
                </li>
            </ul>
        </div>
    )
}

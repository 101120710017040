import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {  Form, Grid, } from "semantic-ui-react";
import {  useDispatch, useSelector } from "react-redux";
import { updateProfileCleanup, updateProfileRequest } from "../../store/actions/profile";

export default function ProfileSetup({history}) {
  const profile = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const {isLoading, isSuccessful, error} = profile

useEffect(() => {
  if(profile.isSuccessful || (profile.profile?.registrationStage == 'completed')){
      dispatch(updateProfileCleanup())
      history.push('/signupCompleted')
  }
}, [profile])


  
  return (
    !profile?.profile ? null :
    <div className="signin">
      <Grid columns="equal">
      <Grid.Column
          tablet="7"
          computer="7"
          className="no-padding "
          only="computer"
        >
          <div className="left-grid flex-justify"></div>
        </Grid.Column>
        <Grid.Column
          tablet="9"
          mobile="16"
          computer="9"
          className="no-padding"
        >
          <div className="right-grid ">
              <div className="x-container">
                <Form  warning loading={isLoading}>
                <Link to="/"> <img src={process.env.PUBLIC_URL + "images/logo.png"} /></Link>
                  <h2 className="x-bold">Lets Create the best <br/> experience for you</h2>
                  <div className="subtitle ">
                    Choose an Account type
                  </div>
                  <br/>
                  <div  className="option" onClick={()=>dispatch(updateProfileRequest({
                  accountType : 'personal', registrationStage: 'completed' 
                }))}>
                    <h3>
                      Personal
                    </h3>
                    <p>I want to build for personal use</p>
                  </div>
                  <div  className="option" onClick={()=>dispatch(updateProfileRequest({
                  accountType : 'business',  registrationStage: 'completed' 
                }))}>
                    <h3>
                      Business
                    </h3>
                    <p>I want to render construction services to home owners</p>
                  </div>
                  {error ? (
                    <div className="ui warning message">
                      <small>{error}</small>
                    </div>
                  ) : null}
                
                </Form>
              
               
              
              </div>

          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
}

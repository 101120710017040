import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Icon , Dropdown, Button} from "semantic-ui-react";
import logo from "../../assets/logo.png"
import moment from "moment"
import $ from "jquery"
export default function Navbar2(props) {
    const profile = useSelector((s) => s.auth.profile);
    const notifications = useSelector((s) => s.auth.notifications);
    const dispatch = useDispatch();
    const [openMenu, setOpenMenu] = useState(false)
    const {history} = props
    useEffect(() => {
        $(window).scrollTop(0)
    }, [])
    const logout  = () => {
        window.localStorage.clear()
        window.location.assign("/signin")
    }
   
 
      const profileDropdown  = () => (
        <Dropdown pointing="top right" icon={ <Link to="#" className={window.location.pathname == '/profile' && "active"}>     
        <img src={profile.photoURL} />  
    </Link>}>
          <Dropdown.Menu>
        <Dropdown.Header>{profile?.firstName} {profile?.lastName}</Dropdown.Header>
        <Dropdown.Divider />
           <Dropdown.Item icon='logout' text='Logout' onClick={logout}/>
            {/* <Dropdown.Item icon='trash' text='Move to trash' /> */}
            {/* <Dropdown.Divider /> */}
          </Dropdown.Menu>
        </Dropdown>
      )
    return (
        <>
        <div className="x-navbar2">
            <div className="x-flex">
            <ul className="desktop">
                <li>
                    <Link to="/">
                        <img src={logo} />
                    </Link>
                </li>
                <li>
                    <Link to="/" >  About us   
                    </Link>
                </li>
                <li>
                {profile?.firstName?
                <Button  size="large" primary onClick={()=> history.push("/projects")}>Dashboard</Button>
                    :
                <Button  size="large" primary onClick={()=> history.push("/signin")}>Get Started</Button>
                }
                </li>
                <li>
                    {profile?.firstName?
                    <Button size="large" basic onClick={logout} >Log out</Button>
                    :
                    <Button size="large" basic onClick={()=> history.push("/signin")} >Log in</Button>
}
                </li>
                {/* <li>
                    <Link to="/payment" className={window.location.pathname == '/explore' && "active"}>  Payment  
                    </Link>
                </li> */}
              
                <li className="profile">
                    {/* <Link to="/profile" className={window.location.pathname == '/profile' && "active"}>     
                        <img src={profile.photoURL} />  
                    </Link> */}
                    {/* {profileDropdown()} */}
                </li>
            </ul>
            <ul className="x-mobile">
                <li> 
                <a  href="#" onClick={()=>setOpenMenu(!openMenu)}> {!openMenu ?  <Icon name="bars"/> : <Icon name="close"/>  }</a>
                </li>
            </ul>
            </div>
            <ul className={openMenu ? "x-menulist open" : "x-menulist closed"}>
            <li>
                <Link to="/" ><Icon name="home" />  Home    </Link> 
            </li>
            <li>
                <Link to="/" ><Icon name="industry" />  About us   </Link> 
            </li>
                <li> 
                {profile?.firstName?
                <Link to="/projects" ><Icon name="building outline" />  Dashboard    </Link> :
                <Link to="/signin" ><Icon name="street view" />   Get Started    </Link> } 
                </li>
                <li>  {profile?.firstName?
                <a href="#" onClick={logout}><Icon name="sign out" /> Log out    </a> :
                <Link to="/signin" > <Icon name="user circle outline" />  Login    </Link> }</li>
            </ul>
        </div>

        
        </>
    )
}

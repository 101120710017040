import AxiosCall, { setAuthorizationToken } from '../../../config/axios';
import * as types from '../../constant';

export const refreshProfile = payload => {
  return { type: types.REFRESH_USER_PROFILE, payload };
};

const updateProfileStart = () => {
  return { type: types.UPDATE_PROFILE_START };
};
export const updateProfileSuccess = payload => {
  return { type: types.UPDATE_PROFILE_SUCCESS, payload };
};

export const updateProfileFail = payload => {
  return { type: types.UPDATE_PROFILE_FAIL, payload };
};

export const updateProfileCleanup = () => {
  return { type: types.UPDATE_PROFILE_CLEANUP };
};

export const refreshUserToken = () => {
  return async dispatch => {
    try {
      const callObj = {
        method: 'GET',
        path: 'auth/refresh',
      };
      const response = await AxiosCall(callObj);
      setAuthorizationToken(response.token)
      var userData = JSON.stringify(response.userData);
      var notifications =  response.notifications
      localStorage.setItem('userData', userData);
      localStorage.setItem('token', response.token);
      dispatch(refreshProfile({profile: response.userData, notifications}))

    }
    catch (e) {
      console.log(e)
    }
  }
}


export const updateProfileRequest = data => {
    return async dispatch => {
      dispatch(updateProfileStart());
      try {
        const callObj = {
          method: 'POST',
          path:  'users/updateProfile',
          data
        };
        const response = await AxiosCall(callObj);
        var userData = JSON.stringify(response.userData);
       
        localStorage.setItem('userData', userData);
        localStorage.setItem('token', response.token);
        setAuthorizationToken(response.token)
        dispatch(updateProfileSuccess(response.userData));
      } catch (e) {
        let errorResponse;
        // console.log(e)
        if (e.response) {
          const { error } = e.response.data;
          errorResponse = error;
        } else {
          errorResponse = 'Something went wrong. please try again';
        }
        console.log(e)
        dispatch(updateProfileFail(errorResponse)); 
      }
    };
}
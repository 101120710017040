
export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const UPDATE_PROFILE_CLEANUP = 'UPDATE_PROFILE_CLEANUP';

export const USER_SIGNUP_START = 'USER_SIGNUP_START';
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS';
export const USER_SIGNUP_FAIL = 'USER_SIGNUP_FAIL';
export const USER_SIGNUP_CLEANUP = 'USER_SIGNUP_CLEANUP';

export const USER_LOGIN_START = 'USER_LOGIN_START';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL';
export const USER_LOGIN_CLEANUP = 'USER_LOGIN_CLEANUP';

export const NEW_PROJECT_START = 'NEW_PROJECT_START';
export const NEW_PROJECT_SUCCESS = 'NEW_PROJECT_SUCCESS';
export const NEW_PROJECT_FAIL = 'NEW_PROJECT_FAIL';
export const NEW_PROJECT_CLEANUP = 'NEW_PROJECT_CLEANUP';

export const GET_PROJECTS_START = 'GET_PROJECTS_START';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAIL = 'GET_PROJECTS_FAIL';
export const GET_PROJECTS_CLEANUP = 'GET_PROJECTS_CLEANUP';

export const GET_PROJECT_START = 'GET_PROJECT_START';
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECT_FOLDERS_SUCCESS = 'GET_PROJECT_FOLDERS_SUCCESS';
export const GET_PROJECT_FAIL = 'GET_PROJECT_FAIL';
export const GET_PROJECT_CLEANUP = 'GET_PROJECT_CLEANUP';
export const GET_PROJECT_RESET = 'GET_PROJECT_RESET';
export const SET_TOUR_GUIDE = 'SET_TOUR_GUIDE';

export const GET_TEMPLATES_START = 'GET_TEMPLATES_START';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_FAIL = 'GET_TEMPLATES_FAIL';
export const GET_TEMPLATES_CLEANUP = 'GET_TEMPLATES_CLEANUP';


export const GET_TEMPLATE_START = 'GET_TEMPLATE_START';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_FAIL = 'GET_TEMPLATE_FAIL';
export const GET_TEMPLATE_CLEANUP = 'GET_TEMPLATE_CLEANUP';

export const GET_INVOICE_START = 'GET_INVOICE_START';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL';
export const GET_INVOICE_CLEANUP = 'GET_INVOICE_CLEANUP';

export const RESET_CODE_VERIFICATION_START = 'RESET_CODE_VERIFICATION_START';
export const RESET_CODE_VERIFICATION_FAIL = 'RESET_CODE_VERIFICATION_FAIL';
export const RESET_CODE_VERIFICATION_SUCCESS = 'RESET_CODE_VERIFICATION_SUCCESS';
export const RESET_CODE_VERIFICATION_CLEANUP = 'RESET_CODE_VERIFICATION_CLEANUP';


export const REFRESH_USER_PROFILE = 'REFRESH_USER_PROFILE'
export const REFRESH_VENDORS = 'REFRESH_VENDORS'
export const SET_ACTIVE = 'SET_ACTIVE'


export const SEARCH_FOODS_START = 'SEARCH_FOODS_START'
export const SEARCH_FOODS_SUCCESS = 'SEARCH_FOODS_SUCCESS'
export const SEARCH_FOODS_FAIL = 'SEARCH_FOODS_FAIL'
export const SEARCH_FOODS_CLEANUP = 'SEARCH_FOODS_CLEANUP'


export const PRODUCTS_CREATION_START = 'PRODUCTS_CREATION_START'
export const PRODUCTS_CREATION_SUCCESS = 'PRODUCTS_CREATION_SUCCESS'
export const PRODUCTS_CREATION_FAIL = 'PRODUCTS_CREATION_FAIL'
export const PRODUCTS_CREATION_CLEAN_UP = 'PRODUCTS_CREATION_CLEAN_UP'


export const PRODUCTS_EDIT_START = 'PRODUCTS_EDIT_START'
export const PRODUCTS_EDIT_SUCCESS = 'PRODUCTS_EDIT_SUCCESS'
export const PRODUCTS_EDIT_FAIL = 'PRODUCTS_EDIT_FAIL'
export const PRODUCTS_EDIT_CLEAN_UP = 'PRODUCTS_EDIT_CLEAN_UP'

